import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const siteHumanReadable = get(siteConf, '[og:site_name]');
  const author = get(state, 'author.data') || {};
  const authorName = get(author, 'name');
  const authorSlug = get(author, 'slug');
  const description = authorName
    ? `Articles and posts written and edited by ${authorName}`
    : `Author stories on ${siteHumanReadable}`;
  const title = authorName ? `${authorName}` : `${siteHumanReadable}`;

  const rss = `/user/${authorSlug}/rss`;

  let next = false;
  let prev = false;

  const page = get(state, 'authorAllFeed.data.page');
  const totalPages = get(state, 'authorAllFeed.data.totalPages');

  if (page < totalPages && page - 1 !== 0) {
    prev = `https://www.${site}.com/user/${authorSlug}/${page - 1}`;
  }

  if (page + 1 <= totalPages) {
    next = `https://www.${site}.com/user/${authorSlug}/${page + 1}`;
  }

  const base = {
    site,
    siteConf,
    title,
    url: `https://www.${site}.com/user/${authorSlug}`,
    description,
    articleSection: authorName,
    rss,
    config: state.config,
    extend: [
      {
        link: []
      }
    ]
  };

  if (next) {
    base.extend[0].link.push({
      rel: 'next',
      href: next
    });
  }

  if (prev) {
    base.extend[0].link.push({
      rel: 'prev',
      href: prev
    });
  }

  return baseSeoUtil(base);
}
