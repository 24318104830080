import categoryPageStateManager from '../category_page_state_manager';
import loadable from '@loadable/component';

const MagazinePage = loadable(() => import('../magazine_page'));

export default function(store) {
  return {
    path: '/magazine',
    component: MagazinePage,
    strict: true,
    exact: true,
    loadData: categoryPageStateManager(store, 'magazine')
  };
}
