import * as promoBannerActions from './../action_creators/promo_banner_action_creators';
import PromoBannerModel from '../../models/promo_banner';
import log from '../../services/logger_service';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(promoBannerActions.promoBannerLoading({}, store));
    return PromoBannerModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        dispatch(promoBannerActions.promoBannerLoaded(resp.data, store));
      })
      .catch(function handleUserError(err) {
        dispatch(promoBannerActions.promoBannerLoadError(err, store));
        log.error(err, `Error in promoBanner actions, message: ${err.message}`);
      });
  };
}
