import React from 'react';

const SuccessMessage = () => (
  <div className="form-submitted">
    <em>Thank you for signing up!</em>
    <p>
      Stephanie’s weekly newsletter will arrive in your inbox each Monday
      morning.
    </p>
    <p>
      To manage your email preferences, please visit{' '}
      <a
        href="https://register.fastcompany.com/fc-preference-center.html"
        title="FastCompany Preferences Center"
      >
        Preferences Center
      </a>{' '}
      or send a note to our newsletter team at{' '}
      <a
        href="mailto:newsletters@mansueto.com"
        title="Send a note to newsletters@mansueto.com"
      >
        newsletters@mansueto.com
      </a>
    </p>
    <div className="foot-links">
      <p>
        Visit{' '}
        <a href="https://www.inc.com/" title="Inc.">
          inc.com
        </a>{' '}
        and{' '}
        <a href="https://www.fastcompany.com/" title="Fast Company">
          fastcompany.com
        </a>
      </p>
    </div>
  </div>
);

export default SuccessMessage;
