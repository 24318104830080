// import { canUseDOM } from 'exenv';

export function homepageVideoLoading(resp = {}, store = {}) {
  return {
    type: 'HOMEPAGE_VIDEO_LOADING',
    homepageVideo: resp,
    store
  };
}

export function homepageVideoLoaded(resp = {}, store) {
  return {
    type: 'HOMEPAGE_VIDEO_LOADED',
    homepageVideo: resp,
    store
  };
}

export function homepageVideoLoadError(error, store) {
  return {
    type: 'HOMEPAGE_VIDEO_ERROR',
    homepageVideo: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
