import React, { Fragment, Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { get, set, isEmpty } from 'lodash';
import Cookies from 'js-cookie';
import { canUseDOM } from 'exenv';
import { getPriorityTag } from '../../../utils/ads/get_priority_tag';
import EventModel from '../../../utils/tracking/models';

const { PIANO_AID } = process.env;

export const UserContext = createContext({
  user: {},
  meter: {},
  activeOffer: {},
  journey: {},
  experience: {}
});

export class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
      meter: {},
      activeOffer: {},
      journey: {},
      currentDrawer: 'NI',
      offerPageDetails: {},
      isUserValid: undefined,
      userAccessIds: [],
      hasMagazineAccess: undefined,
      experience: {},
      premiumPaywallActive: false
    };

    this.subscribedBcEvents = false;

    // binds
    this.logoutUser = this.logoutUser.bind(this);
    this.retriggerPianoPost = this.retriggerPianoPost.bind(this);
    this.retriggerPiano = this.retriggerPiano.bind(this);
    this.onShowDrawer = this.onShowDrawer.bind(this);
    this.flushAllCookies = this.flushAllCookies.bind(this);
    this.isInlineInjected = false;
    this.isStudentUser = this.isStudentUser.bind(this);
    this.isStudentOffer = this.isStudentOffer.bind(this);
    this.setUpApplePay = this.setUpApplePay.bind(this);
    this.hasPaywallAccess = this.hasPaywallAccess.bind(this);
  }

  componentDidMount() {
    // Handle initial user check
    if (!get(window, 'tp', undefined)) {
      window.tp = [];
    }

    window.tp.push([
      'init',
      () => {
        if (tp.pianoId.getUser()) { // eslint-disable-line
          this.setCurrentUser(tp.pianoId.getUser()); // eslint-disable-line
        }

        this.checkUserAccess().then(({ userAccessIds, hasMagazineAccess }) => {
          this.setState({ userAccessIds, hasMagazineAccess });
        });
      }
    ]);

    if (canUseDOM) {
      this.setUpApplePay();
    }

    // Set default Piano handlers
    this.setPianoHandlers([
      'showOffer',
      'showTemplate',
      'loginSuccess',
      'registrationSuccess',
      'meterActive',
      'meterExpired',
      'checkoutComplete',
      'customEvent',
      'checkoutStateChange',
      'startCheckout',
      'checkoutSelectTerm',
      'experienceExecute',
      'setResponseVariable'
    ]);

    // Setup listeners for custom Piano 'Composer' scripts
    this.listenForJourneyEvents(['subscribe', 'showDrawer', 'offerPage']);

    if (this.blueConicLoaded()) {
      this.subscribeToBcEvents();
    } else if (typeof window !== 'undefined') {
      window.addEventListener(
        'onBlueConicLoaded',
        this.subscribeToBcEvents.bind(this),
        false
      );
    }
  }

  // Fires when a user begins checkout on a term.
  onStartCheckout(event) {} // eslint-disable-line

  // Offer checkout has completed
  onCheckoutComplete(event) {
    const eventType = get(event, 'type');
    const termId = get(event, 'termId', 'unknown-term');
    const termConversionId = get(event, 'termConversionId', '');
    const subscriptionId = get(
      event,
      'subscriptionId',
      'unknown-subscription-id'
    );
    const uid = get(event, 'uid', 'unknown-user-id');
    Cookies.set(`converted-${termId}`, 'true', { expires: 30 });

    // Track Purchase Event
    const purchaseEvent = new EventModel();
    purchaseEvent.addTrackingAttributes({
      transaction: {
        type: eventType,
        transaction_id: termConversionId,
        term_id: termId,
        subscription_id: subscriptionId,
        value: event?.chargeAmount || -1,
        offer_id: event?.offerId || 'unknown-offer-id',
        currency: event?.chargeCurrency || 'unknown-currency'
      },
      customer: {
        customer_id: uid,
        customer_email: event?.email || 'unknown-customer-email'
      },
      transaction_id: termConversionId,
      term_id: termId,
      subscription_id: subscriptionId,
      customer_id: uid,
      type: eventType
    });
    purchaseEvent.addToDataLayer('purchase');
  }

  onCheckoutStateChange(stateView) {
    const { stateName } = stateView;

    switch (stateName) {
      case 'offer':
        break;

      case 'auth':
        break;

      case 'printAddress':
        break;

      case 'state2':
        break;

      case 'receipt':
        break;

      case 'alreadyHasAccess':
        break;

      default:
        break;
    }
  }

  onSubscribe(event) {
    const { journey } = this.state;
    const displayMessageFlag = get(event, 'displayMessageFlag', true);
    const displayMessageAt = get(event, 'displayMessageAt', -1);
    const templateId = get(event, 'templateId', 'OTY37IX717TR');
    const offerId = get(event, 'offerId', 'OFC77Q9UWPQM');
    const ctaText = get(event, 'ctaText', 'Upgrade for unlimited access.');
    const drawerCta = get(event, 'drawerCta', 'Subscribe to read more');
    const newData = {
      displayMessageFlag,
      displayMessageAt,
      templateId,
      offerId,
      ctaText,
      drawerCta
    };
    const updatedJourney = Object.assign(journey, newData);
    this.setState({
      journey: updatedJourney,
      offerPageDetails: event
    });
  }

  // A Piano `offer` was triggered & displayed
  onShowOffer(offer) {
    const { displayMode, offerId } = offer;

    if (displayMode === 'modal') {
      // Track Purchase Event
      const showOfferEvent = new EventModel();
      showOfferEvent.addToDataLayer('modal_pageview', {
        page: {
          path: `modal/piano_modal`,
          title: `Offer ID: ${offerId}`,
          content_type: 'piano_modal'
        }
      });
    }

    const thisOffer = offerId || '';

    if (
      this.isLoggedIn() &&
      this.isStudentOffer(thisOffer) &&
      !this.isStudentUser()
    ) {
      // This is a non-student user attempting to view a student only offer
      this.setState({
        activeOffer: {
          failed: true,
          message: (
            <Fragment>
              <h2>
                Please use a valid student email address to quality for this
                offer.
              </h2>
              <br />
              <h3>
                <a href="/subscribe">
                  Not a student? Take a look at our other subscription offers!
                </a>
              </h3>
            </Fragment>
          )
        }
      });
    }
  }

  // A Piano offer's `term` has been selected
  onCheckoutSelectTerm(termData) {
    const usePostCheckoutDebugger = get(window, 'location.href', '').includes(
      'postcheckout'
    );

    const isStudentOffer = this.isStudentOffer(get(termData, 'offerId', false));
    const iframeId = get(termData, 'secondary', undefined);

    if (
      this.isLoggedIn() &&
      this.isStudentOffer(get(termData, 'offerId', false)) &&
      !this.isStudentUser()
    ) {
      // This is a non-student user attempting to view a student only offer
      this.setState({
        activeOffer: {
          failed: true,
          message: (
            <Fragment>
              <h2>
                Please use a valid student email address to qualify for this
                offer.
              </h2>
              <br />
              <h3>
                <a href="/subscribe">
                  Not a student? Take a look at our other subscription offers!
                </a>
              </h3>
            </Fragment>
          )
        }
      });

      window.tp.offer.close(); // Close the offer

      return false;
    }

    // Update state to reflect current offer term
    this.setState({
      activeOffer: termData
    });

    // Handle term display access (student, etc.)
    if (isStudentOffer && !this.isStudentUser() && !usePostCheckoutDebugger) {
      // PRE OFFER ALERT: "You are accessing a student offer with a non-student email."

      // this.showTemplate('OTWUVUE0WD0P'); // Generic hardcoded error template
      this.sendMessageToPiano(
        iframeId,
        false,
        '<h2>Sorry, you must be a student to access this offer.</h2><br /><h3>Please visit the <a href="https://www.inc.com/subscribe">Inc. Subscriptions</a> page for other great offers!</h3>',
        'username'
      );
      window.tp.offer.close();
      return false;
    }
    // POST OFFER ALERT: "You got a student discount, please update your email to take advantage."
    window.tp.push([
      'addHandler',
      'checkoutComplete',
      function checkoutComplete() {
        // eslint-disable-next-line
        // alert(
        //   'POST-CHECKOUT: You signed up without an academic email. Please update your email. Checked after checkout.'
        // );
      }
    ]);
    return false;
  }

  onShowDrawer(event) {
    const { displayMode, offerId, templateId, templateVariantId } = event;
    const target = `#drawerHolder-${this.state.currentDrawer}`;
    this.showOffer(offerId, templateId, displayMode, target, templateVariantId);
  }

  onOfferPage(event) {
    this.setState({
      offerPageDetails: event
    });
  }

  // A Piano `template` was triggered & displayed
  onShowTemplate(event) {
    const showTemplateEvent = new EventModel(
      `Template Modal`,
      `piano – ${get(event, 'templateId', 'none')}`,
      `template view`,
      `template view`,
      `1 | 5`
    );

    showTemplateEvent.addTrackingAttributes({
      id: get(event, 'offerId', 'none'),
      flow: get(event, 'templateid', 'none'),
      product: [
        {
          productInfo: {
            productID:
              'We dont have access to Resource ID this early in the offer',
            productName: get(event, 'offerId', 'none'),
            productOffer: `Product Offer`,
            productBasePrice: '19.99'
          }
        },
        {
          productInfo: {
            productID:
              'We dont have access to Resource ID this early in the offer',
            productName: get(event, 'offerId', 'none'),
            productOffer: `Product Offer`,
            productBasePrice: '1.99'
          }
        }
      ]
    });

    showTemplateEvent.triggerTrackingEvent(
      'template-modal',
      showTemplateEvent.getTrackingObject()
    );
  }

  // A user has succesfully authenticated with Piano ID
  onLoginSuccess(loginResponse) { // eslint-disable-line
    const myAccountRedirects = ['/login', '/register'];
    const { registration, params } = loginResponse;
    const event = registration ? 'sign_up' : 'login';

    // Update context & data layer with user's info
    this.setCurrentUser(get(loginResponse, 'params', {}), event);

    this.checkUserAccess().then(({ userAccessIds, hasMagazineAccess }) => {
      this.setState({ userAccessIds, hasMagazineAccess });
    });

    // Track login Event
    const uid = get(params, 'uid', 'unknown-user-id');
    const loginEvent = new EventModel();
    loginEvent.addTrackingAttributes({
      customer_id: uid
    });
    loginEvent.addToDataLayer('login');

    // Handle redirects and routing

    // "refresh" blueconic so listeners will be executed again
    if (typeof window.blueConicClient.handlePageView === 'function') {
      window.blueConicClient.handlePageView();
    }

    if (
      get(window, 'location.href', '').includes('/shared/') ||
      get(window, 'location.href', '').includes('/subscribe') ||
      get(window, 'location.href', '').includes('/offer') ||
      get(window, 'location.href', '').includes('/giveagift') ||
      get(window, 'location.href', '').includes('gift')
    ) {
      // early return for some pages so we don't hit the
      // window.location.reload(); below
      return;
    }

    // Redirect certain routes
    if (
      myAccountRedirects.some(redirect =>
        get(window, 'location.href', '').includes(redirect)
      )
    ) {
      window.location.replace('/');
    }
  }

  // A user has succesfully registered and is now authenticated with Piano ID
  // eslint-disable-next-line no-unused-vars
  onRegistrationSuccess(response) {
    const { user } = response;
    const myAccountRedirects = ['/login', '/register'];

    // Track login Event
    const uid = get(user, 'sub', 'unknown-user-id');
    const regEvent = new EventModel();
    regEvent.addTrackingAttributes({
      customer_id: uid
    });
    regEvent.addToDataLayer('account_creation');

    // Handle redirects and routing
    if (
      myAccountRedirects.some(redirect =>
        get(window, 'location.href', '').includes(redirect)
      )
    ) {
      window.location.replace('/');
    }
  }

  // A Piano `meter` is active
  onMeterActive(meterData) {
    this.setState({
      meter: meterData
    });
  }

  // An active Piano `meter` is expired
  onMeterExpired(expiredMeter) {
    this.setState({
      meter: expiredMeter
    });
  }

  onSetResponseVariable(eventParams) {
    const primaryResponse = get(eventParams, 'responseVariables', {});
    const eventName = get(primaryResponse, 'name');
    let modalWidth = 500;
    const main = document.querySelector('section.main');
    if (main.offsetWidth && main.offsetWidth < 650) {
      modalWidth = 375;
    }

    switch (eventName) {
      case 'showBottomBanner':
        this.showScreen({
          screen: 'register',
          mode: 'inline',
          selector: `#passwordlessBottomBanner`,
          stage: 'mini'
        });
        break;

      case 'showBottomBanner-co-design':
        this.showScreen({
          screen: 'register',
          mode: 'inline',
          selector: `#passwordlessBottomBanner`,
          stage: 'bottom-banner-co-design'
        });
        break;

      // isInlineInjected is needed to stop piano from continuously
      // injecting the template into containers
      case 'showInlineArticle-co-design':
        if (!this.isInlineInjected) {
          this.showScreen({
            screen: 'register',
            mode: 'inline',
            selector: `.pianoInline-wrapper`,
            stage: 'mid-article-co-design',
            registerDisplayed: this.inlineRegisterDisplayed
          });

          this.isInlineInjected = true;
        }
        break;

      case 'showInlineArticle-compass':
        if (!this.isInlineInjected) {
          this.showScreen({
            screen: 'register',
            mode: 'inline',
            selector: `.pianoInline-wrapper`,
            stage: 'mid-article-compass',
            registerDisplayed: this.inlineRegisterDisplayed
          });

          this.isInlineInjected = true;
        }
        break;

      // showInline cases don't have isInlineInjected logic due to
      // FC's SPA nature or else piano's experience doesn't
      // get trigger between user navigations because isInlineInjected
      // isn't refreshed between page navs
      case 'showInline-co-design':
        this.showScreen({
          screen: 'register',
          mode: 'inline',
          selector: `.pianoInline-wrapper`,
          stage: 'mid-article-co-design',
          registerDisplayed: this.inlineRegisterDisplayed
        });
        break;

      case 'showInline-compass':
        this.showScreen({
          screen: 'register',
          mode: 'inline',
          selector: `.pianoInline-wrapper`,
          stage: 'mid-article-compass',
          registerDisplayed: this.inlineRegisterDisplayed
        });
        break;

      case 'showFlyin-compass':
        this.showScreen({
          screen: 'register',
          mode: 'modal',
          selector: '#passwordlessBottomBanner',
          stage: 'flyin-compass',
          width: modalWidth
        });
        break;

      case 'showFlyin-co-design':
        this.showScreen({
          screen: 'register',
          mode: 'modal',
          selector: '#passwordlessBottomBanner',
          stage: 'flyin-co-design',
          width: modalWidth
        });
        break;

      case 'showFlyin-compass-no-x':
        this.showScreen({
          screen: 'register',
          mode: 'modal',
          selector: '#passwordlessBottomBanner',
          stage: 'flyin-compass',
          width: modalWidth,
          showCloseButton: false
        });
        break;

      case 'showFlyin-co-design-no-x':
        this.showScreen({
          screen: 'register',
          mode: 'modal',
          selector: '#passwordlessBottomBanner',
          stage: 'flyin-co-design',
          width: modalWidth,
          showCloseButton: false
        });
        break;

      default:
        break;
    }
  }

  // Listen for custom events defined in Piano templates
  onCustomEvent(event) {
    const { eventName, params } = event;
    const eventParams = JSON.parse(get(params, 'params', '{}')); // eslint-disable-line

    switch (eventName) {
      case 'offer-subscribe':
        break;
      case 'back_button_click':
        break;
      case 'shipping_save':
        break;
      case 'close_icon_confirmation_screen':
        this.checkUserAccess().then(({ userAccessIds, hasMagazineAccess }) => {
          this.setState({ userAccessIds, hasMagazineAccess });
        });
        break;
      default:
        break;
    }
  }

  // Fired AFTER an experience has been executed
  onExperienceExecute(experience) {
    this.setState({
      experience
    });
  }

  setUpApplePay() {
    window.tp.push(['setApplePayMerchantId', 'merchant.com.applepay.inc']);
  }

  // Map supplied set of handlers and attach to callback functions
  setPianoHandlers(handlers) {
    handlers.map(handler => {
      tp.push([ // eslint-disable-line
        'addHandler',
        handler,
        (data, secondary) => {
          this[`on${handler.charAt(0).toUpperCase() + handler.slice(1)}`](
            Object.assign({}, data, secondary)
          );
          return true;
        }
      ]);
      return true;
    });
  }

  // /*------------------------------------------
  // * GETTERS & SETTERS FOR USER CONTEXT/STATE
  // * getUser, setUser, ...
  // --------------------------------------------*/
  setCurrentUser(user, event) {
    const { uid, valid } = user;

    if (!uid) return;

    this.updateUserLoginStatus();

    if (event) {
      // Track Login Event
      const loginEvent = new EventModel();
      loginEvent.addToDataLayer();
    }

    this.setState({
      user,
      isUserValid: valid
    });

    // Update the data layer object
    if (canUseDOM) {
      set(
        window,
        'digitalData.user[0].profile[0].profileInfo.profileID',
        uid || ''
      );
    }
  }

  // Send custom data back to Piano iframe for use/display
  sendMessageToPiano(iframeId, success, message, object) {
    const iframe = document.getElementById(iframeId);
    if (iframe) {
      iframe.contentWindow.postMessage(
        {
          zig: 'zag',
          piano: {
            success,
            message,
            object
          }
        },
        '*'
      );
    }
  }

  inlineRegisterDisplayed() {
    const selector = '.pianoInline-wrapper';
    const element = document.querySelector(selector);
    const elementStyleDisplay = element.style.display;

    if (element && element.style.display !== 'none') {
      element.style.display = 'none';
    }

    setTimeout(function display() {
      if (element && element.style.display === 'none') {
        element.style.display = elementStyleDisplay;
        element.parentElement.style.display = elementStyleDisplay;
        // solution from shivang for sc-51586
        element.querySelector('iframe').style.width = '100%';
      }
    }, 1000);
  }

  updateUserLoginStatus() {
    const digitalDataUser = get(
      window,
      'digitalData.user[0].profile[0].attributes',
      false
    );
    if (canUseDOM && digitalDataUser) {
      window.digitalData.user[0].profile[0].attributes.isLoggedIn = this.isLoggedIn();
    }
  }

  /*------------------------------------------------
  * GLOBAL HANDLERS FOR PIANO JOURNEY CUSTOM EVENTS
  * Used for geotargeting, etc.
  -------------------------------------------------*/
  listenForJourneyEvents(handlers) {
    // eslint-disable-next-line array-callback-return
    handlers.map(handler => {
      document.body.addEventListener(`${handler}`, event => {
        const eventResponse = get(event, 'detail', {});
        this[`on${handler.charAt(0).toUpperCase() + handler.slice(1)}`](
          eventResponse
        );
      });
    });
  }

  logoutUser() {
    // Logout local application state
    this.setState(
      {
        user: {},
        userAccessIds: [],
        hasMagazineAccess: false,
        isLoggedIn: false,
        isUserValid: false
      },
      () => {
        // Logout Piano state
        window.tp.push([
          'init',
          () => {
            tp.pianoId.logout((response) => { // eslint-disable-line
              // Redirect back to homepage
              // window.location.replace("/");
            });
          }
        ]);
        // "refresh" blueconic so listeners will be executed again
        if (typeof window.blueConicClient.handlePageView === 'function') {
          window.blueConicClient.handlePageView();
        }
      }
    );
  }

  resetExperienceCookie() {
    if (canUseDOM) {
      Cookies.remove('xbc', {
        domain:
          process.env.NODE_ENV === 'development'
            ? 'fastcompany.local'
            : 'fastcompany.com'
      });
    }
  }

  resetUserIdentityCookie() {
    if (canUseDOM) {
      Cookies.remove('__utp', {
        domain:
          process.env.NODE_ENV === 'development'
            ? 'fastcompany.local'
            : 'fastcompany.com'
      });
    }
  }

  resetBrowserCookie() {
    if (canUseDOM) {
      Cookies.remove('__tbc', {
        domain:
          process.env.NODE_ENV === 'development'
            ? 'fastcompany.local'
            : 'fastcompany.com'
      });
    }
  }

  flushAllCookies() {
    this.resetExperienceCookie();
    this.resetUserIdentityCookie();
    this.resetBrowserCookie();

    // refresh the page
    if (canUseDOM) {
      // window.location.reload();
      window.location.href = `${window.location.pathname}?jibboo`;
    }
  }

  subscribeToBcEvents() {
    if (this.subscribedBcEvents) return;

    // fires when any blueconic contentMeter Exceeded
    window.blueConicClient.event.subscribe(
      'contentMeterExceeded',
      this,
      function meterExceeded() {
        this.setState({
          premiumPaywallActive: true
        });
      }
    );

    this.subscribedBcEvents = true;
  }

  /*------------------------------------------
  * SHOW OFFERS
  * screen.show, offer.show, template.show
  --------------------------------------------*/
  // Show a Piano offer to the user
  showOffer(
    offerId,
    templateId,
    displayMode = 'inline',
    selector = '',
    templateVariantId = ''
  ) {
    // console.log("showing offer at",selector);
    tp.push(["init", () => { // eslint-disable-line
        // Show the offer
      tp.offer.show({ // eslint-disable-line
          offerId,
          templateId,
          templateVariantId,
          displayMode,
          containerSelector: selector
        });
      }
    ]);
  }

  // Show any standard piano screen to the user
  showScreen({
    screen,
    mode,
    selector = '',
    stage = 'default',
    registerDisplayed = null,
    showCloseButton
  }) {
    tp.push(['init', () => { // eslint-disable-line
      tp.pianoId.show({ // eslint-disable-line
          screen,
          displayMode: mode,
          containerSelector: selector,
          stage,
          registerDisplayed,
          showCloseButton
        });
      }
    ]);
  }

  showUserAccount(selector = '', displayMode = 'inline') {
    // eslint-disable-next-line no-undef
    tp.push([
      'init',
      () => {
        // eslint-disable-next-line no-undef
        tp.myaccount.show({
          displayMode,
          containerSelector: selector
        });
      }
    ]);
  }

  // showTemplate = (templateId) => {
  //   tp.push(["init", () => {
  //     tp.template.show({
  //       templateId: templateId
  //     });
  //   }]);
  // }

  fetchFromPianoApi(endpoint, data, callback) {
    window.tp.push([
      'init',
      () => {
        window.tp.api.callApi(endpoint, data, callback);
      }
    ]);
  }

  // /*------------------------------------------
  // * HELPERS
  // * Functions to increase dev productivity
  // --------------------------------------------*/
  isStudentUser() {
    const studentDomains = /.edu|.ac.uk|.ac.in|ac.za/;
    const currentUserEmail = get(this.state, 'user.email', '');
    return studentDomains.test(currentUserEmail);
  }

  hasPaywallAccess(postId) {
    const { userAccessIds } = this.state;

    if (!userAccessIds?.length) {
      return false;
    }

    // check special one-off article(s) access tied to a specific resource
    // i.e. only users with R1WJM85 can view post 91048113
    if (postId === 91048113) {
      return userAccessIds?.includes('R1WJM85');
    }

    // check regular user access logic
    // if user rids match paywallAccessIds, user has valid access to remove paywall
    const paywallAccessIds = [
      'RL7LFMG',
      'RLJYHKC',
      'RDRR1PM',
      'R1WJM85',
      'RON7AJ3'
    ];
    const userHasPaywallAccess = paywallAccessIds?.some(
      validId => userAccessIds?.indexOf(validId) >= 0
    );

    return userHasPaywallAccess;
  }

  checkUserAccess() {
    return new Promise((resolve, reject) => { // eslint-disable-line
      // don't show paywall if user have access to valid resources
      const magazineAccessIds = ['RL7LFMG', 'RDRR1PM', 'R1WJM85', 'RON7AJ3'];
      const checkAccess = new Promise((resolve, reject) => { // eslint-disable-line
        this.fetchFromPianoApi('/access/list', { aid: PIANO_AID }, response => {
          // create array of all rids the user has access to
          const userAccessIds = response?.data?.map(
            access => access?.resource?.rid
          );

          // this is for "digital magazine" access in masthead.js
          // since not all paywall resources allow digital magazine access
          const hasMagazineAccess = magazineAccessIds?.some(
            validId => userAccessIds?.indexOf(validId) >= 0
          );

          return resolve({ userAccessIds, hasMagazineAccess });
        });
      });
      checkAccess.then(userAccess => {
        resolve(userAccess);
      });
    });
  }

  isLoggedIn() {
    const user = get(this.state, 'user', {});
    return !isEmpty(user);
  }

  blueConicLoaded() {
    return (
      typeof window !== 'undefined' &&
      typeof window.blueConicClient !== 'undefined' &&
      typeof window.blueConicClient.event !== 'undefined' &&
      typeof window.blueConicClient.event.subscribe !== 'undefined'
    );
  }

  /* eslint-disable no-undef */
  retriggerPiano(type, data) {
    const typeLookup = {
      category() {
        const pageType = 'category';
        const category = get(data, 'slug', 'news');

        tp.push(['setContentSection', category]);
        tp.push(['setCustomVariable', 'primaryCategory', category]);
        tp.push(['setCustomVariable', 'contentType', pageType]);
        tp.push(['setTags', ['categoryPage', category]]);
      },
      tag() {
        const pageType = 'tag';
        const tagName = get(data, 'slug', 'news');

        tp.push(['setContentSection', tagName]);
        tp.push(['setCustomVariable', 'primaryCategory', tagName]);
        tp.push(['setCustomVariable', 'contentType', pageType]);
        tp.push(['setTags', ['tagPage', tagName]]);
      }
    };

    if (tp.experience) {
      if (typeLookup[type]) typeLookup[type]();

      tp.push([
        'init',
        () => {
          tp.experience.execute();
        }
      ]);
    }
  }

  retriggerPianoPost(postData, id, supertags) {
    // console.log("postData ", postData);
    this.setState({
      currentDrawer: get(postData, 'id', 'NOID'),
      meter: {}
    });

    const tags = get(postData, 'tags', []);
    const eyeBrowText = getPriorityTag(tags, supertags);

    let author = '';
    if (postData.author && postData.author.name !== '') {
      author = postData.author.name;
    }

    let primaryCategory = '';
    if (
      postData.categories &&
      postData.categories.length > 0 &&
      postData.categories[0] !== undefined
    ) {
      primaryCategory = postData.categories[0].name;
    }

    let subCategory = '';
    if (
      postData.categories &&
      postData.categories.length > 0 &&
      postData.categories[1] !== undefined
    ) {
      subCategory = postData.categories[1].name;
    } else {
      subCategory = primaryCategory;
    }

    const subSubCategory = eyeBrowText || 'misc';
    const pubdate = get(postData, 'formattedDate', '')
      ? `${get(postData, 'formattedDate', '')}`
      : '';

    const isSponsored = !!tags.filter(tag => tag.name.includes('_advertorial'))
      .length;

    let pageType = 'article';
    if (get(postData, 'posterSlideshow.length')) {
      pageType = 'slideshow';
    } else if (
      get(postData, 'hasVideoPoster') === true ||
      get(postData, 'posterVideo.feedId')
    ) {
      pageType = 'video';
    }

    tp.push(['setContentSection', primaryCategory]);
    tp.push(['setContentAuthor', author]);
    tp.push(['setContentCreated', pubdate]);
    tp.push(['setCustomVariable', 'primaryCategory', primaryCategory]);
    tp.push(['setCustomVariable', 'subCategory1', subCategory]);
    tp.push(['setCustomVariable', 'subCategory2', subSubCategory]);
    tp.push(['setCustomVariable', 'contentType', pageType]);
    tp.push(['setCustomVariable', 'articleType', pageType]);
    tp.push(['setCustomVariable', 'isSponsored', isSponsored]);
    tp.push(['setTags', tags.map(tag => tag.name)]);

    if (tp.experience) {
      tp.push([
        'init',
        () => {
          tp.experience.execute();
        }
      ]);
    }
  }

  isStudentOffer(offerId) {
    const studentOffers = [
      'OFVEO4N0MX2A',
      'OF8KNPZQK7KY',
      'OFOXZBWEXYF6',
      'OFJIR08Q5NGS'
    ]; // Array of all "student" offers
    return studentOffers.indexOf(offerId) > -1;
  }

  render() {
    const finalState = {
      user: get(this.state, 'user', {}),
      meter: get(this.state, 'meter', {}),
      activeOffer: get(this.state, 'activeOffer', {}),
      journey: get(this.state, 'journey', {}),
      offerPageDetails: get(this.state, 'offerPageDetails', {}),
      isUserValid: get(this.state, 'isUserValid', false),
      hasMagazineAccess: get(this.state, 'hasMagazineAccess', false),
      experience: get(this.state, 'experience', {}),
      premiumPaywallActive: get(this.state, 'premiumPaywallActive', false),
      isLoggedIn: this.isLoggedIn(),
      logoutUser: this.logoutUser,
      showScreen: this.showScreen,
      showOffer: this.showOffer,
      showUserAccount: this.showUserAccount,
      retriggerPianoPost: this.retriggerPianoPost,
      retriggerPiano: this.retriggerPiano,
      flushAllCookies: this.flushAllCookies,
      setUpApplePay: this.setUpApplePay,
      hasPaywallAccess: this.hasPaywallAccess
    };

    return (
      <UserContext.Provider value={finalState}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

UserProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.shape({})
  ]).isRequired
};

export const UserConsumer = UserContext.Consumer;
