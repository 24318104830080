import set from 'lodash/set';
import { canUseDOM } from 'exenv';
import asyncHomepageAction from '../../../../redux/actions/homepage/async_homepage_actions';
import log from '../../../../services/logger_service';

export default function fetchDataWithStore(store, isAllIndex) {
  return function fetchData(nextState, replace, callback) {
    set(nextState.params, 'excludeFromHomepage', true);

    if (isAllIndex) {
      set(nextState.params, 'page', 1);
    }
    const { dispatch } = store;
    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        log.info(store.getState().config.initialPageLoad);
        if (callback) {
          return callback();
        }
        return false;
      }
      asyncHomepageAction(nextState.params, store)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return asyncHomepageAction(nextState.params, store)(dispatch)
      .then(function cb() {
        if (callback) {
          return callback();
        }
        return false;
      })
      .catch(function handleActionError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback();
        }
        return false;
      });
  };
}
