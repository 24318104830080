function formatAdPath(adPath) {
  // https://support.google.com/admanager/answer/1628457#ad-unit-codes
  const adUnits = adPath.split('/');
  return adUnits
    .map(adUnit =>
      adUnit
        .trim()
        .toLowerCase()
        .replace(/[^a-zA-Z0-9-_.*!:()]+/g, '-')
    )
    .join('/');
}

function formatTargeting(targeting) {
  const formattedTargeting = {};
  Object.keys(targeting).forEach(targetKey => {
    let targetVal = targeting[targetKey];
    if (!Array.isArray(targetVal)) {
      targetVal = [targetVal];
    }
    targetVal = targetVal.map(
      item =>
        item
          .toString()
          .trim()
          .toLowerCase()
          .replace(/\s\s+/g, ' ') // replace consecutive spaces with one space
          .replace(/\s/g, '-') // replace spaces with hyphens
          .replace(/[^\w-]+/g, '_') // replace non alphanumeric characters and non hyphens with underscores
    );
    targetVal = targetVal.filter(
      item => item && item !== 'undefined' && item !== 'null'
    );
    formattedTargeting[targetKey] = [...new Set(targetVal)];
  });

  return formattedTargeting;
}

export function formatPageContext(rawContext) {
  const adPath = formatAdPath(rawContext.adPath);
  const adUnits = adPath.split('/');
  const additionalTargeting = {
    chn: adUnits[3] || '',
    schn: adUnits[4] || ''
  };
  Object.assign(rawContext.targeting, additionalTargeting);
  const targeting = formatTargeting(rawContext.targeting);

  return {
    adPath,
    targeting
  };
}

export default formatPageContext;
