import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./group_subscriptions').default;
}

export default function(store) {
  const routeConfig = {
    path: '/GroupSubscriptions',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "subscribePage" */ './group_subscriptions').then(
        resp => {
          Component = resp.default;
          // routeConfig.loadData = () => {};
          return Component;
        }
      );
    },
    chunk: 'groupSubscriptions'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="group-subscriptions">
        <div className="group-subscriptions__wrapper">
          <div className="group-subscriptions__container">
            <LoadingSpinner />
          </div>
        </div>
      </section>
    )
  });

  return routeConfig;
}
