// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import extend from 'lodash/extend';

export default function(state = {}, action) {
  const typeMap = {
    AUTHOR_ALL_FEED_LOADING() {
      return {
        isLoading: true,
        authorAllFeed: action.authorAllFeed,
        allRendered: false
      };
    },
    AUTHOR_ALL_FEED_LOADED() {
      return {
        isLoading: false,
        data: get(action, 'authorAllFeed', {}),
        allRendered: false
      };
    },
    AUTHOR_ALL_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: get(action, 'authorAllFeed.errorMessage')
      };
    },
    AUTHOR_ALL_FEED_IS_LOADING() {
      return extend({}, state, { isEnabled: true, isLoading: true });
    },
    AUTHOR_ALL_FEED_IS_LOADED() {
      return {
        isEnabled: true,
        isLoading: false,
        data: extend({}, state.data, {
          page: get(action, 'authorAllFeed.page'),
          posts: [
            ...state.data.posts,
            ...get(action, 'authorAllFeed.posts', [])
          ]
        }),
        allRendered: false
      };
    },
    AUTHOR_ALL_FEED_IS_ERROR() {
      return {
        isEnabled: true,
        isLoading: true,
        data: extend({}, state, {
          page: get(action, 'authorAllFeed.page'),
          posts: [
            ...state.data.posts,
            ...get(action, 'authorAllFeed.posts', [])
          ]
        }),
        allRendered: false
      };
    },
    AUTHOR_ALL_FEED_ALL_STORIES_RENDERED() {
      return {
        isLoading: true,
        data: state.data,
        allRendered: true
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
