// https://github.com/reactjs/redux/issues/99
import { merge } from 'lodash';

export default function(state = {}, action) {
  const typeMap = {
    POST_NATIVE_AD_LOADING() {
      return {
        isLoading: true,
        data: action.postNativeAd
      };
    },
    POST_NATIVE_AD_LOADED() {
      const { nativeType } = action.postNativeAd;
      const newState = {
        isLoading: false,
        data: {
          [nativeType]: {
            posts: action.postNativeAd.posts
          }
        }
      };
      // merge to keep the old state
      return merge({}, state, newState);
    },
    POST_NATIVE_AD_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.postNativeAd.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
