import * as homepageActions from '../../action_creators/homepage/homepage_action_creators';
import asyncScrollingModuleActions from './async_scrolling_module_actions';
import asyncPromoBannerActions from '../async_promo_banner_actions';
import asyncAllFeedActions from './async_all_feed_actions';
import asyncRecircFeedActions from '../async_recirc_feed_actions';
import asyncFeaturedFeedActions from './async_featured_feed_actions';
import asyncMegatronNativeTileActions from './async_megatron_native_tile_actions';
import asyncHomepageTagActions from './async_homepage_tag_actions';
import asyncHomepageFeatureTermActions from './async_homepage_feature_term_actions';
import asyncHomepageVideoActions from './async_homepage_video_actions';
import asyncMagazineLatestIssuePostsActions from '../magazine/async_magazine_latest_issue_posts_actions';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import asyncFeaturedSponsoredCardActions from './async_featured_sponsored_card_actions';
import asyncHomePageTopPromoBarActions from './async_promoter_top_promo_bar_actions';

import log from '../../../services/logger_service';

export default function fetchPostDetail(params, store) {
  const state = store.getState();
  return function firstDispatch(dispatch) {
    dispatch(homepageActions.homepageLoading());
    if (params.page) {
      return Promise.all([
        asyncAllFeedActions(params, store)(dispatch),
        asyncRecircFeedActions(params, store)(dispatch)
      ])
        .then(function handlePostDetailData() {
          dispatch(homepageActions.homepageLoaded({}, store));
          return true;
        })
        .catch(function handleUserError(err) {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          dispatch(homepageActions.homepageLoadError(err, store));
          return log.error(
            err,
            `Error in homepage actions, message: ${err.message}`
          );
        });
    }
    const promises = [
      asyncFeaturedFeedActions(store)(dispatch),
      asyncFeaturedSponsoredCardActions(params, store)(dispatch),
      asyncPromoBannerActions(params, store)(dispatch),
      asyncScrollingModuleActions(params, store)(dispatch),
      asyncHomepageVideoActions(params, store)(dispatch),
      asyncHomepageTagActions(params, store)(dispatch),
      asyncHomepageFeatureTermActions(params, store)(dispatch),
      asyncAllFeedActions(params, store)(dispatch),
      asyncRecircFeedActions(params, store)(dispatch),
      asyncMagazineLatestIssuePostsActions(params, store)(dispatch),
      asyncHomePageTopPromoBarActions(params, store)(dispatch)
    ];

    if (state.config.featureFlags.isDynamicNativeTileEnabled) {
      promises.push(asyncMegatronNativeTileActions(params, store)(dispatch));
    }

    return Promise.all(promises)
      .then(function handlePostDetailData() {
        dispatch(homepageActions.homepageLoaded({}, store));
        return true;
      })
      .catch(function handleUserError(err) {
        dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
        dispatch(homepageActions.homepageLoadError(err, store));
        return log.error(
          err,
          `Error in homepage actions, message: ${err.message}`
        );
      });
  };
}
