import get from 'lodash/get';
import isUndefined from 'lodash/isUndefined';
import isString from 'lodash/isString';
import URL from 'url-parse';

const fcAssetsDomain = 'images.fastcompany.net';
const legacyAssetsDomain = 'assets.fastcompnay.com';
const cloudinaryDomain = 'fast-company-res.cloudinary.com';
const s3Domain = 'wp-cms-fastcompany-com.s3.amazonaws.com';

export function buildAssetUrlWithSizes(assetUrl, size) {
  if (typeof assetUrl === 'string' && typeof size === 'string') {
    const matchData = size.match(/w_(\d*)/);
    const width = get(matchData, '1');

    return width
      ? `${assetUrl
          .replace('/upload/', `/upload/${size}/`)
          .replace(`/fetch/`, `/fetch/${size}/`)} ${width}w`
      : '';
  }

  return '';
}

export function buildAssetUrlWithAuto(src) {
  return src && typeof src === 'string'
    ? src.replace('upload/', 'upload/w_auto,c_limit/')
    : '';
}

export function buildSrcSet(assetSizes, propsToSrcSetForSize, src) {
  const srcSetItems = get(assetSizes, propsToSrcSetForSize) || [];
  return srcSetItems.map(item => buildAssetUrlWithSizes(src, item)).join(', ');
}

export function makeHttps(url) {
  if (!isString(url)) {
    return null;
  }
  const parsed = new URL(url);
  if (parsed.protocol !== 'https:') {
    parsed.set('protocol', 'https:');
    return parsed.toString();
  }

  return url;
}

export function buildAssetUrlWithPresets(src, size, isDesktop) {
  const smallSizes = get(size, 'small.srcset');
  const smallSizesLength = get(smallSizes, 'length');
  const largestSmallPreset = get(smallSizes, `[${smallSizesLength - 1}]`);

  const largeSizes = get(size, 'large.srcset');
  const largeSizesLength = get(largeSizes, 'length');
  const largestLargePreset = get(largeSizes, `[${largeSizesLength - 1}]`);

  // this is to provide an asset size for gifs converted to videos
  // since they can't take an entire srcset string
  const presetSize = isDesktop ? largestLargePreset : largestSmallPreset;

  if (!isString(src)) {
    return null;
  }

  const parsed = new URL(src);

  if (
    parsed.host === cloudinaryDomain ||
    parsed.host === legacyAssetsDomain ||
    parsed.host === fcAssetsDomain
  ) {
    parsed.set(
      'pathname',
      parsed.pathname
        .replace('/upload/', `/upload/${presetSize}/`)
        .replace(`/fetch/`, `/fetch/${presetSize}/`)
    );
    return parsed.toString();
  }

  return src;
}

export function changeAssetDomain(url) {
  if (!isString(url)) {
    return null;
  }

  const parsed = new URL(url);

  // cloudinary fetch api must use cloudinary domain, otherwise, asset will 404
  const doesNotUseCloudinaryFetch = parsed.pathname.indexOf('/fetch/') === -1;
  if (parsed.host === cloudinaryDomain && doesNotUseCloudinaryFetch) {
    parsed.set('host', fcAssetsDomain);
    return parsed.toString();
  }
  if (parsed.host === s3Domain) {
    parsed.set('host', fcAssetsDomain);
    const { pathname } = parsed;
    parsed.set('pathname', `/image/upload/wp-cms${pathname}`);
    return parsed.toString();
  }

  return url;
}

export function changeAssetToWebp(url) {
  if (!isString(url)) {
    return null;
  }
  const fromRegex = /(png|jpg|jpeg)/i;
  return url.replace(fromRegex, 'webp');
}

export function convertGIFToVideoAssets(assetUrl) {
  if (!isString(assetUrl) || isUndefined(assetUrl)) {
    return null;
  }

  const parsed = new URL(assetUrl);
  const formats = ['webp', 'webm', 'mp4', 'gif'];
  const assets = {};
  if (
    parsed.host === fcAssetsDomain ||
    parsed.host === cloudinaryDomain ||
    parsed.host === legacyAssetsDomain
  ) {
    formats.forEach(function createAssetUrlForEachFormat(format) {
      assets[format] = assetUrl.replace('f_auto', `f_${format}`);
      if (format === 'mp4' || format === 'webm') {
        assets[format] = assets[format]
          .replace(',q_auto:best', ',q_70')
          .replace(',q_auto', ',q_70')
          .replace(',fl_lossy', '')
          .replace('w_1400', 'w_596');
      }

      if (format === 'jpg') {
        assets[format] = assets[format].replace('gif', 'jpg');
      }

      // Replace gif extension with video extension for appropriate formats
      if (format === 'webm') {
        const newExtension = '.webm';
        assets[format] = assets[format].replace(/\.(gif)$/, newExtension);
      }
      if (format === 'mp4') {
        const newExtension = '.mp4';
        assets[format] = assets[format].replace(/\.(gif)$/, newExtension);
      }
    });
    return assets;
  }

  return assetUrl;
}

// following breakpoints generated with https://www.responsivebreakpoints.com/ (size step 45kb on desktop, 25kb on mobile, max 5 image)
export const imageSizesLookup = {
  column: {
    large: {
      sizes: 'calc(0.1666 * (100vw - 40px))',
      srcset: [
        'w_112,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_306,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: [
        'w_141,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_236,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  4: {
    large: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: [
        'w_216,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_560,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: [
        'w_141,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_472,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  3: {
    large: {
      sizes: '33vw',
      srcset: [
        'w_301,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_772,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: [
        'w_141,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_472,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  2: {
    large: {
      sizes: '50vw',
      srcset: [
        'w_491,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_900,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: [
        'w_141,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_472,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  1: {
    large: {
      sizes: 'calc(0.666 * (100vw - 40px))',
      srcset: [
        'w_650,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_1045,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(100vw - 20px)',
      srcset: [
        'w_300,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_638,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  poster: {
    large: {
      sizes: 'calc(0.58333 * (100vw - 40px))',
      srcset: [
        'w_562,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_937,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_1250,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(100vw - 20px)',
      srcset: [
        'w_300,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_638,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  hero: {
    large: {
      sizes: '100vw',
      srcset: ['w_2480,ar_16:9,c_fill,g_auto,f_auto,q_auto:best']
    },
    small: {
      sizes: 'calc(100vw - 20px)',
      srcset: [
        'w_300,ar_16:9,c_fill,g_auto,f_auto,q_auto:best',
        'w_638,ar_16:9,c_fill,g_auto,f_auto,q_auto:best',
        'w_800,ar_16:9,c_fill,g_auto,f_auto,q_auto:best'
      ]
    }
  },
  meta: {
    large: {
      sizes: '100vw',
      srcset: ['w_1280,f_auto,q_auto,fl_lossy']
    }
  },
  slider: {
    large: {
      sizes: 'calc(0.58333 * (100vw - 40px))',
      srcset: [
        'w_562,c_limit,f_auto,q_auto,fl_lossy',
        'w_937,c_limit,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(100vw - 20px)',
      srcset: [
        'w_300,c_limit,f_auto,q_auto,fl_lossy',
        'w_638,c_limit,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  bigFirstMobile: {
    large: {
      sizes: 'calc(0.25 * (100vw - 40px))',
      srcset: [
        'w_772,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_302,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    },
    small: {
      sizes: 'calc(100vw - 20px)',
      srcset: [
        'w_300,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy',
        'w_638,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy'
      ]
    }
  },
  magazine: {
    large: {
      sizes: 'calc(0.25 * (100vw - 40px))',
      srcset: ['w_130,f_auto,q_auto,fl_lossy', 'w_750,f_auto,q_auto,fl_lossy']
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: ['w_180,f_auto,q_auto,fl_lossy', 'w_589,f_auto,q_auto,fl_lossy']
    }
  },
  mic2018Landscape: {
    large: {
      sizes: 'calc(0.5 * (100vw - 40px))',
      srcset: ['w_600,f_auto,q_auto,fl_lossy', 'w_1200,f_auto,q_auto,fl_lossy']
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: ['w_400,f_auto,q_auto,fl_lossy', 'w_800,f_auto,q_auto,fl_lossy']
    }
  },
  mic2018Portrait: {
    large: {
      sizes: 'calc(0.25 * (100vw - 40px))',
      srcset: ['w_400,f_auto,q_auto,fl_lossy', 'w_800,f_auto,q_auto,fl_lossy']
    },
    small: {
      sizes: 'calc(0.5 * (100vw - 20px))',
      srcset: ['w_400,f_auto,q_auto,fl_lossy', 'w_800,f_auto,q_auto,fl_lossy']
    }
  }
};
