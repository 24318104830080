import get from 'lodash/get';

export function mic2020CompanyLoading(resp, store = {}) {
  return {
    type: 'MIC_2020_COMPANY_LOADING',
    companies: [],
    store
  };
}

export function mic2020CompanyLoaded(resp = {}, store = {}) {
  return {
    type: 'MIC_2020_COMPANY_LOADED',
    micCompanies: get(resp, 'posts', []),
    store
  };
}

export function mic2020CompanyLoadError(error, store = {}) {
  return {
    type: 'MIC_2020_COMPANY_ERROR',
    micCompany: {
      isLoading: false,
      error: true,
      errorMessage: get(error, 'message', 'no message')
    },
    store
  };
}
