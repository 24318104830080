export function addQuotes(text) {
  return `"${text}"`;
}

export function deSlugify(text) {
  if (text === 'experience-economy') {
    return text;
  } else if (text) {
    return text.replace(/-/g, '');
  }
  return '';
}

export function slugify(text) {
  if (!text) {
    return '';
  }

  return (
    text
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      // eslint-disable-next-line no-useless-escape
      .replace(/[^\w\-]+/g, '') // Remove all non-word chars
      // eslint-disable-next-line no-useless-escape
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '')
  ); // Trim - from end of text
}

export function capitalize(string) {
  return typeof string === 'string'
    ? `${string.charAt(0).toUpperCase()}${string.slice(1)}`
    : '';
}

export default deSlugify;

export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  );
}
