import HomePage from './index_page';
import homepagePageStateManager from './homepage_state_manager';

const IS_ALL_INDEX = true;

export default function(store) {
  return {
    path: '/all',
    component: HomePage,
    exact: true,
    strict: true,
    loadData: homepagePageStateManager(store, IS_ALL_INDEX)
  };
}
