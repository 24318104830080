// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export function featuredSponsoredCardLoading(resp = {}, store = {}) {
  return {
    type: 'FEATURED_SPONSORED_CARD_LOADING',
    featuredSponsoredCard: get(resp, 'featuredSponsoredCard'),
    store
  };
}

export function featuredSponsoredCardLoaded(resp = {}, store) {
  return {
    type: 'FEATURED_SPONSORED_CARD_LOADED',
    featuredSponsoredCard: get(resp, 'featuredSponsoredCard'),
    store
  };
}

export function featuredSponsoredCardLoadError(error, store) {
  return {
    type: 'FEATURED_SPONSORED_CARD_ERROR',
    featuredSponsoredCard: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
