export function megatronNativeTileLoading(resp = {}, store = {}) {
  return {
    type: 'MEGATRON_NATIVE_TILE_LOADING',
    megatron_native_tile: resp,
    store
  };
}

export function megatronNativeTileLoaded(resp = {}, store) {
  return {
    type: 'MEGATRON_NATIVE_TILE_LOADED',
    megatron_native_tile: resp,
    store
  };
}

export function megatronNativeTileLoadError(error, store) {
  return {
    type: 'MEGATRON_NATIVE_TILE_ERROR',
    megatron_native_tile: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
