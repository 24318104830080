import React from 'react';
import PropTypes from 'prop-types';

export default function Style({ innerHtml }) {
  if (!innerHtml) {
    return null;
  }
  return <style dangerouslySetInnerHTML={{ __html: innerHtml }} />;
}

Style.defaultProps = {
  innerHtml: ''
};

Style.propTypes = {
  innerHtml: PropTypes.string
};
