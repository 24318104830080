import * as postMoreStoriesActions from '../../action_creators/post/post_more_stories_action_creators';
import PostMoreStoriesModel from '../../../models/post/post_more_stories_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(postMoreStoriesActions.postMoreStoriesLoading({}, store));

    const state = store.getState();
    const { abtest } = state.config;

    return PostMoreStoriesModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(postMoreStoriesActions.postMoreStoriesLoaded(data, store));
      })
      .catch(function handleUserError(err) {
        dispatch(postMoreStoriesActions.postMoreStoriesLoadError(err, store));
        log.error(
          err,
          `Error in post see more actions, message: ${err.message}`
        );
      });
  };
}
