import get from 'lodash/get';
import extend from 'lodash/extend';
import scriptjs from 'scriptjs';

let logglyLoaded = false;

// import $ from 'jquery';
// import circularJSON from 'circular-json';
/**
 * Send log request to site.
 * @param {string} level The log level.
 * @param {Object} data The log data.
 * @param {string} message The log message.
 * @private
 * @returns {Promise}
 */
function log(level, data = {}, message) {
  const jsonData = {};

  // if (data.message && data.stack) {
  //   return $.getScript('https://cdnjs.cloudflare.com/ajax/libs/stacktrace.js/1.3.1/stacktrace.min.js',
  //     () => {
  //       window.StackTrace.fromError(data)
  //         .then((dataWithStackTraces) => {
  //           jsonData.message = data.message;
  //           jsonData.stack = dataWithStackTraces;
  //           return axios({
  //             url: '/api/v1/log', // TODO: talk with team to make api absorb these logs?
  //             method: 'POST',
  //             dataType: 'json',
  //             data: {
  //               level,
  //               data: circularJSON.stringify(jsonData),
  //               message
  //             }
  //           });
  //         })
  //         .catch(function handleErrorGracefully(lerr) {
  //           // TODO: track in ga here
  // eslint-disable-next-line no-console
  //           console.error(lerr);
  //         });
  //     });
  // }

  extend(jsonData, data);
  if (!logglyLoaded) {
    return scriptjs(
      '//cloudfront.loggly.com/js/loggly.tracker-2.1.min.js',
      () => {
        logglyLoaded = true;
      }
    );
  }
  // eslint-disable-next-line no-underscore-dangle
  return window._LTracker.push({
    level,
    data: {
      message: jsonData.message,
      stack: jsonData.stack
    },
    message
  });
}

/**
 * Send log request to server side.
 * @typedef {function} logMethod
 * @param {Object|string} data The log data or message.
 * @param {string=} message The log message if the log data was specified.
 * @returns {Promise}
 */

/**
 * Methods: trace, debug, info, warn, error, fatal.
 *
 * See methods API: https://github.com/trentm/node-bunyan#log-method-api
 *
 * @example
 *   var log = require('./services/logger');
 *   log.error({ stack: err.stack }, 'Descriptive Error message' );
 *
 * @module services/logger
 */
class Logger {
  constructor() {
    extend(this, {
      /**
       * @name module:services/logger.trace
       * @type {logMethod}
       */
      trace: this.createLogFn('trace'),

      /**
       * @name module:services/logger.debug
       * @type {logMethod}
       */
      debug: this.createLogFn('debug'),

      /**
       * @name module:services/logger.info
       * @type {logMethod}
       */
      info: this.createLogFn('info'),

      /**
       * @name module:services/logger.warn
       * @type {logMethod}
       */
      warn: this.createLogFn('warn'),

      /**
       * @name module:services/logger.error
       * @type {logMethod}
       */
      error: this.createLogFn('error'),

      /**
       * @name module:services/logger.fatal
       * @type {logMethod}
       */
      fatal: this.createLogFn('fatal')
    });
  }

  /**
   * Factory to create log methods.
   * @param {string} level The log level method name.
   * @private
   * @returns {function}
   */
  createLogFn(level) {
    return function logFn(data, message) {
      if (!data && !message) {
        // console error if in development
        return false;
      }

      // TODO: clean up here

      // uncomment from here
      // const prodLog =
      //   level === 'trace' ||
      //   level === 'debug' ||
      //   level === 'info' ||
      //   level === 'warn';

      // if (this.env === 'production' && prodLog) {
      //   return false;
      // }

      // uncomment end here

      const currentInfo = {
        current_location: window.location.href
      };

      if (typeof data === 'string') {
        return log(level, extend({}, this.data, currentInfo), data);
      }

      if (this.env === 'development') {
        if (data.stack && data.message) {
          // eslint-disable-next-line no-console
          console.error(data);
        }
      }

      return log(level, extend(data, this.data, currentInfo), message);
    };
  }

  initialize(conf) {
    this.env = get(conf, 'config.env');
    this.data = extend(
      {},
      {
        site: get(conf, 'config.site'),
        userAgent: window.navigator.userAgent
      }
    );
  }
}

export default new Logger();
