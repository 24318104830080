import get from 'lodash/get';

export default function generateTagData(tags = []) {
  const data = {};

  tags.forEach(tag => {
    const { name } = tag;
    const slicedName = name.slice(0, 1);

    // there are cases of no star, hence this does not always work
    if (!data.advertiser && slicedName === '*') {
      data.advertiser = name
        .slice(1)
        .replace(/_/g, ' ')
        .replace(/\s?advertorial/g, '')
        .toUpperCase();
    } else if (!data.type && slicedName === '_') {
      data.type = name;
    }
  });

  return data;
}

export function findAdvertorialTagID(tags = []) {
  const advertorial = tags.filter(tag => tag.name.slice(0, 1) === '*');
  return !!advertorial.length && get(advertorial, '[0].slug');
}
