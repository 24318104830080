export default function(state = {}, action) {
  const typeMap = {
    MOST_CREATIVE_PEOPLE_SCROLLING_MODULE_LOADING() {
      return {
        isLoading: true,
        mostCreativePeopleScrollingModule:
          action.mostCreativePeopleScrollingModule
      };
    },
    MOST_CREATIVE_PEOPLE_SCROLLING_MODULE_LOADED() {
      return {
        isLoading: false,
        data: action.mostCreativePeopleScrollingModule
      };
    },
    MOST_CREATIVE_PEOPLE_SCROLLING_MODULE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.mostCreativePeopleScrollingModule.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
