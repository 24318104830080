import extend from 'lodash/extend';
import get from 'lodash/get';

export function mic2020RegionsLoading(resp = {}, store = {}) {
  return {
    type: 'MIC_2020_REGIONS_LOADING',
    micRegions: resp,
    store
  };
}

export function mic2020RegionsLoaded(resp = {}, store = {}, year, sector) {
  extend(resp, { year });
  return {
    type: 'MIC_2020_REGIONS_LOADED',
    micRegions: get(resp, 'posts', []),
    store,
    year,
    sector
  };
}

export function mic2020RegionsLoadError(error, store = {}) {
  return {
    type: 'MIC_2020_REGIONS_ERROR',
    micRegions: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
