// import { canUseDOM } from 'exenv';

export function postLoading(resp = {}, store = {}) {
  return {
    type: 'POST_LOADING',
    post: resp,
    store
  };
}

export function postLoaded(resp = {}, store = {}) {
  return {
    type: 'POST_LOADED',
    post: resp,
    store
  };
}

export function postLoadError(error, store = {}) {
  return {
    type: 'POST_ERROR',
    post: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
