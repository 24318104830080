import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let CollectionComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  CollectionComponent = require('../../custom_collection/custom_collection_page')
    .default;
}

export default function(store) {
  const routeConfig = {
    path: `/section/fast-company-executive-board`,
    component() {
      if (CollectionComponent) {
        return <CollectionComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: CollectionComponent
      ? CollectionComponent.loadData(store, 'fast-company-executive-board')
      : false,
    preloadChunk() {
      return import(/* webpackChunkName: "customCollectionPage" */ '../../custom_collection/custom_collection_page').then(
        resp => {
          CollectionComponent = resp.default;
          routeConfig.loadData = CollectionComponent.loadData(
            store,
            'fast-company-executive-board'
          );
          return CollectionComponent;
        }
      );
    },
    chunk: 'tagPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="tag-page">
        <article className="tag-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
