import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';
import striptags from 'striptags';

export default function(state = {}, action = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const wpTitle = get(action, 'franchiseApplyPage.acf.title');
  const img = get(
    action,
    'franchiseApplyPage._embedded.wp:featuredmedia[0].source_url'
  );
  const wpExcerpt = get(action, 'franchiseApplyPage.excerpt.rendered');
  const socialTitle = wpTitle ? striptags(wpTitle).trim() : ''; // displays for twitter and social platforms
  const seoTitle = get(action, 'franchiseApplyPage.acf.title'); // displays on google and browser tab
  const description = wpExcerpt ? striptags(wpExcerpt).trim() : '';
  const url = get(action, 'franchiseApplyPage.acf.publicUrl');

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle,
    url,
    description,
    image: img,
    articleSection: 'Franchise Application Page',
    config: get(state, 'config'),
    facebookTitleOverride: `${socialTitle} | Fast Company`,
    twitterTitleOverride: `${socialTitle} | Fast Company`
  });
}
