// import { canUseDOM } from 'exenv';

export function homepageLoading(resp = {}, store = {}) {
  return {
    type: 'HOMEPAGE_LOADING',
    homepage: resp,
    store
  };
}

export function homepageLoaded(resp = {}, store) {
  return {
    type: 'HOMEPAGE_LOADED',
    homepage: resp,
    store
  };
}

export function homepageLoadError(error, store) {
  return {
    type: 'HOMEPAGE_ERROR',
    homepage: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
