import get from 'lodash/get';
import * as postpageActions from '../../action_creators/post/postpage_action_creators';
import asyncPostActions from './async_post_actions';
import asyncPostVideoActions from './async_post_video_actions';
import asyncPostMoreStoriesActions from './async_post_more_stories_actions';
import asyncAfterbodyRecircModuleTypeActions from './async_afterbody_recirc_module_type_actions';
import asyncPromoterArticleTextActions from './async_promoter_article_text_actions';

import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import asyncRecircFeedActions from '../async_recirc_feed_actions';
import asyncSupertagsActions from '../async_supertags_actions';
import log from '../../../services/logger_service';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(postpageActions.postpageLoading({}, store));

    const asyncPostActionsList = [
      asyncAfterbodyRecircModuleTypeActions(params, store)(dispatch),
      asyncPromoterArticleTextActions(params, store)(dispatch),
      asyncPostActions(params, store)(dispatch),
      asyncPostVideoActions(params, store)(dispatch),
      asyncPostMoreStoriesActions(params, store)(dispatch),
      asyncRecircFeedActions(params, store)(dispatch),
      asyncSupertagsActions(params, store)(dispatch)
    ];

    return Promise.all(asyncPostActionsList)
      .then(function handlePostDetailData() {
        dispatch(postpageActions.postpageLoaded({}, store));
        return true;
      })
      .catch(function handleUserError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
        }
        dispatch(postpageActions.postpageLoadError(err, store));
        log.error(err, `Error in post page actions, message: ${err.message}`);
      });
  };
}
