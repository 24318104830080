import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const sitemapPostPageTitle = get(state, 'htmlSitemapPost.data.title');
  const description = `Site map for ${sitemapPostPageTitle}`;
  const title = `${sitemapPostPageTitle}`;

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    url: get(state, 'config.navHistory[0]'),
    description,
    articleSection: title,
    config: state.config
  });

  return data;
}
