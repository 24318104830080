export default function(state = {}, action) {
  const typeMap = {
    BRANDS_THAT_MATTER_PAGE_LOADING() {
      return {
        isLoading: true,
        brandsThatMatterPage: action.brandsThatMatterPage
      };
    },
    BRANDS_THAT_MATTER_PAGE_LOADED() {
      return {
        isLoading: false,
        data: action.brandsThatMatterPage
      };
    },
    BRANDS_THAT_MATTER_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.brandsThatMatterPage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
