import * as homepageFeatureTermActions from '../../action_creators/homepage/homepage_feature_term_action_creators';
import HomepageFeatureTermModel from '../../../models/homepage/homepage_feature_term_module';
import log from '../../../services/logger_service';

export default function fetchHomepageFeatureTerm(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(homepageFeatureTermActions.homepageFeatureTermLoading({}, store));
    return HomepageFeatureTermModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        dispatch(
          homepageFeatureTermActions.homepageFeatureTermLoaded(data, store)
        );
      })
      .catch(function handleUserError(err) {
        dispatch(
          homepageFeatureTermActions.homepageFeatureTermLoadError(err, store)
        );
        log.error(
          err,
          `Error in homepage feature term actions, message: ${err.message}`
        );
      });
  };
}
