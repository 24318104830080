import get from 'lodash/get';

const MAX_VIDEO_PLAYLIST_LENGTH = 50;

export default function(state = {}, action) {
  const typeMap = {
    POST_VIDEO_LOADING() {
      return {
        isLoading: true,
        postVideo: action.postVideo
      };
    },
    POST_VIDEO_LOADED() {
      if (
        get(action, 'postVideo.playlist', []).length > MAX_VIDEO_PLAYLIST_LENGTH
      ) {
        get(
          action,
          'postVideo.playlist',
          []
        ).length = MAX_VIDEO_PLAYLIST_LENGTH;
      }
      return {
        isLoading: false,
        data: action.postVideo
      };
    },
    POST_VIDEO_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.postVideo.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
