import get from 'lodash/get';
import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  // Strip tags and trim the string
  const cleanString = string => striptags(string).trim();

  const micIxPageObject = get(action, 'micIxPage');
  const micIxPageAcf = get(action, 'micIxPage.acf');
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const seoTitle = cleanString(get(micIxPageObject, 'title.rendered'));
  const title = seoTitle || 'Most Innovative Companies Index Page';
  const description =
    cleanString(get(micIxPageAcf, 'seoDescription')) ||
    `An exclusive look at the World's most Innovative Companies index page.`;
  const url = micIxPageAcf?.publicUrl || 'https://www.fastcompany.com/mic-ix';

  return baseSeoUtil({
    site,
    siteConf,
    overrideTitle: true,
    title,
    url,
    description,
    config: get(state, 'config'),
    facebookTitleOverride: seoTitle,
    twitterTitleOverride: seoTitle
  });
}
