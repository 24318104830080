// import { canUseDOM } from 'exenv';

export function categoryPromoBannerLoading(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_PROMO_BANNER_LOADING',
    categoryPromoBanner: resp,
    store
  };
}

export function categoryPromoBannerLoaded(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_PROMO_BANNER_LOADED',
    categoryPromoBanner: resp.categoryPromoBanner,
    store
  };
}

export function categoryPromoBannerLoadError(error, store = {}) {
  return {
    type: 'CATEGORY_PROMO_BANNER_ERROR',
    categoryPromoBanner: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
