// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    CATEGORY_FEATURED_FEED_LOADING() {
      return {
        isLoading: true,
        categoryFeaturedFeed: action.categoryFeaturedFeed
      };
    },
    CATEGORY_FEATURED_FEED_LOADED() {
      return {
        isLoading: false,
        featured: get(action, 'categoryFeaturedFeed.featured'),
        top: get(action, 'categoryFeaturedFeed.top'),
        category: get(action, 'categoryFeaturedFeed.category'),
        source: get(action, 'categoryFeaturedFeed.source')
      };
    },
    CATEGORY_FEATURED_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.categoryFeaturedFeed.errorMessage,
        featured: [],
        category: {},
        top: []
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
