import { canUseDOM } from 'exenv';
import asyncPostpageAction from '../../../../redux/actions/post/async_postpage_actions';
import log from '../../../../services/logger_service';

export default function fetchDataWithStore(store) {
  return function fetchData(nextState, replace, callback) {
    const { dispatch } = store;

    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        if (callback) {
          return callback();
        }
        return false;
      }
      asyncPostpageAction(nextState.params, store)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return asyncPostpageAction(nextState.params, store)(dispatch)
      .then(function cb() {
        if (callback) {
          if (callback) {
            return callback();
          }
          return false;
        }
        return false;
      })
      .catch(function handleActionError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback(error);
        }
        return false;
      });
  };
}
