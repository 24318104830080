export function getSlug(config = {}) {
  const { navHistory = [] } = config;
  const urlString = navHistory[0] || '';
  if (!urlString) {
    return [];
  }

  try {
    const url = new URL(urlString) || {};
    const pathname = url.pathname || '';
    const slugParts = pathname.split('/');
    slugParts.shift();
    return slugParts;
  } catch (error) {
    return [];
  }
}

export default getSlug;
