import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const ggDetails = get(state, 'giftGuideDetail.data.rows[0]');
  const slugMapping = {
    1: 'holiday-2017',
    2: 'holiday-2018'
  };
  const slug = slugMapping[get(ggDetails, 'year_id')];

  return baseSeoUtil({
    site,
    siteConf,
    title: get(ggDetails, 'title', ''),
    url: `https://www.fastcompany.com/gift-guide/${slug}`,
    description: get(ggDetails, 'description', ''),
    image: get(ggDetails, 'logo_url', ''),
    articleSection: 'Gift Guide',
    config: state.config
  });
}
