// https://github.com/reactjs/react-router/blob/master/docs/guides/ServerRendering.md
// https://github.com/reactjs/react-router-redux/blob/master/examples/server/client.js
import P from 'promise-polyfill';
import assign from 'lodash/assign';
import Cookies from 'js-cookie';

if (!window.Promise) {
  window.Promise = P;
}

if (!Object.assign) {
  Object.assign = assign;
}

import 'lazysizes';
import React from 'react';
import { hydrate, render } from 'react-dom';
import createHistory from 'history/createBrowserHistory';
import matchRoutes from 'react-router-config/matchRoutes';
import scriptJS from 'scriptjs';
import get from 'lodash/get';
import TagManager from 'react-gtm-module';

import Root from '../views/containers/root_container';
import '../utils/patch_react';

// eslint-disable-next-line import/extensions
// import 'autotrack/autotrack.js';
import './utils/global_util';
import configureStore from '../redux/store/store';
import getRoutesWithStore from '../react_router/react_router';
import initialLoadActionCreator from '../redux/action_creators/initial_load_action_creator';
import log from './services/logger_service';
import cachingService from './services/caching_service';
import polyfillUtil from './utils/picture_polyfill_util';
import matchContext from '../config/manual_param_set';
import EventModel from '../utils/tracking/models';
import { loadableReady } from '@loadable/component';
import { shouldLogWebVitals, logWebVitals } from './utils/cwv_util';

// eslint-disable-next-line no-underscore-dangle
window.__forceSmoothScrollPolyfill__ = true;

function bootReact() {
  const browserHistory = createHistory();

  const originalHash = browserHistory.location.hash;

  browserHistory.location.hash = '';

  // get json here
  let bootstrappedConfig = false;
  let env = false;

  try {
    bootstrappedConfig = window.appState;
    env = get(bootstrappedConfig, 'config.env');
    if (env !== 'development') {
      const criticalCss = document.querySelector('.critical-css');
      bootstrappedConfig.config.criticalCss = criticalCss.innerHTML;
    }
    cachingService.initialize(bootstrappedConfig.config.cacheEnabled);
    log.initialize(bootstrappedConfig); // TODO: send, site etc for errors
    browserHistory.location.key = bootstrappedConfig.routing.location.key;
  } catch (error) {
    log.fatal(error, 'Fatal error parsing bootstrap json.');
  }

  if (!bootstrappedConfig) {
    bootstrappedConfig = {}; // TODO: default bootstrap to handle errors
  }

  // Enable Google CMP ahead of Tag Manager initialization
  window.gtag_enable_tcf_support = true;

  // GOOGLE TAG MANAGER
  const tagManagerArgs = {
    gtmId: 'GTM-KM6J9JF'
  };

  TagManager.initialize(tagManagerArgs);
  // GOOGLE TAG MANAGER

  // Track initial page view
  const pageViewEvent = new EventModel();
  pageViewEvent.addToDataLayer('page_view');

  const store = configureStore(browserHistory, bootstrappedConfig, env);
  const routesToMatch = getRoutesWithStore(store);
  const preloadChunks = matchRoutes(
    routesToMatch,
    // eslint-disable-next-line no-restricted-globals
    location.pathname.toLowerCase()
  ).reduce(function matchReduce(list, { route, match }) {
    if (match.url && match.url.includes('/fcw')) {
      const splitUrl = match.url.split('/');
      const param = match.params.page
        ? splitUrl[splitUrl.length - 2]
        : splitUrl[splitUrl.length - 1];
      const newParam = `${param.replace('-', '_')}_advertorial`;
      // eslint-disable-next-line no-param-reassign
      match.params.tag = newParam;
    }

    // This will manually set the params in cases where we can't pass them in the url
    matchContext.cases.forEach(foundCase => {
      if (match.url === foundCase.url) {
        // eslint-disable-next-line no-param-reassign
        match.params[foundCase.paramater] = foundCase.setTo;
      }
    });
    if (route.preloadChunk) {
      list.push(route.preloadChunk);
    }

    return list;
  }, []);

  polyfillUtil();

  browserHistory.listen((location /* , action */) => {
    const url = `${location.pathname}`;
    const branch = matchRoutes(routesToMatch, url);
    branch.map(function matchMap({ route, match }) {
      if (match.url && match.url.includes('/fcw')) {
        const splitUrl = match.url.split('/');
        const param = match.params.page
          ? splitUrl[splitUrl.length - 2]
          : splitUrl[splitUrl.length - 1];
        const newParam = `${param.replace('-', '_')}_advertorial`;
        // eslint-disable-next-line no-param-reassign
        match.params.tag = newParam;
      }

      // This will manually set the params in cases where we can't pass them in the url
      matchContext.cases.forEach(foundCase => {
        if (match.url === foundCase.url) {
          // eslint-disable-next-line no-param-reassign
          match.params[foundCase.paramater] = foundCase.setTo;
        }
      });
      if (route.preloadChunk) {
        return route.preloadChunk().then(() => {
          if (route.loadData) {
            if (!route.autoScrollOff) {
              window.scrollTo(0, 0);
            }

            return route.loadData(match);
          }
          return Promise.resolve(null);
        });
      }
      if (route.loadData) {
        if (!route.autoScrollOff) {
          window.scrollTo(0, 0);
        }

        if (window.localStorage.needToSetCookies) {
          Cookies.set('dailycookie', 'true', {
            expires: 1,
            domain: '.fastcompany.com'
          });

          window.localStorage.removeItem('needToSetDailyCookie');
        }

        return route.loadData(match);
      }
      return Promise.resolve(null);
    });
  });

  function renderedApp() {
    store.dispatch(initialLoadActionCreator());
    browserHistory.location.hash = originalHash;
    scriptJS(
      'https://cdnjs.cloudflare.com/ajax/libs/autotrack/2.2.0/autotrack.js'
    );
    document.documentElement.className +=
      document.documentElement.className === '' ? 'hydrated' : ' hydrated';
    if (shouldLogWebVitals()) {
      logWebVitals();
    }
  }

  if (!preloadChunks.length) {
    loadableReady(() => {
      hydrate(
        <Root
          store={store}
          history={browserHistory}
          requestUrl={window.location.pathname}
        />,
        document.getElementById('root'),
        renderedApp
      );
    });
  } else {
    Promise.all(preloadChunks.map(chunk => chunk())).then(() => {
      loadableReady(() => {
        hydrate(
          <Root
            store={store}
            history={browserHistory}
            requestUrl={window.location.pathname}
          />,
          document.getElementById('root'),
          renderedApp
        );
      });
    });
  }

  if (module.hot) {
    module.hot.accept(
      ['../react_router/react_router', '../views/containers/root_container'],
      () => {
        // eslint-disable-next-line global-require
        const HotLoadRoot = require('../views/containers/root_container')
          .default;
        render(
          <HotLoadRoot store={store} history={browserHistory} />,
          document.getElementById('root')
        );
      }
    );
  }
}

// TODO: add image polyfill util here
if (!window.Map || !window.Set || !window.requestAnimationFrame) {
  scriptJS(
    [
      'https://cdnjs.cloudflare.com/ajax/libs/babel-polyfill/6.26.0/polyfill.min.js',
      'https://cdnjs.cloudflare.com/ajax/libs/dom4/1.8.3/dom4.js'
    ],
    bootReact
  );
} else {
  bootReact();
}
