// import { canUseDOM } from 'exenv';

export function postpageLoading(resp = {}, store = {}) {
  return {
    type: 'POSTPAGE_LOADING',
    postpage: resp,
    store
  };
}

export function postpageLoaded(resp = {}, store = {}) {
  return {
    type: 'POSTPAGE_LOADED',
    postpage: resp,
    store
  };
}

export function postpageLoadError(error, store = {}) {
  return {
    type: 'POSTPAGE_ERROR',
    postpage: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}

export function infiniteScrollUrlChange() {
  return {
    type: 'INFINITE_SCROLLPAGE_LOADED'
  };
}
