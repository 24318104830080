import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./html_sitemap_index_page').default;
}

export default function(store) {
  const routeConfig = {
    path: '/sitemap',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "sitemapIndex" */ './html_sitemap_index_page').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store);
          return Component;
        }
      );
    },
    chunk: 'sitemapIndex'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="author-page">
        <article className="author-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

let FranchiseComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  FranchiseComponent = require('./html_sitemap_index_page').default;
}

export function sitemapFranchises(store) {
  const routeConfig = {
    path: '/sitemap/franchise/:franchise?',
    component() {
      if (FranchiseComponent) {
        return <FranchiseComponent />;
      }

      return <LazyComponent />;
    },
    exact: false,
    // strict: true,
    loadData: FranchiseComponent ? FranchiseComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "sitemapIndex" */ './html_sitemap_index_page').then(
        resp => {
          FranchiseComponent = resp.default;
          routeConfig.loadData = FranchiseComponent.loadData(store);
          return FranchiseComponent;
        }
      );
    },
    chunk: 'sitemapIndex'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="sitemap-franchise-page">
        <article className="sitemap-franchise__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

let HubComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  HubComponent = require('./html_sitemap_index_page').default;
}

export function sitemapHubs(store) {
  const routeConfig = {
    path: '/sitemap/hub/:slug?',
    component() {
      if (HubComponent) {
        return <HubComponent />;
      }

      return <LazyComponent />;
    },
    exact: false,
    // strict: true,
    loadData: HubComponent ? HubComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "sitemapIndex" */ './html_sitemap_index_page').then(
        resp => {
          HubComponent = resp.default;
          routeConfig.loadData = HubComponent.loadData(store);
          return HubComponent;
        }
      );
    },
    chunk: 'sitemapIndex'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="sitemap-franchise-page">
        <article className="sitemap-franchise__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
