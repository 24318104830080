import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import allFeedISActions from '../../../../redux/actions/homepage/async_is_all_feed_actions';
import { hideRecircAction } from '../../../../redux/actions/async_recirc_feed_actions';

import loadable from '@loadable/component';

const Megatron = loadable(() =>
  import(/* webpackChunkName: "feed" */ '../../../components/megatron/megatron')
);
const FeatureFeed = loadable(() =>
  import(/* webpackChunkName: "feed" */ '../../../components/feature_feed/feature_feed')
);
const NotFound = loadable(() =>
  import('../../../components/not_found_component/not_found_component')
);
const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);
const AllFeed = loadable(() =>
  import(/* webpackChunkName: "feed" */ '../../../components/all_feed/all_feed')
);
const VideoModule = loadable(() =>
  import('../../../components/video_module/video_module')
);
const Slug = loadable(() => import('../../../components/slug/slug'));
const FeatureTerm = loadable(() =>
  import(/* webpackChunkName: "stories" */ '../../../components/feature_term/feature_term')
);
const FranchiseRow = loadable(() =>
  import(/* webpackChunkName: "stories" */ '../../../components/franchise_showcase/franchise_showcase')
);
const MoreStories = loadable(() =>
  import(/* webpackChunkName: "stories" */ '../../../components/more-stories-component/more-stories-component')
);
const AdContainer = loadable(() =>
  import('../../../components/ad/AdContainer')
);

function filterDisplayedArticles(scrollingModule, allFeed) {
  const scrollingPosts = scrollingModule?.posts || [];
  const allFeedPosts = allFeed?.posts || [];

  const scrollingPostIds = scrollingPosts.map(post => post.id);

  const filteredPosts = allFeedPosts.filter(
    post => !scrollingPostIds.includes(post.id)
  );

  return filteredPosts;
}

function Homepage({
  config,
  homepage,
  homepageVideo,
  homepageTag,
  homepageFeatureTerm,
  scrollingModule,
  promoBanner,
  featuredSponsoredCard,
  allFeed,
  featuredFeed,
  megatronNativeTile,
  allISFeedActions,
  hideRecircFeed,
  match,
  location
}) {
  const [fcWorksCard, setFcWorksCard] = useState(null);

  const { isLoading = false, allRendered = false, page, totalPages } =
    allFeed || {};
  const { combined } = featuredFeed || {};
  const { playlist } = homepageVideo || {};
  // const { posts: homepagePosts } = homepageTag || {};
  const pageParam = match?.params?.page || false;
  const isLastPage = Number(page) >= totalPages;
  // this isnt needed anymore
  // const tags = homepagePosts?.[0]?.tags || [];

  useEffect(
    () => {
      const item = megatronNativeTile?.posts?.length
        ? megatronNativeTile.posts[
            Math.floor(Math.random() * megatronNativeTile.posts.length)
          ]
        : null;

      if (
        !fcWorksCard &&
        item &&
        config?.featureFlags?.isDynamicNativeTileEnabled
      ) {
        setFcWorksCard(item);
      }
    },
    [megatronNativeTile]
  );

  if (homepage?.error) {
    return (
      <section className="homepage-page">
        <article className="homepage-page__main--error-pg">
          <NotFound />
        </article>
      </section>
    );
  }

  if (homepage?.isLoading) {
    return (
      <section className="homepage-page">
        <article className="homepage-page__main">
          <LoadingSpinner />
        </article>
      </section>
    );
  }

  const USE_ARTICLE_OVERRIDE = false; // Slot an override article into the first megatron spot.

  // BWI 2024 OVERRIDE
  const articleOverride = {
    categories: [{ name: 'WORK LIFE' }],
    featured_image: {
      source:
        'https://images.fastcompany.com/image/upload/f_webp,q_auto,c_fit/wp-cms-2/2024/09/BWFI.homepage.jpg'
    },
    title: 'See the Winners of the 2024 Best Workplaces for Innovators',
    link: 'https://www.fastcompany.com/best-workplaces-for-innovators/list'
  };

  const featureFeedCombined = combined || [];

  const megatronArticles = USE_ARTICLE_OVERRIDE
    ? [articleOverride, ...featureFeedCombined.slice(0, 2)]
    : featureFeedCombined.slice(0, 3);
  const megatronFeatured = USE_ARTICLE_OVERRIDE
    ? featureFeedCombined.slice(2, 4)
    : featureFeedCombined.slice(3, 5);

  const mobileArticleOverrideFeed = {
    featured: [articleOverride],
    top: [...featuredFeed.featured, ...featuredFeed.top.slice(0, 4)]
  };

  const allFeedPosts = filterDisplayedArticles(scrollingModule, allFeed);

  if (USE_ARTICLE_OVERRIDE) allFeedPosts.unshift(featureFeedCombined[4]);

  if (!pageParam && location?.pathname === '/') {
    return (
      <section className="homepage-page">
        <div className="homepage-desktop">
          <Megatron
            articles={megatronArticles}
            config={config}
            megatronNativeTile={megatronNativeTile}
            loading="eager"
          />
          <FeatureFeed
            adPostion={2}
            featuredFeed={{ featured: megatronFeatured }}
            promoBanner={promoBanner}
            scrollingModule={scrollingModule}
            page="homepage"
            featuredSponsoredCard={featuredSponsoredCard}
            config={config}
            shouldRenderDesktop={true}
            loading="lazy"
            noAds={true}
          />
        </div>
        <div className="homepage-mobile">
          <FeatureFeed
            featuredFeed={
              USE_ARTICLE_OVERRIDE ? mobileArticleOverrideFeed : featuredFeed
            }
            fcWorksCard={fcWorksCard}
            promoBanner={promoBanner}
            scrollingModule={{}}
            page="homepage"
            featuredSponsoredCard={featuredSponsoredCard}
            config={config}
            shouldRenderDesktop={false}
          />
          {featuredFeed.top.length >= 2 && (
            <div className="homepage-page__ff-ad-container">
              <AdContainer type="break" />
            </div>
          )}
        </div>

        <FeatureTerm data={homepageFeatureTerm} config={config} />

        {/* Hardcoding data since HP tag module in manage isnt hooked up to new CMS */}
        <section className="homepage-tag">
          <MoreStories
            metadata={homepageTag.posts}
            slugData={{ name: 'MARKETING INNOVATION' }}
            tags={[]}
            config={config}
          />
        </section>

        {homepageTag.posts.length > 0 && (
          <div className="homepage-page__tag-ad-container">
            <AdContainer type="break" />
          </div>
        )}

        {/* Restore VideoModule after G-P Sponsorship is over */}
        <VideoModule
          config={config}
          data={homepageVideo}
          playerid="76APbejE"
          stories={playlist}
        />

        {playlist.length > 0 && (
          <div className="homepage-page__video-ad-container">
            <AdContainer type="break" />
          </div>
        )}

        <FranchiseRow config={config} />

        {playlist.length === 0 && (
          <div className="homepage-page__video-ad-container">
            <AdContainer type="break" />
          </div>
        )}

        <AllFeed
          hasSlug={true}
          stories={allFeedPosts}
          allRendered={allFeed.allRendered}
          paginationEnabled={true}
          totalPages={totalPages}
          page={page}
          section="all"
          type="homepage"
          isLastPage={isLastPage}
          infiniteScrollEnabled={true}
          infiniteScrollAllFeedFetch={allISFeedActions}
          infiniteScrollLoading={isLoading}
          config={config}
          hideRecircFeed={hideRecircFeed}
          mobileAdCount={[4, null, 5]}
        />

        {allFeed.isLoading && <LoadingSpinner />}
      </section>
    );
  }
  return (
    <section className="homepage-page">
      <section className="page-title">
        <Slug slugName="All Stories" className="all-page" linkTo="/all" />
      </section>
      <AllFeed
        config={config}
        hasSlug={false}
        stories={allFeedPosts}
        allRendered={allRendered}
        paginationEnabled={true}
        page={page + 1}
        section="all"
        type="homepage"
        isLastPage={isLastPage}
      />
    </section>
  );
}
Homepage.propTypes = {
  config: PropTypes.shape({}).isRequired,
  homepage: PropTypes.shape({
    error: PropTypes.bool,
    isLoading: PropTypes.bool,
    errorMessage: PropTypes.string
  }).isRequired,
  homepageVideo: PropTypes.shape({
    playlist: PropTypes.arrayOf(PropTypes.shape({})),
    feedid: PropTypes.string,
    title: PropTypes.string
  }).isRequired,
  homepageTag: PropTypes.shape({
    tags: PropTypes.arrayOf(PropTypes.shape({})),
    posts: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  homepageFeatureTerm: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})),
    name: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  scrollingModule: PropTypes.shape({}).isRequired,
  promoBanner: PropTypes.shape({}).isRequired,
  featuredSponsoredCard: PropTypes.shape({}).isRequired,
  allFeed: PropTypes.shape({
    posts: PropTypes.arrayOf(PropTypes.shape({})),
    allRendered: PropTypes.bool
  }).isRequired,
  featuredFeed: PropTypes.shape({
    source: PropTypes.string,
    featured: PropTypes.arrayOf(PropTypes.shape({})),
    top: PropTypes.arrayOf(PropTypes.shape({}))
  }).isRequired,
  megatronNativeTile: PropTypes.shape({
    data: PropTypes.shape({
      posts: PropTypes.arrayOf(PropTypes.shape({}))
    })
  }).isRequired,
  allISFeedActions: PropTypes.func.isRequired,
  hideRecircFeed: PropTypes.func.isRequired,
  match: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired
};

function mapStateToProps(state = {}) {
  const {
    config = {},
    scrollingModule = {},
    promoBanner = {},
    featuredSponsoredCard = {},
    allFeed = {},
    homepageVideo = {},
    featuredFeed = {},
    megatronNativeTile = {},
    homepageTag = {},
    homepageFeatureTerm = {},
    homepage = {},
    magazineLatestIssuePosts = {}
  } = state;

  const {
    data: { posts: allFeedPosts = [], page, totalPages } = {},
    isLoading = false,
    allRendered = false
  } = allFeed;

  const {
    data: { playlist = [], title = '', feedid = '' } = {}
  } = homepageVideo;

  const {
    data: { source = '', top = [], featured = [], combined = [] } = {}
  } = featuredFeed;

  const {
    data: { posts: megatronNativeTilePosts = [] } = {}
  } = megatronNativeTile;

  const { data: homepagePosts = [] } = homepageTag;

  return {
    config,
    scrollingModule: scrollingModule.data,
    promoBanner: promoBanner.data,
    featuredSponsoredCard: featuredSponsoredCard.data,
    allFeed: {
      isLoading,
      allRendered,
      posts: allFeedPosts,
      page,
      totalPages
    },
    homepageVideo: {
      playlist,
      title,
      feedid
    },
    featuredFeed: {
      source,
      top,
      featured,
      combined
    },
    megatronNativeTile: {
      posts: megatronNativeTilePosts
    },
    homepageTag: {
      posts: homepagePosts
    },
    homepageFeatureTerm,
    homepage,
    magazineLatestIssuePosts
  };
}

const mapDispatchToProps = dispatch => ({
  allISFeedActions: allFeedISActions(dispatch),
  hideRecircFeed: hideRecircAction(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);
