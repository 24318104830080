/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable func-names */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import FormContainer from '../FormContainer/FormContainer';

const MarketoForm = ({
  server,
  munchkinId,
  assetId,
  name,
  lazy,
  onSubmitHandler
}) => {

  const containerRef = useRef(null);

  const initializeMarketo = () =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://register.fastcompany.com/js/forms2/js/forms2.min.js';
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });

  const initMunchkin = () =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = '//munchkin.marketo.net/munchkin.js';
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });

  const loadMarketoForm = () => {
    window.MktoForms2.loadForm(
      server,
      munchkinId,
      assetId,
      function (form) {
        // Add an onSuccess handler
        form.onSuccess(function () {
          if (onSubmitHandler) {
            onSubmitHandler(true);
            // Return false to prevent the submission handler
            // from taking the lead to the follow up url
            return false;
          }
        });
      }
    );
  }

  const renderMarketo = () => {
    initializeMarketo()
      .then(loadMarketoForm)
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log('Error initializing Marketo: ', err);
      });

    initMunchkin().then(() => {
      window.Munchkin.init(assetId);
    });
  };

  if (!lazy) {
    useEffect(() => {
      renderMarketo();
    }, []);
  } else {
    useEffect(() => {
      const config = {
        root: null,
        rootMargin: '500px 0px',
        threshold: 0.0
      };

      const observer = new IntersectionObserver((entries, self) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            renderMarketo();
            self.unobserve(entry.target);
          }
        });
      }, config);

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      }
    }, [containerRef]);
  }

  return (
    <div className="newsletters__page__marketo__form__container" ref={containerRef}>
      {/* Form placeholder replaced by Marketo script */}
      <FormContainer id={`mktoForm_${assetId}`} name={name} />
    </div>
  );
};

MarketoForm.propTypes = {
  server: PropTypes.string,
  munchkinId: PropTypes.string,
  assetId: PropTypes.number,
  name: PropTypes.string,
  lazy: PropTypes.bool,
  onSubmitHandler: PropTypes.func
};

MarketoForm.defaultProps = {
  server: '//register.fastcompany.com',
  munchkinId: '610-LEE-872',
  assetId: 1019,
  name: 'generic-marketo-form',
  lazy: false,
  onSubmitHandler: () => {}
};

export default MarketoForm;
