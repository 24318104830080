import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

const categoryJwIdMap = {
  technology: 'PSsJtDkM',
  leadership: 'QIKO4Bg0',
  'work-life': 'R6of2m4z',
  magazine: 'Y9DjLnt6',
  MCP: '7qluGmg9',
  MIC: 'dGGdpkNJ',
  news: 'W56TTMkA',
  ads: 'AYdibOhm',
  movies: '9pyPGMMb',
  games: 'pcPUSNzp',
  tv: 'BFft6WE8',
  music: '31g3mGyX',
  graphics: 'E94CFqkP',
  IBD: 'ugHwoYDj',
  interactive: 'QhzkgUg4',
  product: 'SguMMpt0',
  spaces: 'G9BPHwFi',
  cities: 'GofVu1Ze',
  ideas: 'OATCOesq',
  impact: 'R6of2m4z',
  sustainability: 'osKp5aZy',
  science: 'WFvqDhWI',
  transportation: '7Knj3gow',
  entertainment: 'Rdek7nNz',
  creativity: 'Rdek7nNz',
  default: 'R6of2m4z'
};

export default class CategoryPageVideo extends Base {
  static fetch(params, store) {
    const state = store.getState();
    const cacheEnabled = get(state, 'config.cacheEnabled');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const cacheExpire = get(state, 'config.cacheExpire');
    const { category } = params;
    const id = get(categoryJwIdMap, category) || categoryJwIdMap.default;
    const host = 'content.jwplatform.com';
    const options = {};
    let abtest = get(state, 'config.abtest');
    if (abtest) {
      abtest = `?abtest=${abtest}`;
    }
    const cacheKeyUrl = `https://${host}/feeds/${id}.json`;
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}${abtest}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
