import get from 'lodash/get';
import axios from 'axios';
import log from '../../../services/logger_service';
import Cookies from 'js-cookie';

export default function loadParselyTracker(currentState, pageNumber) {
  if (window.PARSELY && window.PARSELY.beacon) {
    // Sometimes multiple entries of same post appear on dashboard with weird query strings, this is to filter our all query strings.
    let url = window.location.href.split('?');
    url = get(url, '[0]', window.location.href);

    const navHistory = get(currentState, 'config.navHistory') || [];
    const isAdInjection =
      get(currentState, 'cardType', '') === 'Sponsored Story';

    let itmCampaignPrefix = 'pos';
    if (isAdInjection) {
      itmCampaignPrefix = 'ad';
    }

    // Temporarly solution to figure out why parsely.js is fired before head.js, we need head to set document.title first before parsely fires in order for parsely to collect the right title.
    setTimeout(() => {
      if (Cookies.get('X-abtest') && Cookies.get('X-abtest').length > 0) {
        window.PARSELY.updateDefaults({
          data: { ab: Cookies.get('X-abtest') }
        });
      }
      window.PARSELY.beacon.trackPageView({
        url: `${url}${
          pageNumber > 1
            ? `?itm_campaign=${itmCampaignPrefix}${pageNumber}`
            : ''
        }`,
        urlref: navHistory[navHistory.length - 1],
        js: 1
      });
    }, 200);
  }
}

// Builds a profile of the user's recommendation list, doc https://www.parse.ly/help/api/recommendations/#get-related
export function trainParselyProfile(url) {
  if (window.PARSELY && window.PARSELY.config) {
    const profileURL = url || window.location.href;
    const uuid = get(window, 'PARSELY.config.uuid');

    const isPreview =
      profileURL.includes('/preview/') ||
      profileURL.includes('/pvw/') ||
      profileURL.includes('THIS-IS-A-PREVIEW-LINK');

    // Train user base on UUID and post
    if (!isPreview) {
      axios
        .get(
          `https://api.parsely.com/v2/profile?apikey=fastcompany.com&url=${profileURL}&uuid=${uuid}`
        )
        .catch(error => {
          log.error('Parsely profile trainer error', error);
        });
    }
  }
}
