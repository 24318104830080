import get from 'lodash/get';
import extend from 'lodash/extend';
import { canUseDOM } from 'exenv';
import authUtil from '../../utils/auth_util';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

export default class AllFeed extends Base {
  static fetch({ page, excludeFromHomepage }, store) {
    const state = store.getState();
    const pageParams = page ? `&page=${page}` : '';
    const excludeFromHomepageFlag = excludeFromHomepage
      ? `&exclude_from_homepage`
      : '';
    const cacheEnabled = get(state, 'config.cacheEnabled') || false;
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const cacheExpire = get(state, 'config.cacheExpire');
    const host = get(state, 'config.apiEndpoint') || 'fc-api.fastcompany.com';
    const protocol = get(state, 'config.protocol') || 'https://';
    const options = {};
    let abtest = get(state, 'config.abtest');
    if (abtest) {
      abtest = `?abtest=${abtest}`;
    }
    // api has auth in beta
    if (get(state, 'config.authEnabled')) {
      extend(options, authUtil(state));
    }
    const cacheKeyUrl = `${protocol}${host}/v4/frontpage/posts/fastcompany?old_fe=1&only_new=1&page_size=20${pageParams}${excludeFromHomepageFlag}`;
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}${abtest}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }

  static fetchAllFeedIS({ page, excludeFromHomepage }, config) {
    const state = config;
    const pageParams = page ? `&page=${page}` : '';
    const excludeFromHomepageFlag = excludeFromHomepage
      ? `&exclude_from_homepage`
      : '';
    const total = 20;
    const cacheEnabled = get(state, 'cacheEnabled') || false;
    const cacheNamespace = get(state, 'cacheNamespace');
    const cacheExpire = get(state, 'cacheExpire');
    const host = get(state, 'apiEndpoint') || 'fc-api.fastcompany.com';
    const protocol = get(state, 'protocol') || 'https://';
    const options = {};
    // api has auth in beta
    if (get(state, 'authEnabled')) {
      extend(options, authUtil(state));
    }
    const cacheKeyUrl = `${protocol}${host}/v4/frontpage/posts/fastcompany?old_fe=1&only_new=1&page_size=${total}${pageParams}${excludeFromHomepageFlag}`;
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
