import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Ibd2019Component = false;
let Component = false;
let LegacyComponent = false;
let DefaultComponent = false;
let AnniversaryComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Ibd2019Component = require('../../tag_page/tag_page').default;
  // eslint-disable-next-line global-require
  Component = require('./innovation_by_design_page_2018').default;
  // eslint-disable-next-line global-require
  LegacyComponent = require('./innovation_by_design_page').default;
  // eslint-disable-next-line global-require
  AnniversaryComponent = require('./innovation_by_design_anniversary_page')
    .default;
  // eslint-disable-next-line global-require
  DefaultComponent = require('./innovation_by_design_page_controller').default;
}

const IBDRouteMapping = {
  '/innovation-by-design/:year(2012|2013|2014|2015|2016|2017)': {
    preloadChunkPromise: import(/* webpackChunkName: "legacyIbdPage" */ './innovation_by_design_page'),
    pageComponent: LegacyComponent,
    chunk: 'legacyIbdPage',
    autoScrollOff: true
  },
  '/innovation-by-design/2018': {
    preloadChunkPromise: import(/* webpackChunkName: "ibdPage" */ './innovation_by_design_page_2018'),
    pageComponent: Component,
    chunk: 'ibdPage',
    autoScrollOff: true
  },
  '/innovation-by-design/2019': {
    preloadChunkPromise: import('../../tag_page/tag_page'),
    pageComponent: Ibd2019Component,
    chunk: 'ibdPage',
    autoScrollOff: true
  },
  '/innovation-by-design/:year(2020|2021|2022|2023)': {
    preloadChunkPromise: import('./innovation_by_design_page_controller'),
    pageComponent: DefaultComponent,
    chunk: 'defaultMcpPage',
    autoScrollOff: true
  }
};

const generateRouteConfig = path => store => {
  let PageComponent = IBDRouteMapping[path].pageComponent;
  const routeConfig = {
    path,
    component() {
      if (PageComponent) {
        return <PageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: PageComponent ? PageComponent.loadData(store) : false,
    preloadChunk() {
      return IBDRouteMapping[path].preloadChunkPromise.then(resp => {
        PageComponent = resp.default;
        routeConfig.loadData = PageComponent.loadData(store);

        return PageComponent;
      });
    },
    chunk: IBDRouteMapping[path].chunk,
    autoScrollOff: IBDRouteMapping[path].autoScrollOff
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
};

export const ibd2019Page = generateRouteConfig('/innovation-by-design/2019');

export const ibd2018Page = generateRouteConfig('/innovation-by-design/2018');

// export const legacyIbdPage = generateRouteConfig('/innovation-by-design/:year(2012|2013|2014|2015|2016|2017');
// TODO: check this path
export function legacyIbdPage(store) {
  const routeConfig = {
    path: '/innovation-by-design/:year(2012|2013|2014|2015|2016|2017)',
    component() {
      if (LegacyComponent) {
        return <LegacyComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: LegacyComponent ? LegacyComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "legacyIbdPage" */ './innovation_by_design_page').then(
        resp => {
          LegacyComponent = resp.default;
          routeConfig.loadData = LegacyComponent.loadData(store);
          return LegacyComponent;
        }
      );
    },
    chunk: 'legacyIbdPage',
    autoScrollOff: true
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export const defaultIbdPage = generateRouteConfig(
  '/innovation-by-design/:year(2020|2021|2022|2023)'
);

// ======================================

export function anniversaryIbdPage(store) {
  const routeConfig = {
    path: '/innovation-by-design/:year/:subpage',
    component() {
      if (AnniversaryComponent) {
        return <AnniversaryComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: AnniversaryComponent
      ? AnniversaryComponent.loadData(store)
      : false,
    preloadChunk() {
      return import('./innovation_by_design_anniversary_page').then(resp => {
        AnniversaryComponent = resp.default;
        routeConfig.loadData = AnniversaryComponent.loadData(store);
        return AnniversaryComponent;
      });
    },
    chunk: 'defaultMcpPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
