// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    GIFT_GUIDE_PAGE_LOADING() {
      return {
        isLoading: true,
        giftGuidePage: action.giftGuidePage
      };
    },
    GIFT_GUIDE_PAGE_LOADED() {
      return {
        isLoading: false,
        data: action.homepage
      };
    },
    GIFT_GUIDE_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.giftGuidePage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
