import get from 'lodash/get';
import striptags from 'striptags';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';
import {
  isAdvertorial,
  getBrandworksName
} from '../../views/components/advertorial/advertorial';
import podcastsData from '../../config/podcast_config';
import { toTitleCase } from '../string_util';

export default function(state = {}) {
  const POST_LIMIT = 20;
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const tag = get(state, 'tagFeaturedFeed.tag') || {};
  let tagName = get(tag, 'name');
  const slug = get(tag, 'slug');
  // let tagDescription = get(tag, 'description');
  let tagDescription = '';
  const tags = get(state, 'tagAllFeed.data.posts[0].tags');
  const rss = `/section/${slug}/rss`;
  let currentIssue = null;
  if (get(state, 'magazineIssuePosts')) {
    currentIssue = get(state, 'magazineIssuePosts.data.issue', {});
  }

  if (isAdvertorial(tags)) {
    tagName = getBrandworksName(tags);
  }

  tagName = toTitleCase(tagName);

  const defaultDescription = `Find the latest ${tagName} news from Fast company. See related business and technology articles, photos, slideshows and videos.`;

  let title = tagName ? `${tagName} Latest News, Photos & Videos` : '';

  if (get(currentIssue, 'description', '')) {
    title = get(currentIssue, 'description', '').replace(/ \| /g, ', ');
  }

  let image = '';
  let podcastUrl = '';

  const isWithinPostLimits =
    get(state, 'tagAllFeed.data.totalPosts') <= POST_LIMIT;

  if (slug === 'audio') {
    title = 'Podcasts';
    tagDescription =
      'Listen now and subscribe anywhere you listen to podcasts.';
    image =
      'https://images.fastcompany.net/image/upload/v1591024099/fcweb/FC_Podcast_SocialShare_pnxs4k.jpg';
    podcastUrl = 'https://www.fastcompany.com/podcasts';
  } else {
    podcastsData.forEach(podcast => {
      if (slug === get(podcast, 'wp_slug', '')) {
        title = get(podcast, 'title');
        tagDescription = get(podcast, 'blurb');
        image = get(podcast, 'image', '');
        podcastUrl = `https://www.fastcompany.com/podcasts/${get(
          podcast,
          'slug',
          ''
        )}`;
      } else if (slug === 'new-way-we-work') {
        title = get(podcastsData[0], 'title');
        tagDescription = get(podcastsData[0], 'blurb');
        image = get(podcastsData[0], 'image', '');
        podcastUrl = `https://www.fastcompany.com/podcasts/${slug}`;
      } else if (slug === 'creative-conversation') {
        title = get(podcastsData[1], 'title');
        tagDescription = get(podcastsData[1], 'blurb');
        image = get(podcastsData[1], 'image', '');
        podcastUrl = `https://www.fastcompany.com/podcasts/${slug}`;
      }
    });
  }

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    image,
    url: podcastUrl || `https://www.${site}.com/section/${slug}`,
    description: tagDescription
      ? striptags(tagDescription)
      : defaultDescription,
    articleSection: tagName,
    rss,
    config: state.config
  });

  if (isWithinPostLimits || isAdvertorial(tags)) {
    data.meta.push({
      name: 'robots',
      content: 'noindex'
    });
  }

  return data;
}
