// import { canUseDOM } from 'exenv';

export function postVideoLoading(resp = {}, store = {}) {
  return {
    type: 'POST_VIDEO_LOADING',
    postVideo: resp,
    store
  };
}

export function postVideoLoaded(resp = {}, store = {}) {
  return {
    type: 'POST_VIDEO_LOADED',
    postVideo: resp,
    store
  };
}

export function postVideoLoadError(error, store = {}) {
  return {
    type: 'POST_VIDEO_ERROR',
    postVideo: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
