import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const imgPresets =
    'https://images.fastcompany.net/image/upload/w_530,q_auto,f_auto,fl_lossy';

  return baseSeoUtil({
    site,
    siteConf,
    title: 'Most Creative People in Business Community',
    url: 'https://www.fastcompany.com/most-creative-people-community',
    description:
      'Meet the most inspiring leaders in the Fast Company community',
    image: `${imgPresets}/v1495030888/MCP-Logo-default-SHARE.jpg`,
    articleSection: 'Most Creative People',
    config: state.config
  });
}
