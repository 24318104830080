import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const url = get(state, 'config.navHistory[0]', '');
  const pathName = url
    .replace('https://www.fastcompany.com/', '')
    .split('/')[0];

  let title;
  let description;

  switch (pathName) {
    case 'subscribe':
      title = 'Subscribe to Fast Company Premium';
      description =
        'Subscribe to Fast Company for design news, technology, business intelligence, and more';
      break;
    case 'login':
      title = 'Login';
      break;
    case 'register':
      title = 'Register';
      break;
    case 'myaccount':
      title = 'My Account';
      break;
    case 'resetpassword':
      title = 'Reset Password';
      break;
    default:
      title = '';
      description = `Fast Company is the world's leading progressive business media brand, with a unique editorial focus on innovation in technology, leadership, and design.`;
      break;
  }

  return baseSeoUtil({
    site,
    siteConf,
    title: `${title}`,
    url: `https://www.fastcompany.com/${pathName}`,
    description,
    excerpt: description,
    articleSection: `${title}`,
    config: state.config
  });
}
