// import { canUseDOM } from 'exenv';

export function categoryFeaturedFeedLoading(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_FEATURED_FEED_LOADING',
    categoryFeaturedFeed: resp,
    store
  };
}

export function categoryFeaturedFeedLoaded(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_FEATURED_FEED_LOADED',
    categoryFeaturedFeed: {
      featured: resp.featured,
      top: resp.top,
      category: resp.category,
      source: resp.source
    },
    store
  };
}

export function categoryFeaturedFeedLoadError(error, store) {
  return {
    type: 'CATEGORY_FEATURED_FEED_ERROR',
    categoryFeaturedFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
