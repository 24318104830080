/**
 * Gets a search param value from ?cms=some-value
 *
 * @param {string} urlString - some url string
 * @returns {string} the cms search param value or an empty string
 */
function getCMSQueryValue(urlString) {
  try {
    const url = new URL(urlString);
    if (url.search) {
      const searchParams = new URLSearchParams(url.search);
      if (searchParams.has('cms')) {
        return searchParams.get('cms');
      }
    }
  } catch (error) {
    return '';
  }
  return '';
}

function getHash(urlString) {
  try {
    const { origin = '', pathname = '' } = new URL(urlString);
    const key = `${origin}${pathname}`;

    if (!key) {
      return '';
    }

    let hash = 0;
    let i;
    let chr;
    // eslint-disable-next-line no-plusplus
    for (i = 0; i < key.length; i++) {
      chr = key.charCodeAt(i);
      // eslint-disable-next-line no-bitwise
      hash = (hash << 5) - hash + chr;
      // eslint-disable-next-line
      hash &= hash;
    }
    hash = Math.abs(hash);
    hash = hash.toString(36);
    return hash;
  } catch (error) {
    return '';
  }
}

export function getCMSHash(config = {}) {
  const { navHistory = [] } = config;
  const urlString = navHistory[0] || '';
  if (!urlString) {
    return '';
  }

  // append ?cms=abc123 to override cms
  const cmsQueryValue = getCMSQueryValue(urlString);
  if (cmsQueryValue) {
    return cmsQueryValue;
  }

  const cmsHash = getHash(urlString);
  if (!cmsHash) {
    return '';
  }

  return cmsHash;
}

export default getCMSHash;
