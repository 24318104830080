import React from 'react';
import hubConfig from '../../../../config/hubs';

import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let MICPageComponent = false;
let WCIPageComponent = false;
let MCPPageComponent = false;
let DefaultHubPageComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  MCPPageComponent = require('./most_creative_people_hub_page').default;
  // eslint-disable-next-line global-require
  MICPageComponent = require('./most_innovative_companies_hub_page').default;
  // eslint-disable-next-line global-require
  WCIPageComponent = require('./world_changing_ideas_hub_page').default;
  // eslint-disable-next-line global-require
  DefaultHubPageComponent = require('./default_hub_page').default;
  // eslint-disable-next-line global-require
}

export function mcpHubPage(store) {
  const routeConfig = {
    path: '/:tag(most-creative-people)',
    component() {
      if (MCPPageComponent) {
        return <MCPPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: MCPPageComponent ? MCPPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./most_creative_people_hub_page').then(resp => {
        MCPPageComponent = resp.default;
        routeConfig.loadData = MCPPageComponent.loadData(store);

        return MCPPageComponent;
      });
    },
    chunk: 'mcpHubPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function micHubPage(store) {
  const routeConfig = {
    path: '/:tag(most-innovative-companies)',
    component() {
      if (MICPageComponent) {
        return <MICPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: MICPageComponent ? MICPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./most_innovative_companies_hub_page').then(resp => {
        MICPageComponent = resp.default;
        routeConfig.loadData = MICPageComponent.loadData(store);

        return MICPageComponent;
      });
    },
    chunk: 'micHubPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function wciHubPage(store) {
  const routeConfig = {
    path: '/:tag(world-changing-ideas)',
    component() {
      if (WCIPageComponent) {
        return <WCIPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: WCIPageComponent ? WCIPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./world_changing_ideas_hub_page').then(resp => {
        WCIPageComponent = resp.default;
        routeConfig.loadData = WCIPageComponent.loadData(store);

        return WCIPageComponent;
      });
    },
    chunk: 'wciHubPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function defaultHubPage(store) {
  const routeConfig = {
    path: `/:tag(${Object.keys(hubConfig).join('|')})`,
    component() {
      if (DefaultHubPageComponent) {
        return <DefaultHubPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: DefaultHubPageComponent
      ? DefaultHubPageComponent.loadData(store)
      : false,
    preloadChunk() {
      return import('./default_hub_page').then(resp => {
        DefaultHubPageComponent = resp.default;
        routeConfig.loadData = DefaultHubPageComponent.loadData(store);

        return DefaultHubPageComponent;
      });
    },
    chunk: 'micHubPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
