import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  // default variables
  const site = state?.config?.site;
  const siteConf = siteData || {};
  const franchisePage = action?.franchisePage || {};
  // eslint-disable-next-line
  const img = franchisePage?.['_embedded']?.['wp:featuredmedia']?.[0]?.source_url;
  const wpTitle = franchisePage?.title?.rendered;
  const socialTitle = wpTitle ? striptags(wpTitle).trim() : ''; // displays for twitter and social platforms
  const defaultSocialTitle = `${socialTitle} | Fast Company`;
  const franchiseExcerpt = franchisePage?.excerpt?.rendered;
  const defaultDescription = franchiseExcerpt
    ? striptags(franchiseExcerpt).trim()
    : '';

  // wordpress franchise
  const isFranchise = franchisePage?.type === 'franchise-list';
  const franchiseTitle = isFranchise ? wpTitle : null; // displays on google and browser tab
  const franchiseUrl = franchisePage?.acf?.publicUrl;

  // airtable franchise
  const airtableFranchise = franchisePage?.externalList?.metadata || {}; // this is for franchises that use airtable
  const airtableTitle = airtableFranchise?.['SEO title'];
  const airtableImage = airtableFranchise?.['seo image'];
  const airtableUrl = airtableFranchise?.pageurl;
  const airtableDescription = airtableFranchise?.['SEO description'];
  const airtableTwitterTitle = airtableFranchise?.twitterTitle;
  const airtableTwitterDescription = airtableFranchise?.twitterDescription;

  return baseSeoUtil({
    site,
    siteConf,
    title: airtableTitle || franchiseTitle,
    overrideTitle: !!airtableTitle || !!franchiseTitle,
    url: airtableUrl || franchiseUrl,
    description: airtableDescription || defaultDescription,
    twitterDescription:
      airtableTwitterDescription || airtableDescription || defaultDescription,
    image: airtableImage || img,
    config: state?.config,
    facebookTitleOverride:
      airtableTitle || franchiseTitle || defaultSocialTitle,
    twitterTitleOverride:
      airtableTwitterTitle || franchiseTitle || defaultSocialTitle
  });
}
