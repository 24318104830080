import React from 'react';
import PropTypes from 'prop-types';

export default function Script({ innerHtml, type, src }) {
  if (innerHtml) {
    return (
      <script type={type} dangerouslySetInnerHTML={{ __html: innerHtml }} />
    );
  }
  return <script type={type} src={src} />;
}

Script.defaultProps = {
  innerHtml: '',
  src: ''
};

Script.propTypes = {
  innerHtml: PropTypes.string,
  type: PropTypes.string.isRequired,
  src: PropTypes.string
};
