// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    POST_PROMOTER_ARTICLE_TEXT_LOADING() {
      return {
        isLoading: true,
        promoterArticleText: action.promoterArticleText
      };
    },
    POST_PROMOTER_ARTICLE_TEXT_LOADED() {
      return {
        isLoading: false,
        data: action.promoterArticleText
      };
    },
    POST_PROMOTER_ARTICLE_TEXT_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.promoterArticleText.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
