import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

// let PageComponent = false;

// if (process.env.RUNTIME_ENV !== 'browser') {
//   // eslint-disable-next-line global-require
//   PageComponent = require('./brands_that_matter_page').default;
// }

// export function brandsThatMatterPage(store) {
//   const routeConfig = {
//     path: '/brands-that-matter/:year',
//     component() {
//       if (PageComponent) {
//         return <PageComponent />;
//       }

//       return <LazyComponent />;
//     },
//     exact: true,
//     strict: true,
//     autoScrollOff: true,
//     loadData: PageComponent ? PageComponent.loadData(store) : false,
//     preloadChunk() {
//       return import('./brands_that_matter_page').then(resp => {
//         PageComponent = resp.default;
//         routeConfig.loadData = PageComponent.loadData(store);

//         return PageComponent;
//       });
//     },
//     chunk: 'brandsThatMatterPage'
//   };

//   const LazyComponent = Loadable({
//     loader() {
//       return routeConfig.preloadChunk();
//     },
//     loading() {
//       return (
//         <section className="post-page">
//           <article className="post__main">
//             <LoadingSpinner />
//           </article>
//         </section>
//       );
//     }
//   });

//   return routeConfig;
// }

let DefaultComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  DefaultComponent = require('./brands_that_matter_page_controller').default;
}

export default function brandsThatMatterListPage(store) {
  const routeConfig = {
    path: '/brands-that-matter/:year(2021|2022|list)',
    component() {
      if (DefaultComponent) {
        return <DefaultComponent />;
      }
      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: DefaultComponent ? DefaultComponent.loadData(store) : false,
    preloadChunk() {
      return import('./brands_that_matter_page_controller').then(resp => {
        DefaultComponent = resp.default;
        routeConfig.loadData = DefaultComponent.loadData(store);
        return DefaultComponent;
      });
    },
    chunk: 'brandsThatMatterPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
