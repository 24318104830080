// import { canUseDOM } from 'exenv';

export function categoryAllFeedLoading(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_ALL_FEED_LOADING',
    categoryAllFeed: resp,
    store
  };
}

export function categoryAllFeedLoaded(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_ALL_FEED_LOADED',
    categoryAllFeed: resp,
    store
  };
}

export function categoryAllFeedLoadError(error, store = {}) {
  return {
    type: 'CATEGORY_ALL_FEED_ERROR',
    categoryAllFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}

export function categoryAllFeedAllStoriesRendered() {
  return {
    type: 'CATEGORY_ALL_FEED_ALL_STORIES_RENDERED',
    allRendered: true
  };
}
