import React from 'react';
import PropTypes from 'prop-types';
import renderRoutes from 'react-router-config/renderRoutes';
import { ConnectedRouter } from 'react-router-redux';
import { Provider } from 'react-redux';
import getRoutesWithStore from '../../react_router/react_router';
import Head from '../components/head/head';
import { UserProvider } from '../components/context/userContext';
import { ThemeProvider } from 'styled-components';
import theme from '../fc-components/main/themes';

// eslint-disable-next-line
function Root({ store, history, getRoutes, requestUrl }) {
  return (
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ThemeProvider theme={theme}>
          <UserProvider>
            <Head />
            {renderRoutes(getRoutesWithStore(store))}
          </UserProvider>
        </ThemeProvider>
      </ConnectedRouter>
    </Provider>
  );
}

Root.defaultProps = {
  getRoutes: undefined,
  requestUrl: ''
};

Root.propTypes = {
  store: PropTypes.shape({}).isRequired,
  history: PropTypes.shape({}).isRequired,
  getRoutes: PropTypes.func,
  requestUrl: PropTypes.string
};

export default Root;
