// import { canUseDOM } from 'exenv';

export function postMoreStoriesLoading(resp = {}, store = {}) {
  return {
    type: 'POST_MORE_STORIES_LOADING',
    postMoreStories: resp,
    store
  };
}

export function postMoreStoriesLoaded(resp = {}, store = {}) {
  return {
    type: 'POST_MORE_STORIES_LOADED',
    postMoreStories: resp,
    store
  };
}

export function postMoreStoriesLoadError(error, store = {}) {
  return {
    type: 'POST_MORE_STORIES_ERROR',
    postMoreStories: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
