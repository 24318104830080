import get from 'lodash/get';
import assign from 'lodash/assign';
import striptags from 'striptags';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const wpPage = get(state, 'customPage.data.page[0]');
  const slug = get(wpPage, 'slug');
  const pageTitle = get(wpPage, 'title.rendered');
  const wpExcerpt = get(wpPage, 'excerpt.rendered');
  const description = wpExcerpt ? striptags(wpExcerpt).trim() : '';

  const slugLookup = {
    'world-changing-ideas': {
      title: 'World Changing Ideas',
      description:
        'Rewarding ideas, projects, and products from the last year that have the potential to alter the lives of people and the direction of the planet for the better.',
      articleSection: 'World Changing Ideas'
    },
    'most-innovative-companies': {
      title: 'Apply for Fast Company’s Most Innovative Companies',
      description:
        'Be featured by Fast Company as one of the Most Innovative Companies in the world. Apply today and get recognition for your transformative work.',
      articleSection: 'Most Innovative Companies',
      image:
        'https://assets.fastcompany.com/image/upload/v1533672979/MIC/MIC_social_share_updated.webp'
    },
    'innovation-by-design': {
      title: 'Innovation By Design Awards',
      description:
        'The 2020 Innovation by Design Awards honor the designers and businesses solving the problems of today and tomorrow. The entry period will end on May 15, 2020. Enter one of the most sought-after design awards in the industry today!',
      articleSection: 'Innovation By Design Award About',
      image:
        'https://assets.fastcompany.com/image/upload/v1551903384/IBD%202019/1a-2019-ibd-header.webp'
    },
    'best-workplaces-for-innovators': {
      image:
        'https://assets.fastcompany.com/image/upload/v1610129372/wp-cms/uploads/2021/01/p-1-fast-company-now-accepting-applications-for-best-workplaces-for-innovators-2021.webp'
    },
    'brands-that-matter': {
      title: "Apply Now: Fast Company's 2021 Brands That Matter",
      image:
        'https://images.fastcompany.net/image/upload/v1611956800/fcweb/BTM-Social-Image_g2rh80.jpg'
    },
    'brands-that-matter-2022': {
      title: 'Brands That Matter 2022',
      image:
        'https://images.fastcompany.net/image/upload/v1611956800/fcweb/BTM-Social-Image_g2rh80.jpg'
    },
    'next-big-things-in-tech/2021': {
      title: "Apply Now: Fast Company's 2021 Next Big Things In Tech",
      image:
        'https://images.fastcompany.net/image/upload/v1614892215/fcweb/fc-nbtit-socialasset-16-9_b9xi4s.png'
    }
  };

  const foundStaticConfig = slugLookup[slug] || {};

  let slugOverride = slug;
  if (slug === 'innovation-by-design' || slug === 'most-innovative-companies') {
    slugOverride = `apply/${slug}`;
  }

  const base = {
    site,
    siteConf,
    url: `https://www.${site}.com/${slugOverride}`,
    title: pageTitle,
    articleSection: pageTitle,
    description,
    config: state.config
  };

  const union = assign({}, base, foundStaticConfig);

  const data = baseSeoUtil(union);
  return data;
}
