import get from 'lodash/get';
import * as categoryTagFeaturedActions from '../../action_creators/category/category_featured_feed_action_creators';
import CategoryTagFeaturedModel from '../../../models/category/category_featured_feed';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchCategoryFeaturedFeed(params, store, isTag) {
  return function dispatchCategoryFeaturedFeed(dispatch) {
    dispatch(categoryTagFeaturedActions.categoryFeaturedFeedLoading());

    const state = store.getState();
    const { abtest } = state.config;

    return CategoryTagFeaturedModel.fetch(params, store, isTag)
      .then(function categoryFeaturedFeedData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(
          categoryTagFeaturedActions.categoryFeaturedFeedLoaded(data, store)
        );
      })
      .catch(function categoryFeaturedFeedError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(
            err,
            `Error in category featured feed actions, message: ${err.message}`
          );
        }
        dispatch(
          categoryTagFeaturedActions.categoryFeaturedFeedLoadError(err, store)
        );
      });
  };
}
