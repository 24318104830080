import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};

  return baseSeoUtil({
    site,
    siteConf,
    title: 'Fast Company Magazine Archives From 1995 to Today',
    url: 'https://www.fastcompany.com/magazine/archives',
    description:
      'Fast Company Magazine issues and archives published from 1995 to today. Available in print, on the web, and PDF format.',
    articleSection: 'Magazine Index',
    config: state.config
  });
}
