import * as promoterTopPromoActions from '../../action_creators/homepage/homepage_promoter_top_promo_bar_action_creators';
import promoterTopPromoModel from '../../../models/homepage/homepage_promoter_top_promo_bar';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function promoterTopPromoAction(params, store) {
  return function promoterTopPromoDispatch(dispatch) {
    dispatch(promoterTopPromoActions.topPromoBarLoading(store));

    const state = store.getState();
    const { abtest } = state.config;

    return promoterTopPromoModel
      .fetch(params, store)
      .then(function handleTopPromoData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(promoterTopPromoActions.topPromoBarLoaded(data, store));
      })
      .catch(function handleArticleTextError(err) {
        dispatch(promoterTopPromoActions.topPromoBarLoadError(err, store));
        log.error(err, `Error in top promo actions, message: ${err.message}`);
      });
  };
}
