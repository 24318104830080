import get from 'lodash/get';
import siteData from './site_data_util';
import striptags from 'striptags';
import baseSeoUtil from './base_seo_util';

export default function(state = {}, action = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const acf = get(action, 'recognitionHubPage.acf', {});

  const title = get(acf, 'franchise_title');
  const url = get(acf, 'publicUrl');
  const description = get(acf, 'seoDescription');
  const socialTitle = title ? striptags(title).trim() : '';
  const twitterHeadline = get(acf, 'alt_twitter_headline');

  return baseSeoUtil({
    site,
    siteConf,
    title,
    url,
    description,
    config: get(state, 'config'),
    facebookTitle: `${socialTitle} | Fast Company`,
    twitterTitle: `${socialTitle} | Fast Company`,
    twitterDescription: twitterHeadline
  });
}
