export function supertagsLoading(resp = [], store = {}) {
  return {
    type: 'SUPERTAGS_LOADING',
    supertags: resp,
    store
  };
}

export function supertagsLoaded(resp = [], store = {}) {
  return {
    type: 'SUPERTAGS_LOADED',
    supertags: resp,
    store
  };
}

export function supertagsLoadError(error, store = {}) {
  return {
    type: 'SUPERTAGS_ERROR',
    supertags: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
