import categoryPageStateManager from '../category_page_state_manager';
import loadable from '@loadable/component';

const CategoryPage = loadable(() => import('../category_page'));

export default function(store) {
  return {
    path: '/:category',
    component: CategoryPage,
    exact: true,
    strict: true,
    loadData: categoryPageStateManager(store)
  };
}
