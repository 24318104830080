import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

function generateUrl(url, page) {
  if (page) {
    return `${url}/${page}`;
  }

  return url;
}

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const playlistName = get(state, 'playlistAllFeed.data.title');
  const playlistSlug = get(state, 'playlistAllFeed.data.slug');
  const playlistId = get(state, 'playlistAllFeed.data.feedid');
  const page = get(state, 'playlistAllFeed.data.page');
  const url = `https://www.fastcompany.com/videos/playlist/${playlistSlug}/${playlistId}`;
  const title = `${playlistName} Video Playlist`;

  const description =
    'Fast Company inspires a new breed of innovative and creative thought leaders who are actively inventing the future of business.';

  const data = baseSeoUtil({
    site,
    siteConf,
    noPipe: true,
    title,
    url: generateUrl(url, page),
    description,
    image_width: 1280,
    image_height: 720,
    type: 'video',
    config: state.config
  });

  return data;
}
