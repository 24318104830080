import HomePage from './index_page';
import homepagePageStateManager from './homepage_state_manager';

export default function(store) {
  return {
    path: '/all/:page',
    component: HomePage,
    exact: true,
    strict: true,
    loadData: homepagePageStateManager(store)
  };
}
