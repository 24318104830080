import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let DefaultComponent = false;
let LegacyComponent = false;
let Mcp2018Component = false;
let Mcp2019Component = false;
let Mcp2021Component = false;
let Mcp2022Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  LegacyComponent = require('./most_creative_people_legacy').default;
  // eslint-disable-next-line global-require
  Mcp2018Component = require('./most_creative_people_2018').default;
  // eslint-disable-next-line global-require
  Mcp2019Component = require('./most_creative_people_2019').default;
  // eslint-disable-next-line global-require
  Mcp2021Component = require('./most_creative_people_2021').default;
  // eslint-disable-next-line global-require
  Mcp2022Component = require('./most_creative_people_2022').default;
  // eslint-disable-next-line global-require
  DefaultComponent = require('./most_creative_people_2023').default;
}

export function legacyMcpPage(store) {
  const routeConfig = {
    path:
      '/most-creative-people/:year(2009|2010|2011|2012|2013|2014|2015|2016|2017)',
    component() {
      if (LegacyComponent) {
        return <LegacyComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: LegacyComponent ? LegacyComponent.loadData(store) : false,
    preloadChunk() {
      return import('./most_creative_people_legacy').then(resp => {
        LegacyComponent = resp.default;
        routeConfig.loadData = LegacyComponent.loadData(store);
        return LegacyComponent;
      });
    },
    chunk: 'legacyMcpPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function mcp2018Page(store) {
  const routeConfig = {
    path: '/most-creative-people/:year(2018)',
    component() {
      if (Mcp2018Component) {
        return <Mcp2018Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mcp2018Component
      ? Mcp2018Component.loadData(store, '2018')
      : false,
    preloadChunk() {
      return import('./most_creative_people_2018').then(resp => {
        Mcp2018Component = resp.default;
        routeConfig.loadData = Mcp2018Component.loadData(store);
        return Mcp2018Component;
      });
    },
    chunk: 'mcp2018Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function mcp2019Page(store) {
  const routeConfig = {
    path: '/most-creative-people/:year(2019)',
    component() {
      if (Mcp2019Component) {
        return <Mcp2019Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mcp2019Component
      ? Mcp2019Component.loadData(store, '2019')
      : false,
    preloadChunk() {
      return import('./most_creative_people_2019').then(resp => {
        Mcp2019Component = resp.default;
        routeConfig.loadData = Mcp2019Component.loadData(store);
        return Mcp2019Component;
      });
    },
    chunk: 'mcp2019Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function mcp2021Page(store) {
  const routeConfig = {
    path: '/most-creative-people/:year(2021)',
    component() {
      if (Mcp2021Component) {
        return <Mcp2021Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: Mcp2021Component
      ? Mcp2021Component.loadData(store, '2021')
      : false,
    preloadChunk() {
      return import('./most_creative_people_2021').then(resp => {
        Mcp2021Component = resp.default;
        routeConfig.loadData = Mcp2021Component.loadData(store);
        return Mcp2021Component;
      });
    },
    chunk: 'mcp2021Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function mcp2022Page(store) {
  const routeConfig = {
    path: '/most-creative-people/:year(2022)',
    component() {
      if (Mcp2022Component) {
        return <Mcp2022Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: Mcp2022Component
      ? Mcp2022Component.loadData(store, '2022')
      : false,
    preloadChunk() {
      return import('./most_creative_people_2022').then(resp => {
        Mcp2022Component = resp.default;
        routeConfig.loadData = Mcp2022Component.loadData(store);
        return Mcp2022Component;
      });
    },
    chunk: 'mcp2022Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function defaultMcpPage(store) {
  const routeConfig = {
    path: '/most-creative-people/:year(list)',
    component() {
      if (DefaultComponent) {
        return <DefaultComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: DefaultComponent ? DefaultComponent.loadData(store) : false,
    preloadChunk() {
      return import('./most_creative_people_2023').then(resp => {
        DefaultComponent = resp.default;
        routeConfig.loadData = DefaultComponent.loadData(store);
        return DefaultComponent;
      });
    },
    chunk: 'defaultMcpPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
