import mediaQueries from '../utils/mediaQueries';

const breakpoints = {
  xs: 0,
  sm: 600,
  md: 920,
  lg: 1280,
  xl: 1600,
};

export default {
  breakpoints,
  media: mediaQueries(breakpoints),
  colors: {
    primary: '#ff3b00',
    primaryHover: '#d63201',
    secondary: '#285cff',
    secondaryHover: '#022CB1',
    grey: '#E5E5E5',
    lightGrey: '#f2f2f2',
    mediumGrey: '#b2b2b2',
    darkGrey: '#474747',
    midGrey: '#666',
    middleGrey: '#777',
    middleGreyHover: '#000',
    darkerGrey: '#333333',
    almostBlack: '#191919',
    black: '#000',
    white: '#fff',
    gold: '#d4af37',
    silver: '#aaa9ad',
    bronze: '#a97142',
    profileCardBlue: '#0073c0',
  },
  fonts: {
    headline: 'grot',
    body: 'le-monde-livre-std',
    mono: 'ibm-plex-mono',
    small: 'proxima-nova',
    gothic: 'tablet-gothic',
  },
};
