import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const year = get(state, 'micYear.year');
  const sector = get(state, 'micSector.sector');
  const imgPresets =
    'https://images.fastcompany.net/image/upload/w_530,q_auto,f_auto,fl_lossy';
  if (sector) {
    const sectorIndex = findIndex(
      get(state, 'micSector.data.rows', []),
      sec => sec.url === sector
    );
    const sectorName = get(
      state,
      `micSector.data.rows[${sectorIndex}].name`,
      sector
    );
    return baseSeoUtil({
      site,
      siteConf,
      title: `The World’s Most Innovative Companies ${year}: ${sectorName} Honorees`,
      url: `https://www.fastcompany.com/most-innovative-companies/${year}/sectors/${sector}`,
      description: `Our annual guide to the businesses that matter the most`,
      image: `${imgPresets}/v1550783002/fcweb/MIC_WEB_OpenerGraphic_16x9_hroirl.jpg`,
      articleSection: 'Most Innovative Companies'
    });
  }
  return baseSeoUtil({
    site,
    siteConf,
    title: `The World’s 50 Most Innovative Companies of ${year}`,
    url: `https://www.fastcompany.com/most-innovative-companies/${year}`,
    description: `The ${year} guide to the businesses that matter the most.`,
    image: `${imgPresets}/v1550783002/fcweb/MIC_WEB_OpenerGraphic_16x9_hroirl.jpg`,
    articleSection: 'Most Innovative Companies',
    config: state.config
  });
}
