export default function polyfillImages() {
  // create an image node so we can test its properties
  const img = document.createElement('img');
  // feature detect
  const supportSrcset = 'srcset' in img && 'sizes' in img;
  if (!supportSrcset) {
    // feature not available:
    // create a script tag
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src =
      'https://cdn.jsdelivr.net/picturefill/3.0.3/picturefill.min.js';
    script.setAttribute('async', '');
    // add it to the document <head>...
    const head = document.getElementsByTagName('head')[0];
    head.appendChild(script);
  }
}
