import get from 'lodash/get';
import * as companyActions from '../../action_creators/mic/mic2020_company_action_creators';
import CompanyModel from '../../../models/mic/mic2020_company_model';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import log from '../../../services/logger_service';

export default function fetchMic2020Company(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(companyActions.mic2020CompanyLoading());
    return CompanyModel.fetch(store)
      .then(function handleCompanyData(companyData) {
        dispatch(companyActions.mic2020CompanyLoaded(companyData.data, store));
      })
      .catch(function handleCompanyError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(status, 'ERROR_STATUS', store));
          log.error(
            err,
            `Error in all company actions, message: ${err.message}`
          );
        }
        dispatch(companyActions.mic2020CompanyLoadError(err, store));
      });
  };
}
