import * as allFeedActions from '../../action_creators/homepage/all_feed_action_creators';
import AllFeedModel from '../../../models/homepage/all_feed_module';
import log from '../../../services/logger_service';
// import { setAB } from '../../../utils/ab_test_util';

export default function fetchAllFeedIS(dispatch) {
  return function fetchModelData(params, config) {
    dispatch(allFeedActions.allFeedISLoading());
    return AllFeedModel.fetchAllFeedIS(params, config)
      .then(function handleAuthorAllFeedData(resp) {
        // const state = store.getState();
        // const abtest = state.config.abtest

        const { data } = resp;
        // Object.keys(data).forEach(key => {
        //   if (Array.isArray(data[key])) {
        //     setAB(data[key],abtest);
        //   }
        // });

        dispatch(allFeedActions.allFeedISLoaded(data, config));
      })
      .catch(function handleAuthorAllFeedError(err) {
        dispatch(allFeedActions.allFeedISError(err, config));
        log.error(
          err,
          `Error in all  all feed actions, message: ${err.message}`
        );
      });
  };
}
