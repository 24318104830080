import React from 'react';
import loadable from '@loadable/component';
import LoadingSpinner from '../../../../components/loading_spinner/loading_spinner';

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./subscriptions_success').default;
}

export default function(store) {
  const routeConfig = {
    path: '/GroupSubscriptions/success',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "subscribePage" */ './subscriptions_success').then(
        resp => {
          Component = resp.default;
          return Component;
        }
      );
    },
    chunk: 'subscriptionsSuccess'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="group-subscriptions">
        <div className="group-subscriptions__wrapper">
          <div className="group-subscriptions__container">
            <LoadingSpinner />
          </div>
        </div>
      </section>
    )
  });

  return routeConfig;
}
