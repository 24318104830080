import canUseDOM from 'exenv';
import { onCLS, onLCP, onFID } from 'web-vitals/attribution';

const LOG_CWV_QUERY_PARAM = 'logcwv';

const writeWebVitalsLog = data => {
  // eslint-disable-next-line no-console
  console.log('%c[CWV]', 'background:yellow;color:black', data);
};

const shouldLogWebVitals = () => {
  if (!canUseDOM || !canUseDOM.canUseDOM) {
    return false;
  }

  const query = new URLSearchParams(window.location.search);
  const hasParam = query.has(LOG_CWV_QUERY_PARAM);
  const isDev = process.env.NODE_ENV === 'development';

  return hasParam || isDev;
};

const logWebVitals = () => {
  const options = {
    reportAllChanges: true
  };
  onCLS(writeWebVitalsLog, options);
  onLCP(writeWebVitalsLog, options);
  onFID(writeWebVitalsLog, options);
};

export { shouldLogWebVitals, logWebVitals };
