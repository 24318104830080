import get from 'lodash/get';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import each from 'lodash/each';
import find from 'lodash/find';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';
import {
  isAdvertorial,
  isCustom,
  getBrandworksName
} from '../../views/components/advertorial/advertorial';
import {
  buildAssetUrlWithPresets,
  changeAssetDomain,
  imageSizesLookup
} from '../cloudinary_asset_util';
import getByLineAttribution from '../by_line_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const post =
    get(state, 'post.data.post') || get(state, 'previewPost.data.post') || {};
  const categories = get(post, 'categories', []);
  const title = get(post, 'title', '');
  let customSEOTitle = get(post, 'title', '');
  const id = get(post, 'id');
  const slug = get(post, 'slug');
  const author =
    get(post, 'customFields.guestAuthors[0].authorName', undefined) ||
    getByLineAttribution(get(post, 'tags', [])) ||
    get(post, 'author.name');

  // SEO field override with custom fields
  const customFields = get(post, 'customFields');
  const canonical = get(customFields, 'canonical_url');
  const customFieldTitle =
    get(post, 'seoMetaFields.headline', '') || get(customFields, 'title');
  if (customFieldTitle && customFieldTitle !== '') {
    customSEOTitle = customFieldTitle;
  }

  let image = get(post, 'shareImage', '');
  if (get(post, 'featured_image.source')) {
    image = changeAssetDomain(image);
    image = image.replace('.gif', '.jpg');
  }
  const DESKTOP_SIZE = true;
  image = buildAssetUrlWithPresets(image, imageSizesLookup.meta, DESKTOP_SIZE);

  const articleDate = get(post, 'datePublished');
  const dateModified = get(post, 'dateModified');
  const allTags = get(post, 'tags');
  const category = get(categories, '[0].name');
  const getTag = function cleanTag() {
    const name = get(allTags, '[0].name');
    if (name === 'homepage' || name === 'top' || name === 'featured') {
      return false;
    }
    return name;
  };

  // const isVideoPost = get(post, 'posterVideo.feedId');
  const oTags = [category, getTag()];
  const dedupedTags = uniq(compact(oTags));
  const standout =
    find(allTags, { name: 'standout' }) ||
    find(allTags, { name: 'googlepick' }) ||
    false;
  const tags = dedupedTags.filter(item => !/^byline_/.test(item)).join(', ');
  const description =
    get(post, 'seoMetaFields.desc', '') ||
    get(post, 'customFields.seo_description', '') ||
    get(post, 'excerpt', '') ||
    '';
  const excerpt = get(post, 'excerpt', '');
  const url = `https://www.${site}.com/${id}/${slug}`;

  const titleTruncated =
    get(customSEOTitle, 'length') >= 70
      ? customSEOTitle.substring(0, 70)
      : customSEOTitle;

  const isAdvertorialPost = isAdvertorial(allTags);
  const isCustomPost = isCustom(allTags);

  const brandWorksName = getBrandworksName(allTags);

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    titleTruncated,
    canonical,
    url,
    description,
    excerpt,
    image,
    image_width: 1280,
    image_height: 720,
    articleSection: category,
    config: state.config,
    extend: [
      {
        meta: [
          {
            property: 'article:published_time',
            content: articleDate
          },
          {
            property: 'article:modified_time',
            content: dateModified
          },
          {
            property: 'author',
            content: author
          },
          {
            name: 'news_keywords',
            content: tags
          }
        ]
      }
    ],
    noPipe: true
  });

  each(dedupedTags, function addOGMeta(tag) {
    data.meta.push({
      property: 'article:tag',
      content: tag
    });
  });

  if (standout) {
    data.meta.push({
      property: 'standout',
      content: url
    });
  }

  if (isAdvertorialPost) {
    data.meta.push({ name: 'ROBOTS', content: 'FOLLOW' });
  }
  if (isCustomPost) {
    data.meta.push({ property: 'og:contentgroup', content: 'brandview' });
    data.meta.push({ property: 'og:brand', content: brandWorksName });
  }

  return data;
}
