export const innovationByDesignPageLoading = (resp = {}, store = {}) => ({
  type: 'INNOVATION_BY_DESIGN_PAGE_LOADING',
  innovationByDesign: resp,
  store
});

export function innovationByDesignPageLoaded(resp = {}, store = {}) {
  return {
    type: 'INNOVATION_BY_DESIGN_PAGE_LOADED',
    innovationByDesign: resp,
    store
  };
}

export function innovationByDesignPageLoadError(error, store = {}) {
  return {
    type: 'INNOVATION_BY_DESIGN_PAGE_ERROR',
    innovationByDesign: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
