import extend from 'lodash/extend';

export function allFeedLoading(resp = {}, store = {}) {
  return {
    type: 'ALL_FEED_LOADING',
    allFeed: resp,
    store
  };
}

export function allFeedLoaded(resp = {}, store = {}) {
  return {
    type: 'ALL_FEED_LOADED',
    allFeed: resp,
    store
  };
}

export function allFeedLoadError(error, store = {}) {
  return {
    type: 'ALL_FEED_ERROR',
    allFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}

export function allFeedAllStoriesRendered() {
  return {
    type: 'ALL_FEED_ALL_STORIES_RENDERED',
    allRendered: true
  };
}

export function allFeedISLoading(resp = {}, config) {
  return {
    type: 'ALL_FEED_IS_LOADING',
    allFeed: extend({ isLoading: true }, resp),
    config
  };
}

export function allFeedISLoaded(resp = {}, config) {
  return {
    type: 'ALL_FEED_IS_LOADED',
    allFeed: resp,
    config
  };
}

export function allFeedISError(resp = {}, config) {
  return {
    type: 'ALL_FEED_IS_ERROR',
    allFeed: resp,
    config
  };
}
