import get from 'lodash/get';
import * as magazineLatestIssuePostsActions from '../../action_creators/magazine/magazine_latest_issue_posts_action_creators';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import MagazineLatestIssuePosts from '../../../models/magazine/magazine_latest_issue_posts_model';
import log from '../../../services/logger_service';

export default function fetchMagazineLatestIssuePosts(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(
      magazineLatestIssuePostsActions.magazineLatestIssuePostsLoading({}, store)
    );
    return MagazineLatestIssuePosts.fetch(params, store)
      .then(function handleMagazineLatestIssuePostsData(
        magazineLatestIssuePostsData
      ) {
        dispatch(
          magazineLatestIssuePostsActions.magazineLatestIssuePostsLoaded(
            magazineLatestIssuePostsData.data,
            store
          )
        );
      })
      .catch(function handleMagazineLatestIssuePostsError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(
            err,
            `Error in fetchMagazineLatestIssuePosts actions, message: ${
              err.message
            }`
          );
        }
        return dispatch(
          magazineLatestIssuePostsActions.magazineLatestIssuePostsError(
            err.data,
            store
          )
        );
      });
  };
}
