import postPageStateManager from '../postpage_state_manager';
import loadable from '@loadable/component';

const PostPage = loadable(() => import('../post_page'));

export default function(store) {
  return {
    path: '/pvw/:id/:slug',
    component: PostPage,
    exact: true,
    strict: true,
    loadData: postPageStateManager(store)
  };
}
