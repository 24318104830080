import * as featuredFeedActions from '../../action_creators/homepage/featured_feed_action_creators';
import FeaturedFeedModel from '../../../models/homepage/featured_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchFeaturedModule(store) {
  return function firstDispatchFeatured(dispatch) {
    dispatch(featuredFeedActions.featuredFeedLoading());

    const state = store.getState();
    const { abtest } = state.config;

    return FeaturedFeedModel.fetch(store)
      .then(function handleFeaturedModuleData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });
        dispatch(featuredFeedActions.featuredFeedLoaded(data, store));
      })
      .catch(function handleFeaturedError(err) {
        dispatch(featuredFeedActions.featuredFeedLoadError(err, store));
        log.error(
          err,
          `Error in featured feed actions, message: ${err.message}`
        );
      });
  };
}
