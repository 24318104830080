import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

// let Component = false;

// if (process.env.RUNTIME_ENV !== 'browser') {
//   // eslint-disable-next-line global-require
//   Component = require('../../tag_page/tag_page').default;
// }

// export function brandworksRoutePaged(store) {
//   const routeConfig = {
//     path: '/test/more/:tag/:page?',
//     component() {
//       if (Component) {
//         return <Component />;
//       }

//       return <LazyComponent />;
//     },
//     exact: true,
//     strict: true,
//     loadData: Component ? Component.loadData(store) : false,
//     preloadChunk() {
//       return import(/* webpackChunkName: "tagPage" */ '../tag_page').then(
//         resp => {
//           Component = resp.default;
//           routeConfig.loadData = Component.loadData(store);
//           return Component;
//         }
//       );
//     },
//     chunk: 'tagPage'
//   };

//   const LazyComponent = Loadable({
//     loader() {
//       return routeConfig.preloadChunk();
//     },
//     loading() {
//       return (
//         <section className="tag-page">
//           <article className="tag-page__main">
//             <LoadingSpinner />
//           </article>
//         </section>
//       );
//     }
//   });

//   return routeConfig;
// }

let CollectionComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  CollectionComponent = require('../../custom_collection/custom_collection_page')
    .default;
}

export default function(store) {
  const routeConfig = {
    path: `/fcw/:tag/:page?`,
    component() {
      if (CollectionComponent) {
        return <CollectionComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: CollectionComponent ? CollectionComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "customCollectionPage" */ '../../custom_collection/custom_collection_page').then(
        resp => {
          CollectionComponent = resp.default;
          routeConfig.loadData = CollectionComponent.loadData(store);
          return CollectionComponent;
        }
      );
    },
    chunk: 'tagPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="tag-page">
        <article className="tag-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
