import get from 'lodash/get';
import identityConfig from '../../config/identity';

export default function(data = {}) {
  const {
    siteConf,
    type,
    rss,
    publisher = true,
    extend = [],
    canonical,
    overrideTitle = false,
    facebookTitleOverride = false,
    twitterTitleOverride = false,
    socialDescriptionOverride = false,
    twitterDescription,
    twitterTitle
  } = data;

  const { useSloganTitle, titleTruncated, noPipe } = data;
  const { title } = data;
  const siteHumanReadable = get(siteConf, '[og:site_name]', '');
  const analytics = identityConfig;
  const description = data.description || analytics.description || '';
  const excerpt = data.excerpt || analytics.description || '';
  const url = data.url || analytics.url || '';
  const siteSlug = analytics.slug || '';
  const fbConfig = analytics.facebook || {};
  const image = data.image || analytics.logo;
  const imageWidth = data.image_width;
  const imageHeight = data.image_height;
  let canonicalURL = url;
  if (canonical) {
    canonicalURL = canonical;
  }
  // title = original title from each seo util, titleSEO modified title base on page type, to be displayed in metatags
  // If titleTruncated exists, only apply it to <title> but keep social title untruncated

  // Default title | sitename
  let titleSEO = overrideTitle
    ? title
    : `${title} | ${get(identityConfig, 'siteName', '')}`;

  // If this is set, usually on homepages, display slogan instead
  if (useSloganTitle === true) {
    titleSEO = `${title} | ${get(identityConfig, 'slogan', '')}`;
  }

  // Pass this option to render no pipe on specific page type utils
  if (noPipe === true) {
    titleSEO = title;
  }

  // const identitySite = identityConfig || 'fastcompany';

  const config = {
    title: titleTruncated || titleSEO,
    meta: [
      {
        httpEquiv: 'X-UA-Compatible',
        content: 'IE=edge,chrome=1'
      },
      // {
      //   property: 'fb:pages',
      //   content: `${get(identitySite, 'facebook.fanpageId', '5942134076')}`
      // },
      {
        name: 'viewport',
        content: 'width=device-width, initial-scale=1.0, maximum-scale=5.0'
      },
      {
        name: 'description',
        content: description
      },
      {
        property: 'og:type',
        content: type || 'article'
      },
      {
        property: 'og:title',
        content: facebookTitleOverride || titleSEO
      },
      {
        property: 'og:url',
        content: url
      },
      {
        property: 'og:locale',
        content: 'en_US'
      },
      {
        property: 'og:description',
        content: socialDescriptionOverride || description
      },
      {
        property: 'og:image',
        content: image
      },
      {
        property: 'og:site_name',
        content: siteHumanReadable
      },
      {
        property: 'fb:app_id',
        content: `${get(fbConfig, 'appId')}`
      },
      {
        property: 'fb:admins',
        content: `${get(fbConfig, 'appAdminId')}`
      },
      {
        name: 'twitter:card',
        content: data.twitterCard || 'summary_large_image'
      },
      {
        name: 'twitter:title',
        content: twitterTitle || twitterTitleOverride || titleSEO
      },
      {
        name: 'twitter:description',
        content: twitterDescription || socialDescriptionOverride || excerpt
      },
      {
        name: 'twitter:url',
        content: url
      },
      {
        name: 'twitter:site',
        content: `@${siteSlug}`
      },
      {
        name: 'twitter:image',
        content: data.twitterImage || image
      },
      {
        name: 'twitter:domain',
        content: `${siteSlug}.com`
      },
      {
        itemProp: 'name',
        content: titleSEO
      },
      {
        itemProp: 'description',
        content: description
      },
      {
        name: 'theme-color',
        content: '#ffffff'
      },
      {
        name: 'robots',
        content: canonical ? 'noindex' : ''
      },
      {
        name: 'robots',
        content: 'max-image-preview:large'
      }
    ],
    link: [
      {
        rel: 'canonical',
        href: canonical ? canonicalURL : url
      },
      {
        rel: 'apple-touch-icon',
        sizes: '180x180',
        href: '/apple-touch-icon.png?v=5'
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-32x32.png?v=5',
        sizes: '32x32'
      },
      {
        rel: 'icon',
        type: 'image/png',
        href: '/favicon-16x16.png?v=5',
        sizes: '16x16'
      },
      {
        rel: 'manifest',
        href: '/manifest.json'
      },
      {
        rel: 'mask-icon',
        href: '/safari-pinned-tab.svg?v=5',
        color: '#000000'
      },
      {
        rel: 'shortcut icon',
        href: '/favicon.ico?v=5'
      },
      {
        rel: 'alternate',
        href: url,
        hrefLang: 'en'
      },
      {
        rel: 'prefetch',
        href:
          'https://tpc.googlesyndication.com/safeframe/1-0-5/html/container.html'
      }
    ],
    noscript: [],
    style: []
  };

  if (publisher) {
    config.meta.push({
      property: 'article:publisher',
      content: siteConf['article:publisher'] || ''
    });
  }

  if (canonicalURL !== url) {
    config.meta.push({
      name: 'ROBOTS',
      content: 'NOINDEX,FOLLOW'
    });
  }

  // we are testing removing this for a couple of weeks
  // to see if this data is being prepended to title for
  // google search results
  //
  // if (data.articleSection) {
  //   config.meta.push({
  //     property: 'article:section',
  //     content: data.articleSection
  //   });
  // }

  if (rss) {
    config.link.push({
      rel: 'alternate',
      type: 'application/rss+xml',
      title: 'RSS',
      href: rss
    });
  }

  if (image && imageWidth && imageHeight) {
    config.meta.push({
      property: 'og:image:width',
      content: imageWidth
    });

    config.meta.push({
      property: 'og:image:height',
      content: imageHeight
    });
  }

  // Extend is used to add objects to the arrays in the config object.
  // For example if you would like to add another meta tag you can:
  // const data = baseSeoUtil({
  //   ...
  //   extend: [{
  //     meta: [{
  //       property: 'META_PROPERTY',
  //       content: 'META_CONTENT'
  //     }]
  //   }]
  // });

  extend.forEach(extras => {
    const key = Object.keys(extras).join('');
    const arr = extras[key];

    config[key] = config[key].concat(arr);
  });

  return config;
}
