import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('../magazine_tag_page').default;
}

let TagComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  TagComponent = require('../tag_page').default;
}

function magIssue1(store) {
  const routeConfig = {
    path: '/magazine/:tag(\\issue-\\d+)',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store, 'magazine') : false,
    preloadChunk() {
      return import(/* webpackChunkName: "magazineTagPage" */ '../magazine_tag_page').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store, 'magazine');
          return Component;
        }
      );
    },
    chunk: 'magazineTagPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="search">
        <LoadingSpinner />
      </section>
    )
  });

  return routeConfig;
}

function magIssue2(store) {
  const routeConfig = {
    path: '/magazine/:tag(\\issue-\\d+-\\d+)',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store, 'magazine') : false,
    preloadChunk() {
      return import(/* webpackChunkName: "magazineTagPage" */ '../magazine_tag_page').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store, 'magazine');
          return Component;
        }
      );
    },
    chunk: 'magazineTagPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="search">
        <LoadingSpinner />
      </section>
    )
  });

  return routeConfig;
}

function magTag(store) {
  const routeConfig = {
    path: '/magazine/:tag',
    component() {
      if (TagComponent) {
        return <TagComponent />;
      }

      return <LazyTagComponent />;
    },
    exact: true,
    strict: true,
    loadData: TagComponent ? TagComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "tagPage" */ '../tag_page').then(
        resp => {
          TagComponent = resp.default;
          routeConfig.loadData = TagComponent.loadData(store);
          return TagComponent;
        }
      );
    },
    chunk: 'tagPage'
  };

  const LazyTagComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="search">
        <LoadingSpinner />
      </section>
    )
  });

  return routeConfig;
}

export default function(store) {
  return [magIssue1(store), magIssue2(store), magTag(store)];
}
