import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const title = `Video | Fast Company | Business + Innovation`;

  const description =
    'Fast Company inspires a new breed of innovative and creative thought leaders who are actively inventing the future of business.';

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    url: 'https://www.fastcompany.com/videos',
    description,
    image_width: 1280,
    image_height: 720,
    type: 'video',
    config: state.config
  });

  return data;
}
