import React, { useRef } from 'react';
// import { get } from 'lodash';
// import EventModel from '../../../tracking/events/eventModel';

// Form is submitted
// eslint-disable-next-line react/prop-types
const FormContainer = ({ children, onSubmit, ...props }) => {
  const formRef = useRef(null);
  // const handleFormSubmit = (e) => {
  //   e.preventDefault();

  //   // Handle optional custom onSubmit logic
  //   if (onSubmit) {
  //     onSubmit(e);
  //   }

  //   // Track form submission event
  //   const formSubmissionEvent = new EventModel();
  //   formSubmissionEvent.addTrackingAttributes({
  //     form_id: get(formRef, 'current.id', ''),
  //     form_name: get(formRef, 'current.name', ''),
  //   });
  //   formSubmissionEvent.addToDataLayer('form_completed');
  // };

  // Form receives focus
  // const handleFormFocus = () => {
  //   // Track form interaction event
  //   const formSubmissionEvent = new EventModel();
  //   formSubmissionEvent.addTrackingAttributes({
  //     form_id: get(formRef, 'current.id', ''),
  //     form_name: get(formRef, 'current.name', ''),
  //   });
  //   formSubmissionEvent.addToDataLayer('form_start');
  // };

  return (
    <form
      {...props}
      ref={formRef}
      // onSubmit={handleFormSubmit}
      // onFocus={handleFormFocus}
    >
      {children}
    </form>
  );
};

export default FormContainer;
