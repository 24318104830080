import get from 'lodash/get';
import * as allFeedActions from '../../action_creators/homepage/all_feed_action_creators';
import AllFeedModel from '../../../models/homepage/all_feed_module';
import log from '../../../services/logger_service';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import { setAB } from '../../../utils/ab_test_util';

export default function allFeedAction(params, store) {
  return function allFeedDispatch(dispatch) {
    dispatch(allFeedActions.allFeedLoading(store));

    const state = store.getState();
    const { abtest } = state.config;

    return AllFeedModel.fetch(params, store)
      .then(function handleAllFeedData(resp) {
        if (params.page && !get(resp, 'data.posts.length')) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        }

        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(allFeedActions.allFeedLoaded(data, store));
      })
      .catch(function handleAllFeedError(err) {
        dispatch(allFeedActions.allFeedLoadError(err, store));
        log.error(err, `Error in all feed actions, message: ${err.message}`);
      });
  };
}
