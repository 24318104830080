import { canUseDOM } from 'exenv';

let serverRequest = false;
let clientRequest = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  serverRequest = require('../server/utils/request_util');
} else {
  // eslint-disable-next-line global-require
  clientRequest = require('../client/utils/request_util');
}

const request = canUseDOM ? clientRequest : serverRequest;

export default request.default;
