import get from 'lodash/get';
import extend from 'lodash/extend';

export default function(state = {}, action) {
  const typeMap = {
    ALL_FEED_LOADING() {
      return {
        isLoading: true,
        allFeed: action.allFeed,
        allRendered: false
      };
    },
    ALL_FEED_LOADED() {
      return {
        isLoading: false,
        data: action.allFeed,
        allRendered: false
      };
    },
    ALL_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        allRendered: false,
        errorMessage: action.allFeed.errorMessage
      };
    },
    ALL_FEED_ALL_STORIES_RENDERED() {
      return {
        isLoading: false,
        data: { posts: state.data.posts },
        allRendered: true
      };
    },
    ALL_FEED_IS_LOADING() {
      return extend({}, state, { isEnabled: true, isLoading: true });
    },
    ALL_FEED_IS_LOADED() {
      return {
        isEnabled: true,
        isLoading: false,
        data: extend({}, state.data, {
          page: get(action, 'allFeed.page'),
          posts: [...state.data.posts, ...get(action, 'allFeed.posts', [])]
        }),
        allRendered: false
      };
    },
    ALL_FEED_IS_ERROR() {
      return {
        isEnabled: true,
        isLoading: true,
        data: extend({}, state, {
          page: get(action, 'allFeed.page'),
          posts: [...state.data.posts, ...get(action, 'allFeed.posts', [])]
        }),
        allRendered: false
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
