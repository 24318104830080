import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  const mcpHubPageAcf = action?.mcpHubPage?.acf || {};

  const site = state?.config?.site;
  const siteConf = siteData || {};
  const wpTitle = 'The Most Creative People in Business';
  // const img = no image provided, commenting this out for now
  const wpExcerpt = mcpHubPageAcf?.seoDescription || '';

  const socialTitle = wpTitle ? striptags(wpTitle).trim() : ''; // displays for twitter and social platforms
  const seoTitle = wpTitle; // displays on google and browser tab
  const description = wpExcerpt ? striptags(wpExcerpt).trim() : '';
  const url = mcpHubPageAcf?.publicUrl || 'https://www.fastcomany.com/';

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle,
    url,
    description,
    config: state?.config,
    facebookTitleOverride: `${socialTitle} | Fast Company`,
    twitterTitleOverride: `${socialTitle} | Fast Company`
  });
}
