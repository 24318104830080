import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import extend from 'lodash/extend';
import authUtil from '../../utils/auth_util';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

export default class Post extends Base {
  static fetch({ id, expirationToken }, store) {
    const { CLIENT_PREVIEW, PREVIEW_TOKEN } = process.env;
    const state = store.getState();
    const isPreview = get(state, 'config.isPreview');
    const cacheEnabled = isPreview ? false : get(state, 'config.cacheEnabled');
    const cacheExpire = get(state, 'config.cacheExpire');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const site = get(state, 'config.site');
    const host = get(state, 'config.apiEndpoint') || 'fc-api.fastcompany.com';
    const protocol = get(state, 'config.protocol') || 'https://';
    const options = {};
    const postRelatedSlug = isPreview ? 'preview-post-related' : 'post-related';
    // api has auth in beta
    if (get(state, 'config.authEnabled')) {
      extend(options, authUtil(state));
    }

    if (CLIENT_PREVIEW === 'true') {
      extend(options, {
        headers: {
          'x-preview-token': PREVIEW_TOKEN,
          'x-expiration-token': expirationToken
        }
      });
    }

    let cacheKeyUrl;

    if (expirationToken && isPreview) {
      cacheKeyUrl = `${protocol}${host}/api/v2/client-preview-post-related/${site}/${id}`;
    } else if (isPreview) {
      cacheKeyUrl = `${protocol}${host}/api/v2/${postRelatedSlug}/${site}/${id}`;
    } else {
      cacheKeyUrl = `${protocol}${host}/api/v3/${postRelatedSlug}/${site}/${id}`;
    }

    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
