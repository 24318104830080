import * as afterbodyRecircModuleTypeActions from '../../action_creators/post/post_afterbody_recirc_module_type_action_creators';
import afterbodyRecircModuleTypeModel from '../../../models/post/afterbody_recirc_module_type';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function afterbodyRecircModuleTypeAction(params, store) {
  return function afterbodyRecircModuleTypeDispatch(dispatch) {
    dispatch(
      afterbodyRecircModuleTypeActions.afterbodyRecircModuleTypeLoading(store)
    );

    const state = store.getState();
    const { abtest } = state.config;

    return afterbodyRecircModuleTypeModel
      .fetch(params, store)
      .then(function handleAfterbodyRecircModuleTypeData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(
          afterbodyRecircModuleTypeActions.afterbodyRecircModuleTypeLoaded(
            data,
            store
          )
        );
      })
      .catch(function handleAfterbodyRecircModuleTypeError(err) {
        dispatch(
          afterbodyRecircModuleTypeActions.afterbodyRecircModuleTypeLoadError(
            err,
            store
          )
        );
        log.error(err, `Error in all feed actions, message: ${err.message}`);
      });
  };
}
