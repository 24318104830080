import * as homepageTagActions from '../../action_creators/homepage/homepage_tag_action_creators';
import HomepageTagModel from '../../../models/homepage/homepage_tag_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(homepageTagActions.homepageTagLoading({}, store));

    const state = store.getState();
    const { abtest } = state.config;

    return HomepageTagModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(homepageTagActions.homepageTagLoaded(data, store));
      })
      .catch(function handleUserError(err) {
        dispatch(homepageTagActions.homepageTagLoadError(err, store));
        log.error(
          err,
          `Error in homepage tag actions, message: ${err.message}`
        );
      });
  };
}
