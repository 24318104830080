import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const gift = get(state, 'gift.data.rows[0]');
  const name = `${get(gift, 'name')}`;
  const url = `https://www.fastcompany.com/gift/${get(gift, 'url')}`;
  const description = '';
  const image = get(gift, 'images[0].url') || '';

  const imageParts = image.split('/fastcompany/');
  const twitterImage = `https://a.fastcompany.net/multisite_files/fastcompany/imagecache/w/300/h/300/crop/${get(
    imageParts,
    '[1]'
  )}`;

  let title = `${name}: Latest News, Work, Videos and Photos`;
  const isIBDEntry = get(gift, 'creator_people.length');
  if (isIBDEntry) {
    title = `${name}: Innovation by Design`;
  }

  return baseSeoUtil({
    site,
    siteConf,
    title,
    url,
    description,
    image,
    articleSection: 'Product',
    twitterCard: 'summary',
    twitterImage,
    config: state.config
  });
}
