import ModernCeoPage from './ModernCeoPage';

export default function() {
  const routeConfig = {
    path: '/newsletters/modern-ceo',
    component: ModernCeoPage,
    exact: true,
    strict: true
  };

  return routeConfig;
}
