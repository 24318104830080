import get from 'lodash/get';
import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

const LATEST_ACTIVE_YEAR = 2024;
const POSTER_IMAGES = {
  2021: 'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2021/08/FC_Carousel_BWFI-padding.jpg',
  2022: 'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2022/07/BWFI_2022_Social_Share.jpg',
  2023: 'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2023/06/BWFI_SocialShare2.jpg',
  2024: 'https://images.fastcompany.com/image/upload/f_webp,q_auto,c_fit/wp-cms-2/2024/09/BWFI.socialshare.jpg'
};

/**
 * Handle DEFAULT LANDER meta data for the best workplaces for innovators page
 */
const bestWorkplacesForInnovatorsMeta = (state = {}, action = {}) => {
  // For pageData => legacy uses bestworkplacesmodel, new page uses franchisepagemodel, need both getters. State does not update properly for legacy so I am tapping into the redux action, not state
  const pageData =
    get(state, 'franchisePage.data') || get(action, 'bestWorkplacesPage');
  const airtableMetaData = pageData?.externalList?.metadata?.[0];
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const year = get(pageData, 'acf.franchise_year', LATEST_ACTIVE_YEAR);
  const seoTitle =
    get(pageData, 'acf.title') || airtableMetaData?.['SEO title'];
  const seoDescription = airtableMetaData?.metaDescription;
  const socialDescription = airtableMetaData?.OGdescription;
  const OGheadline = airtableMetaData?.OGheadline;
  const defaultDescription =
    'And when talent and innovation intersect with mission, they can help change the world.';
  const pageExcerpt = striptags(get(pageData, 'excerpt.rendered'));

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle || `Best Workplaces for Innovators ${year}`,
    overrideTitle: LATEST_ACTIVE_YEAR === 2024 ? seoTitle : false,
    facebookTitleOverride: OGheadline || seoTitle,
    twitterTitleOverride: OGheadline || seoTitle,
    socialDescriptionOverride: pageExcerpt || socialDescription,
    url: `https://www.fastcompany.com/best-workplaces-for-innovators/${
      parseInt(year, 10) === LATEST_ACTIVE_YEAR ? 'list' : year
    }`,
    description: pageExcerpt || seoDescription || defaultDescription,
    image: POSTER_IMAGES[year],
    articleSection: 'Best Workplace for Innovators',
    config: state.config
  });
};

/**
 * Handle INDIVIDUALLY RANKED COMPANY meta data for the best workplaces for innovators page
 */
const bestWorkplacesForInnovatorsItemMeta = (state = {}, action = {}) => {
  const initialRank = get(action, 'bestWorkplacesPage.initialRank');
  const pageData = get(state, 'franchisePage.data');
  const airtableMetaData = pageData?.externalList?.metadata?.[0];
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const year = get(pageData, 'acf.franchise_year', LATEST_ACTIVE_YEAR);

  // If we find a matching company with this rank, then we can use it's data
  const matchingCompany = get(
    pageData,
    `externalList.workplaces.records`,
    []
  ).filter(
    item =>
      item.fields.Rank ===
      parseInt(initialRank, 10).toLocaleString('en-US', {
        minimumIntegerDigits: 3,
        useGrouping: false
      })
  )[0].fields;

  const seoTitle = matchingCompany
    ? `${get(
        matchingCompany,
        'Company'
      )} is number ${initialRank} on Fast Company's list of the Best Workplaces for Innovators in ${year}`
    : undefined;
  const defaultDescription =
    'When talent and innovation intersect with mission, they can help change the world.';
  const pageExcerpt = striptags(get(pageData, 'excerpt.rendered'));
  const seoDescription = airtableMetaData?.metaDescription;
  const socialDescription = airtableMetaData?.OGdescription;

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle || `Best Workplaces for Innovators ${year}`,
    overrideTitle: LATEST_ACTIVE_YEAR === 2024 ? seoTitle : false,
    url: `https://www.fastcompany.com/best-workplaces-for-innovators/${
      parseInt(year, 10) === LATEST_ACTIVE_YEAR ? 'list' : year
    }`,
    description: pageExcerpt || seoDescription || defaultDescription,
    socialDescriptionOverride: socialDescription,
    image: POSTER_IMAGES[year],
    articleSection: 'Best Workplace for Innovators',
    config: state.config
  });
};

export { bestWorkplacesForInnovatorsMeta, bestWorkplacesForInnovatorsItemMeta };
