const data = [
  {
    url: '/best-workplaces-for-innovators/2019',
    id: '90378273',
    slug: 'best-workplaces-for-innovators-2019',
    tag: 'bwp'
  },
  {
    url: '/most-creative-people/2020',
    id: '90523229',
    slug: 'most-creative-people-2020',
    tag: 'mcp'
  },
  {
    url: '/innovation-by-design/2020',
    id: '90548713',
    slug: 'innovation-by-design-2020',
    tag: 'ibd'
  }
];

export default data;
