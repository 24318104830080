const mediaQueries = breakpoints => {
  const media = {};
  const breakpoinstsArray = Object.entries(breakpoints);

  breakpoinstsArray.forEach(([key, value], index) => {
    const mediaSettings = (media[key] = {});
    const prevSetting = breakpoinstsArray[index - 1];
    const nextSetting = breakpoinstsArray[index + 1];
    const minWidth = value;
    const maxWidth = nextSetting ? nextSetting[1] - 1 : 0;

    if (prevSetting && nextSetting) {
      mediaSettings.up = `@media screen and (min-width: ${minWidth}px)`;
      mediaSettings.down = `@media screen and (max-width: ${maxWidth}px)`;
    }

    if (index === 0) {
      mediaSettings.only = `@media screen and (max-width: ${maxWidth}px)`;
    } else if (nextSetting) {
      mediaSettings.only = `@media screen and (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
    } else {
      mediaSettings.only = `@media screen and (min-width: ${minWidth}px)`;
    }
  });

  return media;
};

export default mediaQueries;
