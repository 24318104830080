import React from 'react';
import Helmet from 'react-helmet';

const MindEdge = () => (
  <div className="mindEdge">
    <Helmet>
      <title>Exclusive Online Courses Powered By MindEdge | Fast Company</title>
    </Helmet>
    <header className="mindedge-top-navigation">
      <div className="incLogo">
        <a href="https://fastcompany.com">
          <img
            alt="Fast Company"
            src="//images.fastcompany.net/image/upload/v1538500532/fcweb/FC_Logo_2018_amgwhf.svg"
          />
        </a>
      </div>

      <nav>
        <div>
          <a href="https://catalog.mindedge.com/fastcompany/brands/4/courses">
            REGISTER NOW
          </a>
          <a href="https://fastcompany.mindedgeonline.com/login.php">LOGIN</a>
          <a href="https://catalog.mindedge.com/fastcompany">CATALOG</a>
        </div>
      </nav>

      <div className="mindEdgeLogo">
        <span className="mindEdgeText">POWERED BY</span>
        <img
          alt="MindEdge"
          src="//images.fastcompany.net/image/upload/v1589394443/fc/mindedge-logo_g8sghh.svg"
        />
      </div>
    </header>

    <section className="page-section page-section__intro">
      <h3 className="eyebrow">EXCLUSIVE ONLINE COURSE</h3>
      <h1 className="hed">
        “Future-Proof” <br className="design-br" /> Your Career
      </h1>
      <h2 className="dek">
        Essential Skills, <br className="mb-only" /> Essential to Your Success.
      </h2>

      <div className="cta-container">
        <div className="cta-notice">
          <div className="line1">50% Off</div>
          <div className="line2">Limited time offer</div>
          <div className="line3">
            <a href="https://catalog.mindedge.com/fastcompany">Learn more</a>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section page-section__why">
      <div className="flex-container__row wrap-reverse">
        <div className="flex-container__column">
          <div className="hed">
            Your Business and Career can only grow if you do.
          </div>

          <div className="dek">
            That is why <i>Fast Company</i> has developed “Future-Proofing Your
            Career,” an online course suite of best practices that will help you
            master the concepts, mindsets, and skills to help you thrive in this
            ever-changing, ever-innovating world.
          </div>

          <a
            className="learn-more-button"
            href="https://catalog.mindedge.com/fastcompany"
          >
            Learn more
          </a>
        </div>
        <div className="flex-container__column">
          <div className="image-container">
            <img
              alt="Fast Company Courses business mindset"
              src="//images.fastcompany.net/image/upload/v1589489963/fc/why-1_aculul.jpg"
            />
          </div>
        </div>
      </div>
    </section>

    <section className="page-section page-section__quote">
      <span>
        “<i>FAST COMPANY</i> COURSES ARE <i>MORE REFINED</i>,{' '}
        <i>MORE CHALLENGING</i>, AND <i>MORE EFFECTIVE</i> THAN YOUR TYPICAL
        ONLINE OFFERINGS.”
      </span>
    </section>

    <section className="page-section page-section__start">
      <div className="flex-container__row wrap">
        <div className="flex-container__column">
          <div className="image-container">
            <img
              alt="Fast Company Courses desk logo"
              src="//images.fastcompany.net/image/upload/v1589490031/fc/start-1_ha2djo.jpg"
            />
          </div>
        </div>
        <div className="flex-container__column">
          <div className="hed">
            Your Business and Career can only grow if you do.
          </div>

          <div className="dek">
            That is why <i>Fast Company</i> has developed “Future-Proofing Your
            Career,” an online course suite of best practices that will help you
            master the concepts, mindsets, and skills to help you thrive in this
            ever-changing, ever-innovating world.
          </div>

          <a
            className="learn-more-button"
            href="https://catalog.mindedge.com/fastcompany"
          >
            Learn more
          </a>
        </div>
      </div>
    </section>

    <div className="flex-container__row wrap">
      <div className="flex-container__column justify-center align-center">
        <span className="section-header">Certificate Courses Include</span>
        <div className="arrow-down">
          <img
            alt="Arrow pointing to certification courses."
            src="//images.fastcompany.net/image/upload/v1589558752/fc/arrow_xdohxb.svg"
          />
        </div>
      </div>
    </div>

    <section className="page-section page-section__courses">
      <div className="course-grid">
        <div className="flex-container__column border-r">
          <div className="course-card">
            <div className="image">
              <img
                src="//images.fastcompany.net/image/upload/v1589567535/fc/column1_c5gdca.png"
                alt="Showing sticky notes and thoughtful design"
              />
            </div>
            <div className="meta">
              <div className="hed">DESIGN THINKING</div>
              <div className="dek">
                Design thinking Strategies and methods for generating innovative
                products and ideas that can set an organization apart from its
                competitors.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container__column border-r">
          <div className="course-card">
            <div className="image">
              <img
                src="//images.fastcompany.net/image/upload/v1589567538/fc/column2_nzda36.png"
                alt="Showing a person speaking in front of a group"
              />
            </div>
            <div className="meta">
              <div className="hed">COMMUNICATING WITH FLAIR</div>
              <div className="dek">
                Beyond having clarity and purpose, masterful communication is
                attention grabbing, engaging and memorable. Here you’ll find
                tools to elevate your own.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container__column border-r">
          <div className="course-card">
            <div className="image">
              <img
                src="//images.fastcompany.net/image/upload/v1589567531/fc/column3_b3fgxm.png"
                alt="Depicts a team happy with their final product"
              />
            </div>
            <div className="meta">
              <div className="hed">PERSONAL BRANDING</div>
              <div className="dek">
                Stand out from the crowd by following simple, deliberate and
                effective best practices – expanding your overall presence in
                the process.
              </div>
            </div>
          </div>
        </div>
        <div className="flex-container__column">
          <div className="course-card">
            <div className="image">
              <img
                src="//images.fastcompany.net/image/upload/v1589567537/fc/column4_codwn0.png"
                alt="Shows group of people having fun and chatting"
              />
            </div>
            <div className="meta">
              <div className="hed">NETWORKING</div>
              <div className="dek">
                Make effective professional connections and cultivate
                relationships that can help you over the course of your career.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="page-section page-section__footer">
      <div className="flex-container__row">
        <div className="flex-container__column">
          <div className="hed">GET THE TOOLS YOU NEED TODAY AND EVERYDAY!</div>
          <div className="arrow-down">
            <img
              alt="Arrow pointing to certification courses."
              src="//images.fastcompany.net/image/upload/v1589558752/fc/arrow_xdohxb.svg"
            />
          </div>
          <div class="full-center">
            <a
              className="learn-more-button"
              href="https://catalog.mindedge.com/fastcompany"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default MindEdge;
