import get from 'lodash/get';

export function chooseAB(a, b, link, id, abtest) {
  let abLink = link;
  if (abLink.includes('?')) {
    abLink += '&';
  } else {
    abLink += '?';
  }

  let abTitle;
  if (abtest === 'b') {
    abTitle = b;
    abLink += `abhead=${id}b`;
  } else {
    abTitle = a;
    abLink += `abhead=${id}a`;
  }
  return [abTitle, abLink];
}

export function setAB(array, abtest) {
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    const a = get(array[i], 'title', '');
    const b = get(array[i], 'customFields.headline_b', '');
    const link = get(array[i], 'link', '');
    const id = get(array[i], 'id', '');
    const abDone = get(array[i], 'ab_done', '');

    if (a && b && link && id && abDone === 'false') {
      const [newTitle, newLink] = chooseAB(a, b, link, id, abtest);
      // eslint-disable-next-line no-param-reassign
      array[i].title = newTitle;
      // eslint-disable-next-line no-param-reassign
      array[i].link = newLink;
    }
  }
  return array;
}
