import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import set from 'lodash/set';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

const videosToShow = 10;

const sliceData = resp => {
  if (get(resp, 'data.playlist.length') > videosToShow) {
    set(
      resp,
      'data.playlist',
      get(resp, 'data.playlist', []).slice(0, videosToShow - 1)
    );
  }
  return resp;
};

export default class HomePageVideo extends Base {
  static fetch(params, store) {
    const state = store.getState();
    const cacheEnabled = get(state, 'config.cacheEnabled');
    const cacheExpire = get(state, 'config.cacheExpire');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const id = 'DCmFDcAw';
    const host = 'content.jwplatform.com';
    const options = {};
    let abtest = get(state, 'config.abtest');
    if (abtest) {
      abtest = `?abtest=${abtest}`;
    }

    const cacheKeyUrl = `https://${host}/feeds/${id}.json`;
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}${abtest}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options).then(sliceData);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes)).then(sliceData);
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(sliceData)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
