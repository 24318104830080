import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};

  return baseSeoUtil({
    site,
    siteConf,
    title: 'Person Index',
    url: 'https://www.fastcompany.com/person',
    description: 'A list of people in the Fast Company database',
    articleSection: 'Person Index',
    config: state.config
  });
}
