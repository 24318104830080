import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  return baseSeoUtil({
    site,
    siteConf,
    title: `The World’s 50 Most Innovative Companies of 2020`,
    url: `https://www.fastcompany.com/most-innovative-companies/2020`,
    description: `Fast Company's ranking of the businesses that matter most this year. From Snap to Spotify, they’re changing the world.`,
    image: `https://images.fastcompany.net/image/upload/v1583853636/fcweb/MIC.desktop_kpsqo8.jpg`,
    articleSection: 'Most Innovative Companies',
    config: state.config,
    twitterTitleOverride: `These are the 50 Most Innovative Companies of 2020`,
    facebookTitleOverride: `These are the 50 Most Innovative Companies of 2020`
  });
}
