import React from 'react';

import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./innovation_by_design_page_2018').default;
}

export function ibd2018CategoryPage(store) {
  const routeConfig = {
    path: '/innovation-by-design/:year/category/:category',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "ibd2018CategoryPage" */ './innovation_by_design_page_2018').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store);
          return Component;
        }
      );
    },
    chunk: 'ibdPage',
    autoScrollOff: true
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

let LegacyComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  LegacyComponent = require('./innovation_by_design_page').default;
}

export function legacyIbdCategoryPage(store) {
  const routeConfig = {
    path: '/innovation-by-design/:year/category/:category',
    component() {
      if (LegacyComponent) {
        return <LegacyComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: LegacyComponent ? LegacyComponent.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "legacyIbdCategoryPage" */ './innovation_by_design_page').then(
        resp => {
          LegacyComponent = resp.default;
          routeConfig.loadData = LegacyComponent.loadData(store);
          return LegacyComponent;
        }
      );
    },
    chunk: 'ibdPage',
    autoScrollOff: true
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
