import extend from 'lodash/extend';
import { canUseDOM } from 'exenv';

export default function(
  state = {
    code: 200
  },
  action
) {
  if (action.type === 'NOT_FOUND_STATUS') {
    return {
      code: 404
    };
  }

  if (action.type === 'ERROR_STATUS') {
    return {
      code: action.status
    };
  }

  if (action.type === 'UNPUBLISHED_STATUS') {
    return {
      code: 403
    };
  }

  if (action.type === 'REDIRECT_POST_URL') {
    return {
      code: 301,
      redirect: action.redirect
    };
  }

  if (/PAGE_LOADING/.test(action.type) && canUseDOM) {
    return extend({}, state, {
      code: 200
    });
  }

  return state;
}
