import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

const CURRENT_YEAR = 2024;

export default function(state = {}) {
  const isNewIBD = !!state?.franchisePage?.data?.externalList;

  if (isNewIBD) {
    const year = state?.franchisePage?.data?.franchiseCategory
      .split('-')
      .slice(-1)[0];

    const metadata = state?.franchisePage?.data?.externalList?.metadata[0];

    const url = `https://www.fastcompany.com/innovation-by-design/${
      Number(year) === CURRENT_YEAR ? 'list' : year
    }`;

    return baseSeoUtil({
      site: 'fastcompany',
      siteConf: siteData || {},
      title: metadata['SEO title'] || 'Innovation by Design',
      url,
      description: metadata['SEO description'],
      socialDescriptionOverride: metadata['Social description'],
      image:
        metadata['Social Share Image'] || metadata['featureimage (desktop)'],
      articleSection: 'Innovation By Design Awards',
      config: state.config
    });
  }

  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const year = get(state, 'innovationByDesign.year');
  const isApplyPage =
    Object.keys(get(state, 'innovationByDesignApply', {})).length >= 1;
  let title = `${isApplyPage ? "Apply for Fast Company's " : `Fast Company's`}${
    year ? ` ${year} ` : ' '
  }Innovation by Design Awards`;
  let img = `https://a.fastcompany.net/multisite_files/fastcompany/imagecache/w/500/h/400/scale/ibd/ibd-${year}-logo-share.png`;
  let description = '';
  let url = `https://www.fastcompany.com/innovation-by-design/${year}`;
  const externalAirTable = get(
    state,
    'franchisePage.data.externalList.metadata[0]',
    {}
  );
  let overrideTitle = false;
  let socialDescriptionOverride = false;

  if (
    get(state, 'innovationByDesign.data.slug', '') === 'ibd-2021-anniversary'
  ) {
    title = '10 Years of Design Innovation';
    description =
      "Now in its 10th year, Innovation by Design celebrates a new class of winners, while glancing back at the past decade and inviting some of the world's top designers to predict the future.";
    img =
      'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2021/08/IBDx10_SocialShare.jpg';
    url = 'https://www.fastcompany.com/innovation-by-design/2021/anniversary';
  } else {
    switch (parseInt(year, 10)) {
      case 2017:
        description =
          "Fast Company's sixth annual Innovation By Design Awards are now announced. Read on for this year's " +
          'best products, services, interfaces, and ideas.';
        break;
      case 2018:
        title = 'These are the 397 most inspiring, innovative designs of 2018';
        description =
          'When it comes to solving problems, these trailblazing businesses are doing it as simply and as beautifully as possible. Meet the 2018 Innovation by Design honorees.';
        img =
          'https://images.fastcompany.net/image/upload/v1536444678/fcweb/ibd_poster_og_wgggrl.png';
        break;
      default:
        // TODO: This solution is temporary. It needs some work to make sure the text is correct for each year.
        if (isApplyPage) {
          description = `Apply to be a part of Fast Company's Innovation by Design Awards.`;
        } else {
          description = `Check out the ${year} Innovation by Design Awards.`;
        }
        if (year === 'list') {
          // for 2024 you need to change these fields B.C.
          title = get(externalAirTable, 'SEO title', 'test');
          description = get(externalAirTable, 'SEO description', 'test');
          img = get(externalAirTable, 'featureimage (desktop)', 'test');
          overrideTitle = true;
          socialDescriptionOverride = description;
        }
        break;
    }
  }

  return baseSeoUtil({
    site,
    siteConf,
    title,
    overrideTitle,
    url,
    description,
    socialDescriptionOverride,
    image: img,
    articleSection: 'Innovation By Design Awards',
    config: state.config
  });
}
