import get from 'lodash/get';
import siteData from '../site_data_util';
import striptags from 'striptags';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}, action = {}) {
  // Strip tags and trim the string
  const cleanString = string => striptags(string).trim();

  const hubPageObject = get(action, 'micHubPage');
  const hubPageAcf = get(action, 'micHubPage.acf');
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const seoTitle = cleanString(get(hubPageAcf, 'title'));
  const socialTitle = get(hubPageAcf, 'franchise_title', false);
  const socialDescription = get(hubPageAcf, 'franchise_dek', false);
  const title = seoTitle || 'Most Innovative Companies Hub';
  const hubImage = get(
    hubPageObject,
    '_embedded["wp:featuredmedia"][0].source_url'
  );
  const topArticleImage = get(
    hubPageObject,
    'micHubPage.featuredArticles.posts[0].shareImage'
  );
  const description =
    cleanString(get(hubPageObject, 'seoDescription')) ||
    `An exclusive look at the World's most Innovative Companies.`;
  const url = `https://www.fastcomany.com/most-innovative-companies`;

  return baseSeoUtil({
    site,
    siteConf,
    overrideTitle: true,
    title,
    url,
    description,
    image: hubImage || topArticleImage,
    config: get(state, 'config'),
    facebookTitleOverride: socialTitle,
    twitterTitleOverride: socialTitle,
    socialDescriptionOverride: socialDescription
  });
}
