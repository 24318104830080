import { canUseDOM } from 'exenv';
import assign from 'lodash/assign';
import cloneDeep from 'lodash/cloneDeep';

export default function(state = {}, action) {
  if (action.type === 'INITIAL_PAGE_LOAD') {
    return assign({}, state, {
      initialPageLoad: false
    });
  }

  if (action.type === 'SET_SURROGATE_KEY') {
    return assign({}, state, {
      surrogateKey: action.value
    });
  }

  if (canUseDOM && /PAGE_LOADED/.test(action.type)) {
    // console.log('reducer: ' + action.type + ' ' + window.location.href);
    const navHistory = cloneDeep(state.navHistory);
    navHistory.push(window.location.href);
    return assign({}, state, { navHistory });
  }

  return state;
}
