export function magazineLatestIssuePostsLoading(resp = {}, store = {}) {
  return {
    type: 'MAGAZINE_LATEST_ISSUE_POSTS_LOADING',
    magazineLatestIssuePosts: resp,
    store
  };
}

export function magazineLatestIssuePostsLoaded(resp = {}, store = {}) {
  return {
    type: 'MAGAZINE_LATEST_ISSUE_POSTS_LOADED',
    magazineLatestIssuePosts: resp,
    store
  };
}

export function magazineLatestIssuePostsError(error, store = {}) {
  return {
    type: 'MAGAZINE_LATEST_ISSUE_POSTS_ERROR',
    magazineLatestIssuePosts: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
