import get from 'lodash/get';

export default function(state, method = 'get') {
  return {
    method,
    auth: {
      username: get(state, 'config.authUsername'),
      password: get(state, 'config.authPassword')
    }
  };
}
