// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import logger from '../../services/logger_service';

function contextHelper(type, site, id, path) {
  if (!id && !path) {
    return {
      type,
      site
    };
  }
  return {
    type,
    site,
    id,
    path
  };
}

export default function(state = {}, action) {
  const typeMap = {
    HOMEPAGE_LOADED(site) {
      return contextHelper('homepage', site);
    },
    HOMEPAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    POSTPAGE_LOADED(site, currentState) {
      const postData = get(currentState, 'post.data.post');
      const id = get(postData, 'id');
      // const isMobileAdhesiveTop = get(postData, 'tagIds', []).includes(163765)
      //   ? 'mobileAdhesive'
      //   : '';
      return contextHelper(
        get(postData, 'type'),
        site,
        id,
        `${id}/${get(postData, 'slug')}`
      );
    },
    PREVIEW_POSTPAGE_LOADED(site, currentState) {
      const postData = get(currentState, 'previewPost.data.post');
      const id = get(postData, 'id');
      return contextHelper(
        get(postData, 'type'),
        site,
        id,
        `pvw/${id}/${get(postData, 'slug')}`
      );
    },
    POSTPAGE_ERROR(site, currentState) {
      const postData = get(currentState, 'post.data.post');
      const id = get(postData, 'id');
      return contextHelper(
        'errorPage',
        site,
        id,
        `${id}/${get(postData, 'slug')}`
      );
    },
    CATEGORY_PAGE_LOADED(site) {
      return contextHelper('category', site);
    },
    CATEGORY_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    TAG_PAGE_LOADED(site) {
      return contextHelper('tag', site);
    },
    TAG_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    AUTHOR_PAGE_LOADED(site) {
      return contextHelper('author', site);
    },
    AUTHOR_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    WP_PAGE_LOADED(site) {
      return contextHelper('page', site);
    },
    WP_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    CUSTOM_PAGE_LOADED(site) {
      return contextHelper('page', site);
    },
    CUSTOM_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    HTML_SITEMAP_INDEX_PAGE_LOADED(site) {
      return contextHelper('sitemapIndexPage', site);
    },
    HTML_SITEMAP_POST_PAGE_LOADED(site) {
      return contextHelper('sitemapPostPage', site);
    },
    MAGAZINE_ARCHIVE_PAGE_LOADED(site) {
      return contextHelper('magazineArchivePage', site);
    },
    MOST_CREATIVE_PEOPLE_PAGE_LOADED(site) {
      return contextHelper('mostCreativePeoplePage', site);
    },
    MOST_CREATIVE_PEOPLE_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MOST_CREATIVE_PEOPLE_COMMUNITY_PAGE_LOADED(site) {
      return contextHelper('mostCreativePeopleCommunityPage', site);
    },
    MOST_CREATIVE_PEOPLE_COMMUNITY_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MOST_CREATIVE_PEOPLE_COMMUNITY_SECTION_PAGE_LOADED(site) {
      return contextHelper('mostCreativePeopleCommunitySectionPage', site);
    },
    MOST_CREATIVE_PEOPLE_COMMUNITY_SECTION_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PERSON_INDEX_PAGE_LOADED(site) {
      return contextHelper('personIndexPage', site);
    },
    PERSON_INDEX_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PERSON_INDEX_SECTION_PAGE_LOADED(site) {
      return contextHelper('personIndexSectionPage', site);
    },
    PERSON_INDEX_SECTION_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PERSON_PAGE_LOADED(site) {
      return contextHelper('personPage', site);
    },
    PERSON_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PRODUCT_PAGE_LOADED(site) {
      return contextHelper('productPage', site);
    },
    PRODUCT_INDEX_PAGE_LOADED(site) {
      return contextHelper('productIndexPage', site);
    },
    PRODUCT_INDEX_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PRODUCT_INDEX_SECTION_PAGE_LOADED(site) {
      return contextHelper('productIndexSectionPage', site);
    },
    PRODUCT_INDEX_SECTION_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PRODUCT_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    STATIC_PAGE_LOADED(site) {
      return contextHelper('staticPage', site);
    },
    INNOVATION_BY_DESIGN_PAGE_LOADED(site) {
      return contextHelper('ibdPage', site);
    },
    GIFT_GUIDE_PAGE_LOADED(site) {
      return contextHelper('giftGuidePage', site);
    },
    VIDEO_INDEX_PAGE_LOADED(site) {
      return contextHelper('videoIndexPage', site);
    },
    PLAYLIST_PAGE_LOADED(site) {
      return contextHelper('videoPlaylistPage', site);
    },
    VIDEO_PAGE_LOADED(site) {
      return contextHelper('videoPage', site);
    },
    VIDEO_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MIC_YEAR_PAGE_LOADED(site) {
      return contextHelper('micYearPage', site);
    },
    MIC_2020_YEAR_PAGE_LOADED(site) {
      return contextHelper('mic2020YearPage', site);
    },
    BEST_WORKPLACES_PAGE_LOADED(site) {
      return contextHelper('bestWorkplacesPage', site);
    },
    MIC_YEAR_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MIC_SECTOR_PAGE_LOADED(site) {
      return contextHelper('micSectorPage', site);
    },
    MIC_SECTOR_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    PIANO_PAGE_LOADED(site) {
      return contextHelper('pianoPage', site);
    },
    PIANO_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    QUEER50_PAGE_LOADED(site) {
      return contextHelper('queer50Page', site);
    },
    QUEER50_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    WORLD_CHANGING_IDEAS_PAGE_LOADED(site) {
      return contextHelper(get(action, 'pageType', 'default'), site);
    },
    WORLD_CHANGING_IDEAS_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    FRANCHISE_PAGE_LOADED(site) {
      return contextHelper(get(action, 'pageType', 'default'), site);
    },
    FRANCHISE_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    BRANDS_THAT_MATTER_PAGE_LOADED(site) {
      return contextHelper('btmListPage', site);
    },
    BRANDS_THAT_MATTER_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MIC_IX_PAGE_LOADED(site) {
      return contextHelper('micIxPage', site);
    },
    MIC_IX_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    HUB_PAGE_LOADED(site) {
      return contextHelper('hubPage', site);
    },
    HUB_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    WCI_HUB_PAGE_LOADED(site) {
      return contextHelper('wciHubPage', site);
    },
    WCI_HUB_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MIC_HUB_PAGE_LOADED(site) {
      return contextHelper('micHubPage', site);
    },
    MIC_HUB_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    MCP_HUB_PAGE_LOADED(site) {
      return contextHelper('mcpHubPage', site);
    },
    MCP_HUB_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    FRANCHISE_APPLY_PAGE_LOADED(site) {
      return contextHelper('applyPage', site);
    },
    FRANCHISE_APPLY_PAGE_ERROR(site) {
      return contextHelper('errorPage', site);
    },
    RECOGNITION_HUB_PAGE_LOADED(site) {
      return contextHelper('recognitionHubPage', site);
    },
    RECOGNITION_HUB_PAGE_ERROR(site) {
      return contextHelper('recognitionHubPage', site);
    }
  };

  const { store, type } = action;

  if (typeMap[type]) {
    const currentState = store.getState();
    const site = get(currentState, 'config.site');
    return typeMap[action.type](site, currentState);
  }

  if (/PAGE_LOADED/.test(type)) {
    if (store && store.getState) {
      const currentState = store.getState();
      const site = get(currentState, 'config.site');
      logger.error(
        `CONTEXT REDUCER DID NOT HANDLE PAGE LOADED, ANALYTICS ARE BROKEN ON ${type}!!`
      );
      return {
        type: 'unknown',
        site
      };
    }
  }

  if (/PAGE_ERROR/.test(type)) {
    if (store && store.getState) {
      const currentState = store.getState();
      const site = get(currentState, 'config.site');
      logger.error(
        `CONTEXT REDUCER DID NOT HANDLE PAGE ERROR, ANALYTICS ARE BROKEN ON ${
          action.type
        }!!`
      );
      return contextHelper('errorPage', site);
    }
  }

  return state;
}
