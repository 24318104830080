// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    HOMEPAGE_LOADING() {
      return {
        isLoading: true,
        homepage: action.homepage
      };
    },
    HOMEPAGE_LOADED() {
      return {
        isLoading: false,
        data: get(action, 'homepage')
      };
    },
    HOMEPAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.homepage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
