import get from 'lodash/get';
import identityConfig from '../../../config/identity';

export default function loadComscoreTracker(currentState) {
  let site = get(currentState, 'context.site', 'fastcompany');
  const comscoreConfig = get(identityConfig, 'comscore');
  const { c1, c2 } = comscoreConfig;
  if (site) {
    site = site.toUpperCase();
  } else {
    site = window.location.hostname;
  }

  // Read cs_ucfr from local storage
  const csUcfr = localStorage.getItem('cs_ucfr') || '';

  // reset COMSCORE and add options test
  if (window.COMSCORE && window.COMSCORE.beacon) {
    window.COMSCORE.beacon({
      c1,
      c2,
      cs_ucfr: csUcfr,
      options: {
        url_append: `comscorekw=${site}`,
        enableFirstPartyCookie: true,
        bypassUserConsentRequirementFor1PCookie: true
      }
    });
  }
}

export function sendComscoreCandidatePing() {
  // figure out domain site name and deal with subdomains etc
  const hostNameSplit = window.location.hostname.split('.');
  let site =
    get(hostNameSplit, 'length') === 2 ? hostNameSplit[0] : hostNameSplit[1];

  const comscoreConfig = get(identityConfig[site], 'comscore', {});
  const { c1, c2 } = comscoreConfig;
  if (site) {
    site = site.toUpperCase();
  }

  // Read cs_ucfr from local storage
  const csUcfr = localStorage.getItem('cs_ucfr') || '';

  // reset COMSCORE
  if (window.COMSCORE && window.COMSCORE.beacon) {
    window.COMSCORE.beacon({
      c1,
      c2,
      cs_ucfr: csUcfr,
      options: {
        url_append: `comscorekw=${site}`,
        enableFirstPartyCookie: true
      }
    });
  }
}
