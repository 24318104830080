// import { canUseDOM } from 'exenv';

export function tagAllFeedLoading(resp = {}, store = {}) {
  return {
    type: 'TAG_ALL_FEED_LOADING',
    tagAllFeed: resp,
    store
  };
}

export function tagAllFeedLoaded(resp = {}, store = {}) {
  return {
    type: 'TAG_ALL_FEED_LOADED',
    tagAllFeed: resp,
    store
  };
}

export function tagAllFeedLoadError(error, store = {}) {
  return {
    type: 'TAG_ALL_FEED_ERROR',
    tagAllFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}

export function tagAllFeedAllStoriesRendered() {
  return {
    type: 'TAG_ALL_FEED_ALL_STORIES_RENDERED',
    allRendered: true
  };
}
