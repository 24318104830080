import get from 'lodash/get';
import set from 'lodash/set';
import * as categoryPageActions from '../../action_creators/category/category_page_action_creators';
import log from '../../../services/logger_service';
import tagAllFeedActions from '../tag/async_tag_all_feed_actions';
import categoryVideoActions from './async_category_video_actions';
import categoryAllFeedActions from './async_category_all_feed_actions';
import categoryScrollingModule from './async_category_scrolling_module_actions';
import asyncCategoryPromoBannerActions from './async_category_promo_banner_actions';
import categoryFeaturedFeedActions from './async_category_featured_feed_actions';
import asyncRecircFeedActions from '../async_recirc_feed_actions';
import asyncFeaturedSponsoredCardActions from '../homepage/async_featured_sponsored_card_actions';
import asyncMagazineLatestIssuePostsActions from '../magazine/async_magazine_latest_issue_posts_actions';

import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';

/**
 * Determines whether or not to fetch the articles for the
 * Fast Company Executive Board.
 * @param {number} tagId - The ID of the tag to check.
 * @returns {boolean} - Whether or not to display the video module.
 */
const shouldFetchExecutiveBoardArticles = slug => {
  const allowedSlugs = [
    'co-design',
    'technology',
    'work-life',
    'impact',
    'recommender'
  ];
  return allowedSlugs.indexOf(slug) !== -1;
};

export default function fetchCategoryPage(params, store, isTag) {
  return function firstDispatchCategoryPage(dispatch) {
    dispatch(categoryPageActions.categoryPageLoading());
    let actions = [];

    switch (params.category) {
      case 'magazine':
        actions = [
          asyncMagazineLatestIssuePostsActions(params, store, isTag)(dispatch)
        ];
        set(params, 'category', 'magazine');
        break;
      default:
        actions = [
          categoryVideoActions(params, store, isTag)(dispatch),
          categoryAllFeedActions(params, store, isTag)(dispatch),
          categoryScrollingModule(params, store)(dispatch),
          asyncFeaturedSponsoredCardActions(params, store, isTag)(dispatch),
          asyncCategoryPromoBannerActions(params, store, isTag)(dispatch)
        ];
        break;
    }

    // Fetch the articles for the Fast Company Executive Board
    if (shouldFetchExecutiveBoardArticles(params.category)) {
      actions.push(
        tagAllFeedActions(
          {
            tag: 'fast-company-executive-board'
          },
          store,
          true
        )(dispatch)
      );
    }

    return Promise.all([
      categoryFeaturedFeedActions(params, store, isTag)(dispatch),
      asyncRecircFeedActions(params, store, isTag)(dispatch),
      ...actions
    ])
      .then(function handleCategoryPageData() {
        dispatch(categoryPageActions.categoryPageLoaded({}, store));
        return true;
      })
      .catch(function handleCategoryPageError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(
            err,
            `Error in category page actions, message: ${err.message}`
          );
        }
        dispatch(categoryPageActions.categoryPageLoadError(err, store));
      });
  };
}
