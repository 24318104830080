import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../../components/loading_spinner/loading_spinner')
);

let Mic2020Component = false;
let Mic2019Component = false;
let Mic2018Component = false;
let LegacyMICComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Mic2020Component = require('../mic_year_2020_page').default;
  // eslint-disable-next-line global-require
  Mic2019Component = require('../mic_year_2019_page').default;
  // eslint-disable-next-line global-require
  Mic2018Component = require('../mic_year_2018_page').default;
  // eslint-disable-next-line global-require
  LegacyMICComponent = require('../mic_year_page').default;
}

/**
 * 2020 Lander Route
 */
export function micPage2020(store) {
  const routeConfig = {
    path: '/most-innovative-companies/2020',
    component() {
      if (Mic2020Component) {
        return <Mic2020Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mic2020Component ? Mic2020Component.loadData(store) : false,
    preloadChunk() {
      return import('../mic_year_2020_page').then(resp => {
        Mic2020Component = resp.default;
        routeConfig.loadData = Mic2020Component.loadData(store);
        return Mic2020Component;
      });
    },
    chunk: 'MicYearPage2020'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

/**
 * 2019 LANDER ROUTE
 */
export function micPage2019(store) {
  const routeConfig = {
    path: '/most-innovative-companies/2019/:sectors?/:sector?',
    component() {
      if (Mic2019Component) {
        return <Mic2019Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mic2019Component ? Mic2019Component.loadData(store) : false,
    preloadChunk() {
      return import('../mic_year_2019_page').then(resp => {
        Mic2019Component = resp.default;
        routeConfig.loadData = Mic2019Component.loadData(store, '2017');
        return Mic2019Component;
      });
    },
    chunk: 'MicYearPage2019'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

/**
 * 2018 LANDER ROUTE
 */
export function micPage2018(store) {
  const routeConfig = {
    path: '/most-innovative-companies/2018/:sectors?/:sector?',
    component() {
      if (Mic2018Component) {
        return <Mic2018Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Mic2018Component ? Mic2018Component.loadData(store) : false,
    preloadChunk() {
      return import('../mic_year_2018_page').then(resp => {
        Mic2018Component = resp.default;
        routeConfig.loadData = Mic2018Component.loadData(store);
        return Mic2018Component;
      });
    },
    chunk: 'MicYearPage2018'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

/**
 * LEGACY (<= 2017) LANDER ROUTE
 */
export function legacyMicPage(store) {
  const routeConfig = {
    path:
      '/most-innovative-companies/:year(2008|2009|2010|2011|2012|2013|2014|2015|2016|2017)/:sectors?/:sector?',
    component() {
      if (LegacyMICComponent) {
        return <LegacyMICComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: LegacyMICComponent ? LegacyMICComponent.loadData(store) : false,
    preloadChunk() {
      return import('../mic_year_page').then(resp => {
        LegacyMICComponent = resp.default;
        routeConfig.loadData = LegacyMICComponent.loadData(store);
        return LegacyMICComponent;
      });
    },
    chunk: 'micYearPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
