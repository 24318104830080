import get from 'lodash/get';
import identityConfig from '../../../config/identity';

/* eslint-disable */
export default function loadFBTracker(currentState) {
  const facebookConfig = get(identityConfig, 'facebookPixel');
  const { id } = facebookConfig;

  // https://www.facebook.com/ads/manager/pixel/facebook_pixel/?act=1375631352670761&pid=p1
  if (window.fbq) {
    return false;
  }

  let fbq = window.fbq;
  fbq = function() {
    fbq.callMethod ? fbq.callMethod(...arguments) : fbq.queue.push(arguments);
  };

  if (!window._fbq) {
    window._fbq = fbq;
  }

  fbq.push = fbq;
  fbq.loaded = !0;
  fbq.version = '2.0';
  fbq.queue = [];

  fbq('init', `${id}`);
  fbq('track', 'PageView');
}
/* eslint-disable */
