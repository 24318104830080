export default function(state = {}, action) {
  const typeMap = {
    WCI_HUB_PAGE_LOADING() {
      return {
        isLoading: true,
        wciHubPage: action.wciHubPage
      };
    },
    WCI_HUB_PAGE_LOADED() {
      return {
        isLoading: false,
        data: action.wciHubPage
      };
    },
    WCI_HUB_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.wciHubPage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
