import loadable from '@loadable/component';
import stateManager from './state_manager';

const InnovationByDesign = loadable(() => import('./list_page'));
const SplashPage = loadable(() => import('./splash_page'));

const innovationByDesignRoute = store => {
  const SPLASH_PAGE_ACTIVE =
    store?.getState()?.franchisePage?.data?.externalList?.metadata?.[0]?.[
      'Show Splash Page'
    ] || false;

  return {
    path: '/innovation-by-design/:year',
    component: SPLASH_PAGE_ACTIVE ? SplashPage : InnovationByDesign,
    exact: true,
    strict: true,
    loadData: stateManager(store)
  };
};

export default innovationByDesignRoute;
