import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('../innovation_festival_360_tag_page').default;
}

export default function(store) {
  const routeConfig = {
    path: '/section/innovation-festival-360',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import('../innovation_festival_360_tag_page').then(resp => {
        Component = resp.default;
        routeConfig.loadData = Component.loadData(store);
        return Component;
      });
    },
    chunk: 'tagPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="tag-page">
        <article className="tag-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
