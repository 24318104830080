// import { canUseDOM } from 'exenv';

export function categoryScrollingModuleLoading(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_SCROLLING_MODULE_LOADING',
    categoryScrollingModule: resp,
    store
  };
}

export function categoryScrollingModuleLoaded(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_SCROLLING_MODULE_LOADED',
    categoryScrollingModule: {
      posts: resp.posts,
      tag: resp.tag
    },
    store
  };
}

export function categoryScrollingModuleLoadError(error, store = {}) {
  return {
    type: 'CATEGORY_SCROLLING_MODULE_ERROR',
    categoryScrollingModule: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
