import get from 'lodash/get';
import assign from 'lodash/assign';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const siteHumanReadable = get(siteConf, '[og:site_name]');

  const url = get(state, 'config.navHistory[0]');

  /*
    USE CASE: add your url here if you are creating a static page that does not pull
    data from an external API. To start, there is only /newsletters page.
  */

  const urlLookup = {
    'https://www.fastcompany.com/newsletters': {
      title: 'Fast Company Newsletters',
      description: 'Get the latest stories and updates sent your inbox.',
      url,
      articleSection: 'Fast Company Newsletters'
    }
  };

  const foundStaticConfig = urlLookup[url] || {};

  const base = {
    site,
    siteConf,
    title: siteHumanReadable,
    config: state.config
  };

  const union = assign({}, base, foundStaticConfig);

  const data = baseSeoUtil(union);

  return data;
}
