import * as megatronNativeTile from '../../action_creators/homepage/megatron_native_tile_action_creators';
import MegatronNativeTileModel from '../../../models/homepage/homepage_megatron_native_tile_module';
import log from '../../../services/logger_service';

export default function fetchMegatronModule(params, store) {
  return function firstDispatchMegatron(dispatch) {
    dispatch(megatronNativeTile.megatronNativeTileLoading());

    // const state = store.getState();
    return MegatronNativeTileModel.fetch(params, store)
      .then(function handleMegatronModuleData(resp) {
        const { data } = resp;
        const results = { posts: [] };
        if (data && data?.data) {
          if (Array.isArray(data?.data) && data.data.length > 0) {
            // eslint-disable-next-line no-unused-expressions
            data?.data.forEach(obj => {
              Object.keys(obj).forEach(key => {
                results.posts.push(obj[key]);
              });
            });
          }
        }
        dispatch(megatronNativeTile.megatronNativeTileLoaded(results, store));
      })
      .catch(function handleMegatronError(err) {
        dispatch(megatronNativeTile.megatronNativeTileLoadError(err, store));
        log.error(
          err,
          `Error in homepage megatronNativeTile actions, message: ${
            err.message
          }`
        );
      });
  };
}
