import React, { useState } from 'react';
import MarketoForm from '../../../../components/MarketoForm/MarketoForm';
import SuccessMessage from './SuccessMessage';
import { Helmet } from 'react-helmet';

const ModernCeoPage = () => {
  const [wasSubmitted, setSubmitted] = useState(false);

  return (
    <section className="section__modernceo__page">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="head-wrap">
        <img
          src="https://assets.fastcompany.com/image/upload/v1675805781/Modern%20CEO%20Lander/SM-Illo_2.webp"
          alt="Stephanie Mehta"
          height="168"
          width="84"
          className="headshot"
        />
        <img
          src="https://assets.fastcompany.com/image/upload/v1675805904/Modern%20CEO%20Lander/stacked-tagline_1.svg"
          alt="Modern CEO - from the publisher of Fast Company and Inc."
          height="100"
          width="321"
        />
        <img
          className="img-dt"
          src="https://assets.fastcompany.com/image/upload/v1675983844/Modern%20CEO%20Lander/header-modern-ceo.webp"
          alt="Modern CEO - from the publisher of Fast Company and Inc."
          height="668"
          width="1600"
          srcSet="https://assets.fastcompany.com/image/upload/c_scale,w_979/v1675983844/Modern%20CEO%20Lander/header-modern-ceo.png 979w, https://assets.fastcompany.com/image/upload/v1675983844/Modern%20CEO%20Lander/header-modern-ceo.webp 1600w"
          sizes="(max-width: 1200px) 550px, 800px"
        />
      </div>
      <h1>
        Insights for today’s CEO from the desk of CEO and Chief Content Officer
        Stephanie Mehta
      </h1>

      {wasSubmitted ? <SuccessMessage /> : <CopyText />}

      {!wasSubmitted && (
        <MarketoForm
          assetId={1192}
          onSubmitHandler={() => setSubmitted(true)}
        />
      )}

      <span>
        <a
          href="https://www.mansueto.com/privacy-policy/"
          title="Privacy Policy"
        >
          Privacy Policy
        </a>
      </span>
    </section>
  );
};

function CopyText() {
  return (
    <div className="text-wrap">
      <p>
        Being a leader today can be fulfilling, but it also can be lonely and
        overwhelming. It’s your job to build and lead while navigating myriad
        challenges and opportunities daily. Modern CEO is the newsletter created
        just for you.
      </p>
      <p>
        Written by Mansueto Ventures CEO Stephanie Mehta, this weekly newsletter
        delivers fresh thinking and solutions for executives and entrepreneurs,
        drawn from the pages of Fast Company, Inc., and other sources that
        explore modern leadership.
      </p>
      <p>Get the insights you need, from CEO to CEO. Subscribe today.</p>
    </div>
  );
}

export default ModernCeoPage;
