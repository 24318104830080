import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('../wp_page').default;
}

export default function(store) {
  const routeConfig = {
    path:
      '/:subpath(about-us|contact-us|privacy-policy|staff-masthead|opt-out|statement-of-editorial-ethics)',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "aboutUsWpPage" */ '../wp_page').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store);
          return Component;
        }
      );
    },
    chunk: 'aboutUsWpPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="video-page">
        <article className="video-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
