import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const siteHumanReadable = get(siteConf, '[og:site_name]');

  let next = false;
  let prev = false;

  const page = get(state, 'allFeed.data.page');
  const totalPages = get(state, 'allFeed.data.totalPages');
  const rss = '/latest/rss?truncated=true';

  const base = {
    site,
    siteConf,
    title: siteHumanReadable || 'Fast Company',
    twitterCard: 'summary',
    publisher: false,
    type: 'website',
    config: state.config,
    rss,
    extend: [
      {
        link: []
      }
    ],
    useSloganTitle: true
  };

  if (page < totalPages && page - 1 !== 0) {
    prev = `https://www.${site}.com/all/${page - 1}`;
  }

  if (page + 1 <= totalPages) {
    next = `https://www.${site}.com/all/${page + 1}`;
  }

  if (next) {
    base.extend[0].link.push({
      rel: 'next',
      href: next
    });
  }

  if (prev) {
    base.extend[0].link.push({
      rel: 'prev',
      href: prev
    });
  }

  return baseSeoUtil(base);
}
