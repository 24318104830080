// Embed instructions:
// To populate the modified embed field change the height from 482 to 100%.
// The hardcoded styling needs to be changed in order to display properly.
const podcasts = [
  {
    title: 'The New Way We Work',
    slug: 'new-way-we-work', // User facing slug
    wp_slug: 'the-new-way-we-work-podcast',
    tag: 'The New Way We Work podcast',
    blurb: `Fast Company deputy editor Kathleen Davis takes listeners on a journey through the changing landscape of our work lives. Each episode explores the future of work, including the state of remote and hybrid work amid the return-to-office battle; how AI will change the way we do our jobs; the status of gender equity and DEI efforts; rethinking career ladders and ambition; motivation and what makes work meaningful; and the progress on mental health and disability issues at work. And as if all that isn’t enough, she also shares practical advice for interviews, résumés, and salary negotiations, as well as the latest office jargon, just how useful personality tests really are, and more.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link: 'https://podcasts.apple.com/podcast/id1393035987'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/show/2POyz4d2ymhs8NqSBY7gfW'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/feed/aHR0cHM6Ly9yc3Muc2ltcGxlY2FzdC5jb20vcG9kY2FzdHMvNTg3Mi9yc3M='
      },
      {
        platform: 'Amazon',
        link:
          'https://music.amazon.com/podcasts/0add8ee2-00af-46c8-b22f-8292a673c18c/the-new-way-we-work'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV3867221972&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV3867221972&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://assets.fastcompany.com/image/upload/w_1250,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/v1610657570/NewWay.webp'
  },
  {
    title: 'Most Innovative Companies',
    slug: 'most-innovative-companies',
    wp_slug: 'most-innovative-companies-podcast',
    tag: 'most innovative companies podcast',
    blurb: `Join Fast Company Associate Editor Yasmin Gagne as she brings you the latest innovations transforming business and society—and highlights the companies that are reshaping industries and culture.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link:
          'https://podcasts.apple.com/us/podcast/most-innovative-companies/id1576874503'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/episode/05jfFOiEGbDKO3cSvs9vAi'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vTUFOVjY2NTg3NTMzMDE'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV6658753301&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV6658753301&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://images.fastcompany.net/image/upload/w_1250,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/v1686324847/MIC_podcast_2480x1395.webp'
  },
  {
    title: 'Fast Company Digest',
    slug: 'fast-company-digest',
    wp_slug: 'fast-company-digest-podcast',
    tag: 'fast company digest podcast',
    blurb: `Fast Company Digest is a short weekly podcast from Fast Company Magazine highlighting essential reads from Tech, Design, Impact and Work Life narrated by Noa app. `,
    links: [
      {
        platform: 'Apple Podcasts',
        link:
          'https://podcasts.apple.com/us/podcast/fast-company-digest/id1697327560'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/show/5CoLDETaowNjEzoGx9HjOt'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vZmNkaWdlc3Q?sa=X&ved=0CB8Q27cFahgKEwjoqPuNppmBAxUAAAAAHQAAAAAQ1AI'
      },
      {
        platform: 'Amazon',
        link:
          'https://music.amazon.com/podcasts/4202455e-50ca-4e79-9e08-dc93fa7afc52/fast-company-daily'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm/?p=MANV9230548449&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV9230548449&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://assets.fastcompany.com/image/upload/v1694196562/FC_Digest_16-9.webp'
  },
  {
    title: 'Creative Control',
    slug: 'creative-control',
    wp_slug: 'creative-control',
    tag: 'Creative Control',
    blurb: `Fast Company's Creative Control is taking a deep dive into the forces that are shaping the creator economy and what it means for its future. As the creator economy continues its rapid expansion, there are issues that deserve a closer look. Topics like who owns what online, where’s the middle class for creators, and what web3 technology will really mean for creators after the hype.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link:
          'https://podcasts.apple.com/podcast/idhttps-podcasts-apple-com-us-podcast-creative-conversation/id1393035409'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/show/0YDfXMXCT4yamNLdWBULgm'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vTUFOVjgxMDYyODkwMzM%3D'
      },
      {
        platform: 'Amazon',
        link:
          'https://music.amazon.com/podcasts/e8a0b4f3-9ba7-4995-8d42-08ef6391e28b/creative-control'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV8106289033&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV8106289033&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://images.fastcompany.net/image/upload/w_1250,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/v1647378938/fcweb/creativecontrol_vk6cag.jpg'
  },
  {
    title: 'World Changing Ideas',
    slug: 'world-changing-ideas',
    wp_slug: 'world-changing-ideas-podcast',
    tag: 'world changing ideas podcast',
    blurb: `What if we spent more time thinking about solutions rather than problems? Are you looking for more positive news? World Changing Ideas travels around the globe to find the leaders, experts and activists who are dreaming big and working to change the world for the better. With a focus on the environment, social justice and innovation, these are stories that will get you thinking. World changing ideas come in all shapes and sizes. Join the conversation with solutions journalist and filmmaker Amelia Hemphill.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link: 'https://podcasts.apple.com/us/podcast/fast-break/id1504787159'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/show/6qhfc6mZXbHFQK0hGOUjJ4'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/?feed=aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vZmFzdGJyZWFr&ved=0CBgQ27cFahcKEwjY8YCW2-XoAhUAAAAAHQAAAAAQBQ'
      },
      {
        platform: 'Amazon',
        link:
          'https://music.amazon.com/podcasts/ec26af58-3d4b-487c-9a37-e43ddd73853e/world-changing-ideas'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV5546106257&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV5546106257&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://images.fastcompany.net/image/upload/w_1250,ar_16:9,c_fill,g_auto,f_auto,q_auto,fl_lossy/v1614101062/fcweb/WCI_Podcast_Cover_Web_ygotsq.jpg'
  },
  {
    title: 'Leaders In Innovation',
    slug: 'leaders-in-innovation',
    wp_slug: 'leaders-in-innovation-podcast',
    tag: 'leaders in innovation podcast',
    blurb: `Some of the greatest inventions have come out of crises - from the printing press during the Black Death in the Middle Ages, the computer during WWII to COVID vaccines in 2020. Innovation is essential for growth, but no company or CEO approaches it the same way. Join James Vincent as he explores different approaches to essential innovation from leaders across AI, Mobility, Food, Travel, Design, Luxury and more.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link:
          'https://podcasts.apple.com/us/podcast/presenting-leaders-in-innovation-with-host-james-vincent/id1706149573?i=1000630279814'
      },
      {
        platform: 'Spotify',
        link: 'https://open.spotify.com/show/0t9r9MvR2DsLmO3wTLLZkK'
      }
      // {
      //   platform: 'Google Podcasts',
      //   link:
      //     'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vTUFOVjY2NTg3NTMzMDE'
      // },
      // {
      //   platform: 'Stitcher',
      //   link: 'https://www.stitcher.com/show/most-innovative-companies'
      // }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm/?p=MANV9811921871&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm?p=MANV9811921871&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://assets.fastcompany.com/image/upload/v1694197104/LeadersInInnovation_16x9_1_1.webp'
  },
  {
    title: 'Hit the Ground Running',
    slug: 'hit-the-ground-running',
    wp_slug: 'hit-the-ground-running-podcast',
    tag: 'Hit the Ground Running Podcast',
    blurb: `Join hosts Christina Royster and Yasmin Gagne as they figure out how to navigate the unwritten rules of work and life, exploring everything from what to wear on a zoom call to how to manage a side hustle. Available wherever you listen to podcasts. Subscribe now.`,
    links: [
      {
        platform: 'Apple Podcasts',
        link:
          'https://podcasts.apple.com/us/podcast/hit-the-ground-running/id1558760059'
      },
      {
        platform: 'Spotify',
        link:
          'https://open.spotify.com/show/3hZ9uFtZ2ZIlaHfEjS2Vs4?si=lk5l3FY7SlWi--yk2ITlfw'
      },
      {
        platform: 'Google Podcasts',
        link:
          'https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5tZWdhcGhvbmUuZm0vaHRncg?sa=X&ved=2ahUKEwjg5evRzNrvAhXxM1kFHX-9C8sQ9sEGegQIARAC'
      }
    ],
    embed: `<iframe frameborder="0" src="https://playlist.megaphone.fm/?p=MANV9899270960&light=true" width="100%" height="482"></iframe>`,
    modifiedEmbed: `<iframe frameborder="0" src="https://playlist.megaphone.fm/?p=MANV9899270960&light=true" width="100%" height="100%"></iframe>`,
    image:
      'https://assets.fastcompany.com/image/upload/v1617050229/HTGR_Podcast_WebsiteTopArt_new.webp'
  }
];

export default podcasts;
