// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    HOMEPAGE_TAG_LOADING() {
      return {
        isLoading: true,
        homepageTag: action.homepageTag
      };
    },
    HOMEPAGE_TAG_LOADED() {
      const tagID = get(action, 'homepageTag.tag_id');

      return {
        isLoading: false,
        data: action.homepageTag.posts,
        tagID
      };
    },
    HOMEPAGE_TAG_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.homepageTag.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
