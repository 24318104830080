import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

export default class FranchisePageData extends Base {
  static fetch({ slug }, store) { // eslint-disable-line
    const state = store.getState();
    const cacheEnabled = get(state, 'config.cacheEnabled');
    const cacheExpire = get(state, 'config.cacheExpire');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const host = 'fc-api.fastcompany.com';
    const options = {};
    let id = slug;

    if (slug === 'amazon-taking-over-complete-guide') {
      id = 'amazon-unpacked';
    }

    if (slug === 'next-big-things-in-tech/2021') {
      id = 'next-big-things-in-tech-2021';
    }

    if (slug === 'next-big-things-in-tech/2022') {
      id = 'next-big-things-in-tech';
    }

    // Don't worry about this token. Can be public. Talked to Harry.
    const cacheKeyUrl = `https://${host}/api/v1/franchise/${id}?token=33FDDA6B-93D3-40A7-83E9-E2AA671FEC07`;
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }

      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
