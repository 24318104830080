import get from 'lodash/get';
import * as categoryAllFeedActions from '../../action_creators/category/category_all_feed_action_creators';
import CategoryAllFeedModel from '../../../models/category/category_all_feed';
import log from '../../../services/logger_service';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchCategoryAllFeed(params, store, isTag) {
  return function firstDispatchCategoryAllFeed(dispatch) {
    dispatch(categoryAllFeedActions.categoryAllFeedLoading());

    const state = store.getState();
    const { abtest } = state.config;

    return CategoryAllFeedModel.fetch(params, store, isTag)
      .then(function handleCategoryAllFeedData(CategoryData) {
        if ((params.page || isTag) && !get(CategoryData, 'data.posts.length')) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        }

        const { data } = CategoryData;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(categoryAllFeedActions.categoryAllFeedLoaded(data, store));
      })
      .catch(function handleCategoryAllFeedError(err) {
        const status = get(err, 'response.status', 500);

        if (status === 500) {
          log.error(
            err,
            `Error in category all feed actions, message: ${err.message}`
          );
        }

        dispatch(categoryAllFeedActions.categoryAllFeedLoadError(err, store));
      });
  };
}
