import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let ApplyPageComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  ApplyPageComponent = require('./index').default;
}

export default function franchiseApplyPage(store) {
  const routeConfig = {
    path:
      '/apply/:slug(brands-that-matter|next-big-things-in-tech|most-innovative-companies|world-changing-ideas|best-workplaces-for-innovators|innovation-by-design)',
    component() {
      if (ApplyPageComponent) {
        return <ApplyPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    // autoScrollOff: true,
    loadData: ApplyPageComponent
      ? ApplyPageComponent.loadData(store, false, {
          type: 'application'
        })
      : false,
    preloadChunk() {
      return import('./index').then(resp => {
        ApplyPageComponent = resp.default;
        routeConfig.loadData = ApplyPageComponent.loadData(store, false, {
          type: 'application'
        });

        return ApplyPageComponent;
      });
    },
    chunk: 'franchiseApplyPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
