import get from 'lodash/get';
import { canUseDOM } from 'exenv';
import URL from 'url-parse';
import * as postActions from '../../action_creators/post/post_action_creators';
import PostModel from '../../../models/post/post';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import redirectPostActionCreator from '../../action_creators/redirect_action_creators';
import find from 'lodash/find';
import log from '../../../services/logger_service';
import fetchMic2020Company from '../mic/async_mic2020_company_actions';
import fetchMic2020Region from '../mic/async_mic2020_regions_actions';
import fetchMic2020Sector from '../mic/async_mic2020_sectors_actions';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    const state = store.getState();
    dispatch(postActions.postLoading({}, store));
    return PostModel.fetch(params, store)
      .then(function handlePostDetailData(PostData) {
        const isPreview = get(state, 'config.isPreview');
        const postLink = get(PostData, 'data.post.link');
        const currentUrl = get(state, 'config.navHistory[0]');
        // For articles with custom urls, will be added to post data so infinitescroll and social share will use proper url.
        const isNewUrl = get(params, 'isNewUrl', '');
        if (isNewUrl) {
          // eslint-disable-next-line no-param-reassign
          PostData.data.post.newUrl = currentUrl;
        }
        // eslint-disable-next-line no-param-reassign
        PostData.data.post.isNewUrl = isNewUrl;
        const shouldRedirect = get(params, 'articleRedirect', true);
        if (!canUseDOM && postLink && !isPreview) {
          if (shouldRedirect && currentUrl !== postLink) {
            const parsedCurrent = new URL(currentUrl).pathname;
            const parsedPostLink = new URL(postLink).pathname;
            if (parsedCurrent !== parsedPostLink) {
              dispatch(
                redirectPostActionCreator({
                  status: 301,
                  redirect: postLink
                })
              );
            }
          }
        }

        if (state.config.featureFlags.mic2020) {
          const isMic2020CompanyPost = find(get(PostData, 'data.post.tags'), {
            name: 'Most Innovative Companies 2020'
          });

          const isMic2020SectorPost = find(get(PostData, 'data.post.tags'), {
            name: 'Most Innovative Companies 2020 Sectors'
          });

          const isMic2020RegionPost = find(get(PostData, 'data.post.tags'), {
            name: 'Most Innovative Companies 2020 Regions'
          });

          if (isMic2020SectorPost || isMic2020RegionPost) {
            return Promise.all([
              fetchMic2020Sector({}, store)(dispatch),
              fetchMic2020Region({}, store)(dispatch)
            ]).then(() => {
              dispatch(postActions.postLoaded(PostData.data, store));
            });
          }

          if (isMic2020CompanyPost) {
            return fetchMic2020Company({}, store)(dispatch).then(() => {
              dispatch(postActions.postLoaded(PostData.data, store));
            });
          }
        }

        return dispatch(postActions.postLoaded(PostData.data, store));
      })
      .catch(function handleUserError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 400 || status === 403) {
          dispatch(notFoundActionCreator(403, 'UNPUBLISHED_STATUS', store));
        }
        if (status === 404 || status === 401) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        }
        if (status === 500) {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(err, `Error in post actions, message: ${err.message}`);
        }
        dispatch(postActions.postLoadError(err, store));
      });
  };
}
