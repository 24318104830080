import get from 'lodash/get';
import extend from 'lodash/extend';
import merge from 'lodash/merge';
import identityConfig from '../../../config/identity';
import { createAdDataModel } from '../../../views/components/analytics/trackers/camp';
import { isPremium } from '../../../views/components/advertorial/advertorial';

export default function updateDatalayer(currentState, pageNumber = 1) {
  const siteConfig = identityConfig;
  const context = get(currentState, 'context');
  if (siteConfig === undefined) {
    return '';
  }

  const {
    postData,
    tagPageData,
    categoryPageData,
    authorPageData,
    searchPageData,
    staticPageData,
    mostCreativePeoplePageData,
    personPageData,
    statusCode,
    ibdPageData,
    companyPageData,
    micYearPageData,
    micSectorPageData,
    videoPageData,
    playlistAllFeedData,
    type
  } = context;
  const getDaysSincePubdate = date =>
    Math.round((new Date().getTime() - new Date(date)) / (24 * 60 * 60 * 1000));
  const navHistory = get(currentState, 'config.navHistory', ['']);
  const requestStatusCode = get(currentState, 'status.code');
  // Data layer data structure, defaults to homepage value
  let dataLayerMapping = {};
  const typeLookup = {
    homepage() {
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `Homepage`,
            destinationURL: `https://www.fastcompany.com`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: `homepage`,
            subCategory1: `homepage`,
            subCategory2: `homepage`
          }
        }
      });
    },
    post() {
      const postID = get(postData, 'id', 0);
      const pageName = get(postData, 'title', 'Untitled');
      const postSlug = get(postData, 'slug', 'no-slug-provided');

      let destinationURL = postData.link;
      if (postData.cardType === 'Sponsored Story') {
        destinationURL = `${get(siteConfig, 'url')}/${postID}/${postSlug}`;
      }

      let author = '';
      if (postData.author && postData.author.name !== '') {
        author = postData.author.name;
      }

      let primaryCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[0] !== undefined
      ) {
        primaryCategory = postData.categories[0].name;
      }

      let subCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[1] !== undefined
      ) {
        subCategory = postData.categories[1].name;
      } else {
        subCategory = primaryCategory;
      }

      let subSubCategory = '';
      if (
        postData.categories &&
        postData.categories.length > 0 &&
        postData.categories[2] !== undefined
      ) {
        subSubCategory = postData.categories[2].name;
      } else {
        subSubCategory = subCategory;
      }

      let tagString = '';
      tagString = postData.tags.map(tag => tag.name).join(',');
      const pubdate = postData.formattedDate ? `${postData.formattedDate}` : '';

      let pageType = 'article';
      if (get(postData, 'posterSlideshow.length')) {
        pageType = 'slideshow';
      } else if (
        get(postData, 'hasVideoPoster') === true ||
        get(postData, 'posterVideo.feedId')
      ) {
        pageType = 'video';
      }

      const urlText = postData.slug.replace(/-/g, ' ');

      const paragraphCount = get(postData, 'totalParagraphs', '');

      const mobileInsertionAdCount =
        get(postData, 'content', []).length > 1
          ? get(postData, 'content', []).length - 1
          : 0;

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName,
            pageID: `fc${postData.id}`,
            destinationURL: `${destinationURL}`,
            referringURL: '',
            author,
            editorName: 'FC Editor',
            pubdate,
            daysSincePubdate: getDaysSincePubdate(pubdate),
            type: pageType,
            publisher: 'fast company',
            server: 'fc-node',
            urlText,
            editTags: tagString,
            infiniteScrollIndex: pageNumber,
            paragraphCount,
            mobileInsertionAdCount,
            fbPixelID: '1389601884702365',
            premium: isPremium(get(postData, 'tags', []))
          },
          category: {
            primaryCategory: `${primaryCategory}`,
            subCategory1: `${subCategory}`,
            subCategory2: `${subSubCategory}`
          }
        }
      });
    },
    category() {
      const category = get(categoryPageData, 'slug', 'news');
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${categoryPageData.name} | ${categoryPageData.taxonomy}`,
            destinationURL: `${get(siteConfig, 'url')}/${category}`,
            type: 'category page',
            contentType: 'lander',
            editTags: `${category}`,
            pageID: `fc-${category}-${categoryPageData.id}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: `${category}`,
            subCategory1: `${category}`,
            subCategory2: `${category}`
          }
        }
      });
    },
    tag() {
      const tagId = get(tagPageData, 'id', '99999');
      const tag = get(tagPageData, 'slug', 'tag');
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${tag} | tag`,
            destinationURL: `${get(siteConfig, 'url')}/section/${tag}`,
            type: 'section',
            contentType: 'collection',
            editTags: `${tag}`,
            pageID: `fc-tag-${tag}-${tagId}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: `${tag}`,
            subCategory1: `${tag}`,
            subCategory2: `${tag}`
          }
        }
      });
    },
    author() {
      const authorSlug = get(authorPageData, 'slug', 'fastcompany-staff');
      const authorName = get(authorPageData, 'name', 'Fastcompany Staff');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${authorName} | author`,
            author: authorName,
            destinationURL: `${get(siteConfig, 'url')}/user/${authorSlug}`,
            pageID: `fc-author-${authorPageData.id}`,
            contentType: 'lander',
            type: 'author page',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'author',
            subCategory1: authorName,
            subCategory2: authorName
          }
        }
      });
    },
    search() {
      const searchQuery = get(searchPageData, 'query.queryString', '');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${searchQuery} | search`,
            destinationURL: `${get(siteConfig, 'url')}/search`,
            pageID: `fc-search-${searchQuery}`,
            editTags: `${searchQuery},search`,
            type: 'search page',
            contentType: 'lander'
          },
          category: {
            primaryCategory: 'search',
            subCategory1: searchQuery,
            subCategory2: 'lander'
          }
        }
      });
    },
    page() {
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${get(staticPageData, 'slug')} | page`,
            destinationURL: `${get(siteConfig, 'url')}/${get(
              staticPageData,
              'slug'
            )}`,
            type: 'page',
            fbPixelID: '1389601884702365'
          }
        }
      });
    },
    videoPage() {
      const currentVideoData = get(videoPageData, 'data.playlist[0]', null);
      if (!currentVideoData) {
        return {};
      }

      // const videoTag = get(currentVideoData, 'category');
      const postID = get(currentVideoData, 'id');

      let tagString = '';
      tagString = currentVideoData.tags.map(tag => tag.name).join(',');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: get(currentVideoData, 'title'),
            author: `fast company video`,
            editorName: 'FC Video',
            destinationURL: get(currentVideoData, 'link'),
            pubdate: get(currentVideoData, 'formattedDate'),
            daysSincePubdate: getDaysSincePubdate(
              get(currentVideoData, 'formattedDate')
            ),
            contentType: 'video',
            type: 'video_page',
            editTags: tagString,
            pageID: `fc-jw${postID}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: currentVideoData.series,
            subCategory2: currentVideoData.category.toLowerCase()
          }
        }
      });
    },
    micYearPage() {
      const year = get(micYearPageData, 'year', '');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `mic ${year} | lander` : `mic default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/most-innovative-companies/${year}`
              : `${get(siteConfig, 'url')}/most-innovative-companies`,
            editTags: `${year},mic,most innovative companies`,
            contentType: 'lander',
            type: 'mic page',
            pageID: `fc-mic-${year}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'year'
          }
        }
      });
    },
    ibdPage() {
      // i.e. http://fastcompany.local:8880/innovation-by-design/2018
      const year = get(ibdPageData, 'year', '');
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `ibd ${year} | lander` : `ibd default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/innovation-by-design/${year}`
              : `${get(siteConfig, 'url')}/innovation-by-design`,
            editTags: `${year},ibd,innovation by design`,
            pageID: `fc-ibd-${year}`,
            type: 'ibd year page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'awards',
            subCategory1: 'innovation by design',
            subCategory2: 'lander'
          }
        }
      });
    },
    bestWorkplacesPage() {
      // const year = get(bestWorkplacesPageData, 'year', '');
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: 'Best Workplaces for Innovators 2021',
            destinationURL: `${get(
              siteConfig,
              'url'
            )}/best-workplaces-for-innovators/2021`,
            editTags: `2021,bwi,best workplaces for innovators`,
            contentType: 'lander',
            type: 'bwi page',
            pageID: `fc-bwi-2021`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'best workplaces for innovators',
            subCategory2: '2021'
          }
        }
      });
    },
    mostCreativePeoplePage() {
      const year = get(mostCreativePeoplePageData, 'year', '');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: year ? `mcp ${year} | lander` : `mcp default | lander`,
            destinationURL: year
              ? `${get(siteConfig, 'url')}/most-creative-people/${year}`
              : `${get(siteConfig, 'url')}/most-creative-people`,
            editTags: `${year},mcp,most creative people`,
            contentType: 'lander',
            type: 'mcp page',
            pageID: `fc-mcp-${year}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most creative people',
            subCategory2: 'year'
          }
        }
      });
    },
    personPage() {
      const personSlug = get(personPageData, 'data.rows[0].url', '');
      const personName = `${get(
        personPageData,
        'data.rows[0].first_name',
        ''
      )} ${get(personPageData, 'data.rows[0].last_name', '')}`;
      const personCompanies = get(personPageData, 'data.rows[0].companies', '');
      const personId = get(personPageData, 'data.rows[0].id', '');

      let tagString = '';
      tagString = personCompanies.map(company => company.name).join(',');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `${personName} | person`,
            contentType: 'profile',
            destinationURL: `${get(siteConfig, 'url')}/person/${personSlug}`,
            type: 'person',
            editTags: `${personName},${tagString},mcp,most creative people`,
            pageID: `fc-person-${personId}`
          },
          category: {
            primaryCategory: 'awards',
            subCategory1: 'most creative people',
            subCategory2: 'person'
          }
        }
      });
    },
    companyPage() {
      const companyName = get(companyPageData, 'data.tags[0].name', '');
      const companySlug = get(companyPageData, 'data.tags[0].slug', '');
      const companyId = get(companyPageData, 'data.tags[0].id', '');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            destinationURL: `${get(siteConfig, 'url')}/company/${companySlug}`,
            pageName: `${companyName} | company`,
            pageID: `fc-company-${companyId}`,
            contentType: 'company profile',
            type: 'company page',
            pubdate: '2019-02-20',
            daysSincePubdate: getDaysSincePubdate('2019-02-20'),
            editTags: `${companyName},mic,most innovative companies`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'company'
          }
        }
      });
    },
    micSectorPage() {
      const year = get(micSectorPageData, 'year', '');
      const sector = get(micSectorPageData, 'sector', '');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageName: `Most Innovative Companies ${year} ${sector}`,
            destinationURL: `${get(
              siteConfig,
              'url'
            )}/most-innovative-companies/${year}/${sector}`,
            editTags: `${sector},${year},mic,most innovative companies`,
            contentType: 'lander',
            type: 'mic sector page',
            pageID: `fc-mic-${sector}`,
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'award',
            subCategory1: 'most innovative companies',
            subCategory2: 'sector'
          }
        }
      });
    },
    playlistAllFeed() {
      // i.e. http://fastcompany.local:8880/videos/playlist/trending/jVdHZUiT/1
      // TODO: There's a bug in the original code where if you start at url above,
      //  goto a video page, then use the back button to go back to the lander,
      //  the page type (and object) is changed, and no longer contains certain data

      const feedid = get(playlistAllFeedData, 'feedid');
      const name = get(playlistAllFeedData, 'kind').toLowerCase();
      const slug = get(playlistAllFeedData, 'slug');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-video-playlist-${feedid}`,
            pageName: `${name} | video playlist`,
            destinationURL: `https://www.fastcompany.com/videos/playlist/${slug}/${feedid}`,
            type: 'video playlist',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: name,
            subCategory2: 'playlist'
          }
        }
      });
    },
    videoPlaylistPage() {
      // i.e. http://fastcompany.local:8880/videos/playlist/trending/jVdHZUiT/1
      const feedid = get(playlistAllFeedData, 'data.feedid');
      const name = get(playlistAllFeedData, 'data.kind').toLowerCase();
      const slug = get(playlistAllFeedData, 'data.slug');

      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-video-playlist-${feedid}`,
            pageName: `${name} | video playlist`,
            destinationURL: `https://www.fastcompany.com/videos/playlist/${slug}/${feedid}`,
            type: 'video playlist',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: name,
            subCategory2: 'playlist'
          }
        }
      });
    },
    videoIndexPage() {
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-video-lander`,
            pageName: `Video Landing Page`,
            destinationURL: `${get(siteConfig, 'url')}/videos`,
            type: 'video page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'video',
            subCategory1: 'video',
            subCategory2: 'lander'
          }
        }
      });
    },
    sitemapIndexPage() {
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-sitemap-lander`,
            pageName: `Site Map Index Page`,
            destinationURL: `${get(siteConfig, 'url')}/sitemap`,
            type: 'sitemap page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'sitemap',
            subCategory1: 'sitemap',
            subCategory2: 'lander'
          }
        }
      });
    },
    sitemapPostPage() {
      return extend({}, dataLayerMapping, {
        page: {
          pageInfo: {
            pageID: `fc-sitemap-post`,
            pageName: `Site Map Post Page`,
            destinationURL: `${get(siteConfig, 'url')}/sitemap`,
            type: 'sitemap post page',
            contentType: 'lander',
            fbPixelID: '1389601884702365'
          },
          category: {
            primaryCategory: 'sitemap',
            subCategory1: 'sitemap',
            subCategory2: 'lander'
          }
        }
      });
    }
  };

  if (typeLookup[type]) {
    // Build new data object to merge with current data layer state
    dataLayerMapping = typeLookup[type]();
  }

  // Handle 404 pages
  if (statusCode && statusCode === 404) {
    dataLayerMapping = extend({}, dataLayerMapping, {
      page: {
        pageInfo: {
          pageName: `status-${statusCode}`,
          type: 'status',
          destinationURL: get(
            navHistory,
            `[${navHistory.length - 1}]`,
            'unknown'
          ),
          contentType: 'lander',
          editTags: `${statusCode}`,
          pageID: `fc-${statusCode}`,
          fbPixelID: '1389601884702365'
        },
        category: {
          primaryCategory: '404',
          subCategory1: '404',
          subCategory2: '404'
        }
      }
    });
  }

  // Handle 500 pages
  if (requestStatusCode && requestStatusCode === 500) {
    dataLayerMapping = extend({}, dataLayerMapping, {
      page: {
        pageInfo: {
          pageName: `status-${requestStatusCode}`,
          type: 'status',
          destinationURL: get(
            navHistory,
            `[${navHistory.length - 1}]`,
            'unknown'
          ),
          contentType: 'lander',
          editTags: `${requestStatusCode}`,
          pageID: `fc-${requestStatusCode}`,
          fbPixelID: '1389601884702365'
        },
        category: {
          primaryCategory: '500',
          subCategory1: '500',
          subCategory2: '500'
        }
      }
    });
  }
  // === ads === //
  createAdDataModel(context);

  // Merge new data with current data layer
  return merge(window.digitalData, dataLayerMapping);
}
