import * as scrollingModuleActions from '../../action_creators/homepage/scrolling_module_action_creators';
import ScrollingModuleModel from '../../../models/homepage/scrolling_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(scrollingModuleActions.scrollingModuleLoading({}, store));

    const state = store.getState();
    const { abtest } = state.config;

    return ScrollingModuleModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(scrollingModuleActions.scrollingModuleLoaded(data, store));
      })
      .catch(function handleUserError(err) {
        dispatch(scrollingModuleActions.scrollingModuleLoadError(err, store));
        log.error(
          err,
          `Error in scrollingModule actions, message: ${err.message}`
        );
      });
  };
}
