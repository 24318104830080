// import { canUseDOM } from 'exenv';

export function homepageTagLoading(resp = {}, store = {}) {
  return {
    type: 'HOMEPAGE_TAG_LOADING',
    homepageTag: resp,
    store
  };
}

export function homepageTagLoaded(resp = {}, store) {
  return {
    type: 'HOMEPAGE_TAG_LOADED',
    homepageTag: {
      posts: resp
    },
    store
  };
}

export function homepageTagLoadError(error, store) {
  return {
    type: 'HOMEPAGE_TAG_ERROR',
    homepageTag: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
