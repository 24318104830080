import get from 'lodash/get';

export function homepageFeatureTermLoading(resp = {}, store = {}) {
  return {
    type: 'HOMEPAGE_FEATURE_TERM_LOADING',
    homepageFeatureTerm: resp,
    store
  };
}

export function homepageFeatureTermLoaded(resp = {}, store) {
  return {
    type: 'HOMEPAGE_FEATURE_TERM_LOADED',
    homepageFeatureTerm: {
      name: get(resp, 'tags[0].name'),
      slug: get(resp, 'tags[0].slug'),
      posts: get(resp, 'posts', [])
    },
    store
  };
}

export function homepageFeatureTermLoadError(error, store) {
  return {
    type: 'HOMEPAGE_FEATURE_TERM_ERROR',
    homepageFeatureTerm: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
