import get from 'lodash/get';

export default function loadChartbeatTracker(currentState) {
  const { context } = currentState;

  let sections = '';
  let author = '';
  let title = '';
  if (currentState.context.type === 'post') {
    sections = get(context, 'postData.categories[0].name', '');
    author = get(context, 'postData.author.name', '');
    title = get(context, 'postData.title', '');
  } else if (context.type === 'homepage') {
    title = 'Homepage';
  } else if (context.type === 'micYearPage') {
    sections = 'Mic Year Page';
    title = get(context, 'micYearPageData.year', '');
  } else if (context.type === 'micSectorPage') {
    sections = 'Mic Sector Page';
    title = get(context, 'micYearPageData.year', '');
  } else if (context.type === 'videoIndexPage') {
    title = 'Video Index Page';
  } else if (context.type === 'videoPage') {
    sections = 'Video';
    title = get(context, 'videoPageData.data.title', '');
  } else if (context.type === 'category') {
    sections = 'Category';
    title = get(context, 'categoryPageData.data.title', '');
  }

  title = title.replace("'", '');
  author = author.replace("'", '');
  sections = sections.replace("'", '');

  /* eslint-disable */
  _sf_async_config.sections = sections;
  _sf_async_config.authors = author;
  _sf_async_config.title = title;
  /* eslint-enable */
  if (typeof window.pSUPERFLY !== 'undefined') {
    window.pSUPERFLY.virtualPage(window.location.href);
  }
}
