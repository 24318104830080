import extend from 'lodash/extend';
import get from 'lodash/get';

export function mic2020SectorsLoading(resp = {}, store = {}) {
  return {
    type: 'MIC_2020_SECTORS_LOADING',
    micSectors: resp,
    store
  };
}

export function mic2020SectorsLoaded(resp = {}, store = {}, year, sector) {
  extend(resp, { year });
  return {
    type: 'MIC_2020_SECTORS_LOADED',
    micSectors: get(resp, 'posts', []),
    store,
    year,
    sector
  };
}

export function mic2020SectorsLoadError(error, store = {}) {
  return {
    type: 'MIC_2020_SECTORS_ERROR',
    micSectors: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
