const tagMap = [
  'a-green-new-deal-for-business',
  '2020-election',
  '30-second-mba',
  '25th-anniversary',
  'a-leap-forward',
  'abortion-access',
  'accelerator',
  'ad-of-the-day',
  'adops',
  'afghanistan',
  'agendas',
  'all-stars',
  'almost-genius',
  'ambition-diaries',
  'analyzed',
  'app-economy',
  'apple-event',
  'asides',
  'ask-the-experts',
  'awful-or-awesome',
  'badvocacy',
  'bananas',
  'behind-the-brand',
  'beside-great-leaders',
  'big-bang-design',
  'biomimicry',
  'big-ideas-for-the-first-100-days',
  'biz-of-youtube',
  'black-in-tech',
  'body-os',
  'body-week',
  'bottom-line',
  'brand-evolution',
  'breaking-the-status-quo',
  'breakthrough-capitalism',
  'brilliant-minds',
  'bugaboo-boxer',
  'build-a-business',
  'builders-and-innovators',
  'building-back-greener',
  'business-of-tech',
  'business-simplified',
  'butterfly-effect',
  'buyology',
  'cannes',
  'career-evolution',
  'ceelo-most-creative-videos',
  'change-agents',
  'change-generation',
  'climate-change-survival-plan',
  'closer-to-next',
  'co-creators',
  'code-art',
  'co-location',
  'coexists-2015-in-review',
  'coexists-2016-in-review',
  'coffee-week',
  'colabs-classes',
  'connected-world',
  'conquering-the-cloud',
  'conquering-the-cloud-test',
  'coronavirus',
  'queer-50',
  'content-and-pervasive-creativity',
  'creating-happiness',
  'creation-stories',
  'creative-braintrust',
  'creative-braintrust-test',
  'creative-calendar',
  'creative-control',
  'creative-conversations',
  'creative-counselor',
  'creative-cultures',
  'creative-defiance',
  'creative-destination',
  'creativity-by-the-numbers',
  'creator25',
  'creators-profile',
  'davos-dialogues',
  'day-on-day-off',
  'dear-founder',
  'defining-moments-visualized',
  'design-50',
  'design-award',
  'design-crime',
  'design-for-living',
  'design-moves',
  'designed-for-success',
  'designing-women',
  'destination-design',
  'destination-entrepreneurs',
  'destinations-uncovered',
  'dialed',
  'difficult-conversations',
  'digital-hollywood',
  'digital-trailblazers',
  'dispatch-from',
  'do-more',
  'doubting-the-dose',
  'drive-your-business-forward',
  'driving-innovation',
  'drone-vs',
  'dynamic-dialogues',
  'elasticity',
  'election-2016',
  'electric-avenue',
  'elevator-pitch',
  'engineering-creativity',
  'equity-at-work',
  'ethonomic-indicator',
  'events',
  'evidence',
  'exclusive',
  'exclusive-video',
  'experiments',
  'exposure',
  'faces-of-founders',
  'fashion-forward',
  'fast-cities',
  'fast-company-live',
  'fast-feed',
  'fast-forward',
  'fast-forward-workday',
  'fast-government',
  'fast-take',
  'fast-talk',
  'fastcoworks',
  'feeding-the-future',
  'financing-the-future',
  'find-your-values',
  'first-look',
  'food-week',
  'fund-this',
  'future-forward',
  'future-of-health',
  'future-of-philanthropy',
  'future-of-retail',
  'future-proof',
  'futurist-forum',
  'generation-flux',
  'generosity-series',
  'genius-marketing',
  'getting-funded',
  'gif-of-the-day',
  'gift-guide',
  'gigged',
  'good-design-is-good-business',
  'greatest-geek-moment-in-history',
  'growth-hacking',
  'growth-notes',
  'hackers-we-love',
  'hacking-democracy',
  'healthware',
  'help',
  'heres-an-idea',
  'hit-the-ground-running',
  'holiday-gift-guide',
  'work-in-2040',
  'home-bound',
  'homebrew-news',
  'hotel-revolution',
  'how-i-get-it-done',
  'how-healthy-is-the-future-of-work',
  'how-to-be-a-success-at-everything',
  'how-to-build-the-perfect-city',
  'hows-that-working-out-for-you',
  'hr',
  'ifive',
  'ignite-positive-change',
  'image-of-the-day',
  'industrial-internet',
  'industries-to-watch',
  'industry-pov',
  'influence-project',
  'influencer-collective',
  'infographic-of-the-day',
  'innovation360',
  'innovation-agents',
  'innovation-by-design',
  'innovation-by-design-2014',
  'innovation-by-design-awards',
  'innovation-by-design-awards-2014',
  'innovation-by-design-awards-2015',
  'innovation-by-design-awards-entries-2014',
  'innovation-by-design-awards-entries-2015',
  'innovation-by-design-conference',
  'impact-council',
  'innovation-enabled',
  'innovation-engine',
  'innovation-festival-360',
  'innovation-nation',
  'innovation-pipeline',
  'innovation-uncensored-sf-2014',
  'inside-smart-cities',
  'experience-economy',
  'insider-insight',
  'inspiration-and-impact',
  'inspired-ethonomics',
  'internet-of-things',
  'isle-of-innovation',
  'its-good-not-to-be-home',
  'know-it-all',
  'leadership-now',
  'league-of-extraordinary-women-2012',
  'lessons-learned',
  'let-it-fly',
  'life-in-beta',
  'life-in-the-gig-economy',
  'living-smarter',
  'location-location-location',
  'lorem-ipsum',
  'made-to-stick',
  'make-a-name',
  'make-your-move',
  'making-it',
  'making-your-workplace-work',
  'maps',
  'mark-bittman',
  'marketing-innovation',
  'master-class',
  'masters-of-design',
  'masters-of-design-2005',
  'masters-of-design-2006',
  'masters-of-design-2007',
  'masters-of-design-2008',
  'masters-of-design-2009',
  'masters-of-design-2010',
  'masters-of-design-2011',
  'masters-of-marketing',
  'meme',
  'mind-and-machine',
  'mental-health-at-work',
  'mobilizing',
  'most-creative-people',
  'mindfulness-at-work',
  'misinformation-nation',
  'most-creative-people-2009',
  'most-creative-people-2010',
  'most-creative-people-2011',
  'most-creative-people-2012',
  'most-creative-people-2013',
  'most-creative-people-2014',
  'most-creative-people-2015',
  'most-innovative-companies',
  'most-innovative-moments',
  'moving-the-needle',
  'my-creative-life',
  'my-way',
  'new-heights',
  'new-money',
  'next-design',
  'next-gen-design',
  'objet-dinternet',
  'olympics-2012',
  'one-more-thing',
  'open-company',
  'out-of-office-with',
  'passion-to-profit',
  'pet-week',
  'platform-wars',
  'plugged-in',
  'politechs',
  'pov',
  'power-couples',
  'power-of-ai',
  'powering-the-future',
  'productivity-in-motion',
  'progress-is-possible',
  'project-fusion',
  'qa',
  'quick-hit',
  'reading-list',
  'rebranding-pot',
  'reinventing-music',
  'reinventing-education',
  'retrology',
  'restaurant-diaries',
  'reverse-engineered',
  'revision-history',
  'revolutionizing-work',
  'rhone-technology',
  'risks-visualized',
  'robot-revolution',
  'running-lean',
  'second-shift',
  'secrets-of-the-most-productive-people',
  'sector-forecasting',
  'seth-bodnar-ge',
  'show-up-differently',
  'side-project',
  'signs-and-symbols',
  'silicon-valley-social-entrepreneurs',
  'simple-genius',
  'sleep-week',
  'slicker-city',
  'small-to-fast',
  'smart-cities',
  'solutions-initiative',
  'solving-inequality',
  'spark',
  'startup-report',
  'storytellers',
  'strong-female-lead',
  'style-revolution',
  'super-bowl-50',
  'super-bowl-li',
  'super-bowl-xlix',
  'super-bowl-xlvi',
  'super-bowl-xlvii',
  'super-bowl-xlviii',
  'superlatives-week',
  'supertag-test-multiple-sites',
  'lessons-of-covid',
  'lessons-of-covid-19',
  'sxsw',
  'sxsw-2016',
  'tech-edge',
  'tech-forecast',
  'technovore',
  'techsocial',
  'terminal-velocity',
  'the-29th-floor',
  'the-big-idea',
  'the-big-story',
  'the-code-war',
  'the-creator-economy',
  'the-shape-of-tomorrow',
  'the-fast-company-innovation-festival',
  'fast-company-innovation-festival',
  'the-future-of-collaboration',
  'the-future-of-everything',
  'the-future-of-policing',
  'the-future-of-service-in-america',
  'the-future-of-work',
  'the-intern-economy',
  'the-legacy-of-1995',
  'the-misfit-economy',
  'the-new-magic',
  'the-new-rules-of-work',
  'the-new-rules-of-ai',
  'new-rules-of-ai',
  'the-new-business-of-growing-old',
  'the-new-business-of-food',
  'the-new-capitalism',
  'the-future-of-kids-entertainment',
  'the-other-side',
  'the-pivot',
  'the-purposeful-ceo',
  'the-rebuilders',
  'the-recommender',
  'the-road-ahead',
  'the-rules-of-social-media',
  'the-science-of-work',
  'the-sensor-revolution',
  'the-thread',
  'the-ultimate-urban-utility-bike',
  'the-valley',
  'the-vow',
  'the-walk-through',
  'the-y-combinator-chronicles',
  'the-year-in-post-truth',
  'then-and-now',
  'tim-cooks-apple',
  'today-in-tabs',
  'top-5-ads',
  'top-jobs',
  'tracking',
  'travel-smarter',
  'tribeca-film-festival',
  'united-states-of-innovation',
  'unplug',
  'unreasonables',
  'upswing',
  'us-innovation',
  'usa-can-this-brand-be-saved',
  'visualized',
  'visualizing',
  'visualizing-success',
  'wanderlust',
  'wanted',
  'wearable-computing',
  'wearables-week',
  'wears',
  'weekly-top-5',
  'well-to-do',
  'where-are-they-now',
  'whos-next',
  'why-here',
  'why-youre-fat',
  'women-heroes',
  'women-in-tech-2009',
  'women-in-tech-2010',
  'women-in-tech-2011',
  'women-to-watch',
  'work-mode',
  'work-smart',
  'working-while-female',
  'workplace-evolution',
  'world-changing-ideas',
  'world-changing-ideas-2015',
  'worth-the-risk',
  'wwdc',
  'your-most-productive-self'
];

export default tagMap;
