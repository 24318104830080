import { canUseDOM } from 'exenv';
import set from 'lodash/set';
import asyncCategoryPageAction from '../../../../redux/actions/category/async_category_page_actions';
import log from '../../../../services/logger_service';

export default function fetchDataWithStore(store, override, isTag) {
  return function fetchData(nextState, replace, callback) {
    const { dispatch } = store;

    if (override) {
      set(nextState, 'params.category', override);
    }

    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        if (callback) {
          return callback();
        }
        return false;
      }
      asyncCategoryPageAction(nextState.params, store, isTag)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return asyncCategoryPageAction(nextState.params, store, isTag)(dispatch)
      .then(function cb() {
        if (callback) {
          return callback();
        }
        return false;
      })
      .catch(function handleActionError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback(error);
        }
        return false;
      });
  };
}
