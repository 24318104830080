import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let PageComponent = false;
let ModernPageComponent = false;
let Bwi2023PageComponent = false;
let NewPageComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  PageComponent = require('./best_workplaces_page').default;
  // eslint-disable-next-line global-require
  ModernPageComponent = require('./best_workplaces_page_modern').default;
  // eslint-disable-next-line global-require
  Bwi2023PageComponent = require('./best_workplaces_page_2023').default;
  // eslint-disable-next-line global-require
  NewPageComponent = require('./best_workplaces_page_2024').default;
}

export function defaultBwiPage(store) {
  const routeConfig = {
    path: '/best-workplaces-for-innovators/:year(2019|2020|2021)',
    component() {
      if (PageComponent) {
        return <PageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: PageComponent ? PageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./best_workplaces_page').then(resp => {
        PageComponent = resp.default;
        routeConfig.loadData = PageComponent.loadData(store);

        return PageComponent;
      });
    },
    chunk: 'defaultBwiPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}

export function modernBwiPage(store) {
  const routeConfig = {
    path: '/best-workplaces-for-innovators/:page(2022)',
    component() {
      if (ModernPageComponent) {
        return <ModernPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: ModernPageComponent ? ModernPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./best_workplaces_page_modern').then(resp => {
        ModernPageComponent = resp.default;
        routeConfig.loadData = ModernPageComponent.loadData(store);

        return ModernPageComponent;
      });
    },
    chunk: 'modernBwiPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
export function bwi2023Page(store) {
  const routeConfig = {
    path: '/best-workplaces-for-innovators/:page(2023)/:rank?',
    component() {
      if (Bwi2023PageComponent) {
        return <Bwi2023PageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: Bwi2023PageComponent
      ? Bwi2023PageComponent.loadData(store)
      : false,
    preloadChunk() {
      return import('./best_workplaces_page_2023').then(resp => {
        Bwi2023PageComponent = resp.default;
        routeConfig.loadData = Bwi2023PageComponent.loadData(store);

        return Bwi2023PageComponent;
      });
    },
    chunk: 'bwi2023Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
export function bwiListPage(store) {
  const routeConfig = {
    path: '/best-workplaces-for-innovators/:page(list)/:rank?',
    component() {
      if (NewPageComponent) {
        return <NewPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: NewPageComponent ? NewPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./best_workplaces_page_2024').then(resp => {
        NewPageComponent = resp.default;
        routeConfig.loadData = NewPageComponent.loadData(store);

        return NewPageComponent;
      });
    },
    chunk: 'bwi2024Page'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
