// import { canUseDOM } from 'exenv';

export function scrollingModuleLoading(resp = {}, store = {}) {
  return {
    type: 'SCROLLING_MODULE_LOADING',
    scrollingModule: resp,
    store
  };
}

export function scrollingModuleLoaded(resp = {}, store = {}) {
  return {
    type: 'SCROLLING_MODULE_LOADED',
    scrollingModule: {
      posts: resp.posts,
      tag: resp.tag
    },
    store
  };
}

export function scrollingModuleLoadError(error, store = {}) {
  return {
    type: 'SCROLLING_MODULE_ERROR',
    scrollingModule: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
