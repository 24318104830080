import homepagePageStateManager from './homepage_state_manager';
import loadable from '@loadable/component';

const HomePage = loadable(() => import('./index_page'));

export default function(store) {
  return {
    path: '/',
    component: HomePage,
    exact: true,
    strict: true,
    loadData: homepagePageStateManager(store)
  };
}
