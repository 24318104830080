import * as franchisePageActions from '../../action_creators/franchise/franchise_page_data_action_creators';
import log from '../../../services/logger_service';
import asyncRecircFeedActions from '../async_recirc_feed_actions';
import get from 'lodash/get';
import FranchisePageModel from '../../../models/franchise/franchise_page_model';
import axios from 'axios';
import https from 'https';

const tempoUrl = 'https://tempo.inc.com';
const sourceMap = {
  'next-big-things-in-tech/2021': `${tempoUrl}/nextbusiness/final`,
  'q50-2022': `${tempoUrl}/q502022/final`,
  'q50-2023': `${tempoUrl}/q50-2023/final`,
  'q50-2024': `${tempoUrl}/q50-2024/final`,
  'mcp-2022': `${tempoUrl}/mcp-2022/final`,
  'mcp-2023': `${tempoUrl}/mcp-2023/final`,
  'bwi-2022': `${tempoUrl}/bwi-2022/final`,
  'bwi-2023': `${tempoUrl}/bwi-2023/final`,
  'ibd-2022-2': `${tempoUrl}/ibd-2022/final`,
  'ibd-2023': `${tempoUrl}/ibd-2023/final`,
  'btm-2023': `${tempoUrl}/btm-2023/final`,
  creator25:
    'https://assets.fastcompany.com/multisite_files/fastcompany/creator-25/2022_c25_records.json',
  'next-big-things-in-tech/list': `${tempoUrl}/nbtt-2023/final`,
  'next-big-things-in-tech/2022': `${tempoUrl}/nbtt-2022/final`,
  'ibd-2024': `${tempoUrl}/ibd-2024/final`,
  'bwi-2024': `${tempoUrl}/bwi-2024/final`
};

function getExternalList(params) {
  const { slug } = params;
  const httpsAgent = new https.Agent({
    rejectUnauthorized: false
  });
  const url = sourceMap[slug];

  return url !== undefined
    ? axios.get(url, {
        httpsAgent
      })
    : Promise.resolve(null);
}

function fetchFranchisePageData(params, store) {
  return FranchisePageModel.fetch(params, store)
    .then(function handleFranchisePageData(FranchisePageData) {
      return FranchisePageData;
    })
    .catch(function handleUserError(err) {
      if (err.message === 'Invalid Year') {
        log.warn('User tried a World Changing Ideas page with invalid year.');
        return false;
      }
      log.warn('Something went wrong loading World Changing Ideas.');
      return false;
    });
}

function getFranchiseCategory(params) {
  const { slug } = params;
  switch (slug) {
    case 'q50-2022':
      return 'queer-50';

    default:
      return slug;
  }
}

function formatFranchiseSlug(params) {
  const { slug } = params;
  switch (slug) {
    case 'next-big-things-in-tech/list':
      return 'next-big-things-in-tech-list';

    default:
      return slug;
  }
}
export default function fetchFranchisePage(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(franchisePageActions.franchisePageLoading({}, store));

    const asyncActions = [
      fetchFranchisePageData(params, store),
      asyncRecircFeedActions(params, store)(dispatch),
      getExternalList(params, store)
    ];

    return Promise.all(asyncActions)
      .then(function handlePostDetailData(data) {
        const pageData = get(data, '[0].data', {});
        const pageType = get(pageData, 'type', '')
          ? `${get(pageData, 'type', '')}-${get(pageData, 'slug', '')}`
          : formatFranchiseSlug(params);
        pageData.externalList = get(data, '[2].data', null);
        pageData.franchiseCategory = getFranchiseCategory(params);
        pageData.year = /\b\d{4}\b/.test(get(pageData, 'slug', ''));
        dispatch(
          franchisePageActions.franchisePageLoaded(pageData, store, pageType)
        );
        return pageData;
      })
      .catch(function handleUserError(err) {
        dispatch(franchisePageActions.franchisePageLoadError(err, store));
        return log.error(
          err,
          `Error in Franchise page actions, message: ${err.message}`
        );
      });
  };
}
