import loadable from '@loadable/component';

const NewsletterPage = loadable(() => import('./newsletters_page_marketo'));

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./newsletters_page_marketo').default;
}

export default function(store) {
  const routeConfig = {
    path: '/newsletters',
    component: NewsletterPage,
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    chunk: 'newslettersPage'
  };

  return routeConfig;
}
