import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let ListPageComponent = false;
let ListPageComponentLegacy = false;
let ListPageComponent2023 = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  ListPageComponent = require('./world_changing_ideas_list_page').default;
  // eslint-disable-next-line global-require
  ListPageComponent2023 = require('./world_changing_ideas_list_page_2023')
    .default;
  // eslint-disable-next-line global-require
  ListPageComponentLegacy = require('./world_changing_ideas_list_page_legacy')
    .default;
}
/**
 * List Lander Page
 */
export function wciListPage(store) {
  const routeConfig = {
    path: '/world-changing-ideas/:page(list)',
    component() {
      if (ListPageComponent) {
        return <ListPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: ListPageComponent ? ListPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./world_changing_ideas_list_page').then(resp => {
        ListPageComponent = resp.default;
        routeConfig.loadData = ListPageComponent.loadData(store);

        return ListPageComponent;
      });
    },
    chunk: 'wciListPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

/**
 * List Lander Page old
 */

export function wciListPage2023(store) {
  const routeConfig = {
    path: '/world-changing-ideas/:page(2023)',
    component() {
      if (ListPageComponent2023) {
        return <ListPageComponent2023 />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: ListPageComponent2023
      ? ListPageComponent2023.loadData(store)
      : false,
    preloadChunk() {
      return import('./world_changing_ideas_list_page_2023').then(resp => {
        ListPageComponent2023 = resp.default;
        routeConfig.loadData = ListPageComponent2023.loadData(store);

        return ListPageComponent2023;
      });
    },
    chunk: 'wciListPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}

export function wciListPageLegacy(store) {
  const routeConfig = {
    path: '/world-changing-ideas/:page(2022)',
    component() {
      if (ListPageComponentLegacy) {
        return <ListPageComponentLegacy />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: ListPageComponentLegacy
      ? ListPageComponentLegacy.loadData(store)
      : false,
    preloadChunk() {
      return import('./world_changing_ideas_list_page_legacy').then(resp => {
        ListPageComponentLegacy = resp.default;
        routeConfig.loadData = ListPageComponentLegacy.loadData(store);

        return ListPageComponentLegacy;
      });
    },
    chunk: 'wciListPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
