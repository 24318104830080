import { canUseDOM } from 'exenv';

let serverCache = false;
let clientCache = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  serverCache = require('../server/services/redis_service');
} else {
  // eslint-disable-next-line global-require
  clientCache = require('../client/services/caching_service');
}

const cache = canUseDOM ? clientCache : serverCache;

export default cache.default;
