import get from 'lodash/get';
import uniq from 'lodash/uniq';
import compact from 'lodash/compact';
import each from 'lodash/each';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';
// import { isAdvertorial } from '../../views/components/advertorial/advertorial';

export default function(state = {}) {
  const site = get(state, 'config.site');
  // const url = get(state, 'config.navHistory[0]');
  const siteConf = siteData || {};
  const video = get(state, 'jwVideo.data.playlist[0]', {});
  const title = `VIDEO | ${get(video, 'title', '')}`;
  const id = get(video, 'mediaid');
  const slug = get(video, 'slug');
  const image = `https://images.fastcompany.net/image/fetch/w_1280,f_auto,q_auto,fl_lossy/${get(
    video,
    'image',
    ''
  )}`;
  const date = get(video, 'formattedDate', '');
  const tags = get(video, 'tags', []).map(tag => tag.name);
  const dedupedTags = uniq(compact(tags));
  const description = get(video, 'description', '');

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    url: `https://www.${site}.com/video/${slug}/${id}`,
    description,
    image,
    image_width: 1280,
    image_height: 720,
    type: 'video',
    config: state.config,
    extend: [
      {
        meta: [
          {
            property: 'video:published_time',
            content: date
          }
        ]
      }
    ]
  });

  each(dedupedTags, function addOGMeta(tag) {
    data.meta.push({
      property: 'video:tag',
      content: tag
    });
  });

  /*
  if (isAdvertorial(allTags)) {
    data.meta.push({ name: 'ROBOTS', content: 'NOINDEX,FOLLOW' });
  }
  */

  return data;
}
