import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

const btmList = (state = {}, action = {}) => {
  const siteConf = siteData || {};
  const config = get(state, 'config');
  const site = get(state, 'config.site');
  const btmListPage = get(action, 'brandsThatMatterPage');

  // Set defaults
  const section = 'Brands That Matter';
  const defaultTitle = "Fast Company's Brands That Matter";
  const defaultUrl = 'https://www.fastcompany.com/brands-that-matter/list';
  const defaultDescription =
    "Fast Company's Brands That Matter franchise recognizes companies leading on social action, sustainability, inclusivity, and fun";
  const defaultImage =
    'https://wp-cms-fastcompany-com.s3.amazonaws.com/uploads/2022/10/BTM_Digitalv1_social-share_crop.jpg';
  const defaultFacebookTitle = `${defaultTitle} | Fast Company`;
  const defaultTwitterTitle = `${defaultTitle} | Fast Company`;

  // Set airtable data
  const airtableTitle = btmListPage?.airtableData?.metadata?.PageTitle;
  const airtableUrl = btmListPage?.airtableData?.metadata?.url;
  const airtableDescription = get(
    btmListPage,
    'airtableData.metadata["SEO description"]'
  );
  const airtableImage =
    btmListPage?.airtableData?.metadata?.['featureimage (desktop)'];
  const airtableFacebookTitle = `${airtableTitle} | Fast Company`;
  const airtableTwitterTitle =
    btmListPage?.airtableData?.metadata?.twitterTitle;
  const airtableTwitterDescription =
    btmListPage?.airtableData?.metadata?.twitterDescription;

  // Set wordpress data
  const wpTitle = btmListPage?.wpData?.acf?.title;
  const wpUrl = btmListPage?.wpData?.acf?.publicUrl;
  const wpDescription = btmListPage?.wpData?.acf?.franchise_dek;
  const wpImage = btmListPage?.wpData?.acf?.megatron_image;
  const wpFacebookTitle = `${wpTitle} | Fast Company`;
  const wpTwitterTitle = `${wpTitle} | Fast Company`;

  return baseSeoUtil({
    config,
    site,
    siteConf,
    section,
    title: airtableTitle || wpTitle || defaultTitle,
    url: airtableUrl || wpUrl || defaultUrl,
    description: airtableDescription || wpDescription || defaultDescription,
    image: airtableImage || wpImage || defaultImage,
    facebookTitle:
      airtableFacebookTitle || wpFacebookTitle || defaultFacebookTitle,
    twitterTitle: airtableTwitterTitle || wpTwitterTitle || defaultTwitterTitle,
    twitterDescription: airtableTwitterDescription || airtableDescription
  });
};

export { btmList };
