import * as categoryPromoBannerActions from '../../action_creators/category/category_promo_banner_action_creators';
import CategoryPromoBannerModel from '../../../models/category/category_promo_banner';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store, isTag) {
  return function firstDispatch(dispatch) {
    dispatch(categoryPromoBannerActions.categoryPromoBannerLoading({}, store));

    const state = store.getState();
    const { abtest } = state.config;

    return CategoryPromoBannerModel.fetch(params, store, isTag)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(
          categoryPromoBannerActions.categoryPromoBannerLoaded(data, store)
        );
      })
      .catch(function handleUserError(err) {
        dispatch(
          categoryPromoBannerActions.categoryPromoBannerLoadError(err, store)
        );
        log.error(
          err,
          `Error in categoryPromoBanner actions, message: ${err.message}`
        );
      });
  };
}
