// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    PERSON_LOADING() {
      return {
        isLoading: true
      };
    },
    PERSON_LOADED() {
      return {
        isLoading: false,
        data: action.person
      };
    },
    PERSON_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.person.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
