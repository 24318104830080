import get from 'lodash/get';
import * as innovationByDesignPageActions from '../../action_creators/innovation_by_design/page_action_creators';
import asyncRecircFeedActions from '../async_recirc_feed_actions';
import asyncFranchiseActions from '../franchise/franchise_page_actions';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import log from '../../../services/logger_service';

const CURRENT_YEAR = '2024';

export default function fetchInnovationByDesignPage(params, store) {
  return function firstInnovationByDesignPageDispatch(dispatch) {
    const { rank, year } = params;
    const isRankedListItem = typeof rank !== 'undefined';

    // Begin - set loading state
    dispatch(innovationByDesignPageActions.innovationByDesignPageLoading());

    return Promise.all([
      asyncFranchiseActions(
        {
          slug: `ibd-${year === 'list' ? CURRENT_YEAR : year}`
        },
        store
      )(dispatch),
      asyncRecircFeedActions(params, store)(dispatch)
    ])
      .then(function handleInnovationByDesignPageData(response) {
        const finalResponseData = response[0];
        if (isRankedListItem) {
          finalResponseData.isRankedItem = isRankedListItem;
          finalResponseData.itemRank = rank;
        }
        dispatch(
          innovationByDesignPageActions.innovationByDesignPageLoaded(
            finalResponseData,
            store
          )
        );
        return true;
      })
      .catch(function handleInnovationByDesignPageError(err) {
        const status = get(err, 'response.status') || 500;
        if (status === 404) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        } else {
          dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
          log.error(
            err,
            `Error in Innovation By Design page actions, message: ${
              err.message
            }`
          );
        }
        dispatch(
          innovationByDesignPageActions.innovationByDesignPageLoadError(
            err,
            store
          )
        );
      });
  };
}
