/* eslint-disable semi */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Helmet from 'react-helmet';
import Script from './script';
import Style from './style';
import Noscript from './noscript';

function Head(props) {
  const meta = get(props, 'meta.meta', []);
  const link = get(props, 'meta.link', []);
  const script = get(props, 'meta.script', []);
  const noscript = get(props, 'meta.noscript', []);
  const style = get(props, 'meta.style', []);
  const title = get(props, 'title', get(props, 'meta.title', 'Fast Company'));

  // On initial render Helmet has a bug: https://github.com/nfl/react-helmet/issues/98
  return (
    <Helmet
      prefix="og: https://ogp.me/ns# fb: https://ogp.me/ns/fb# article: https://ogp.me/ns/article#"
      lang="en"
    >
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width,minimum-scale=1,initial-scale=1"
      />
      <title>{title}</title>

      {meta.map(function createMetaTags(conf) {
        return <meta key={JSON.stringify(conf)} {...conf} />;
      })}

      <link rel="dns-prefetch" href={props.staticUrl} />

      {link.map(function createLinkTags(conf) {
        return <link key={JSON.stringify(conf)} {...conf} />;
      })}

      {script.map(function createScriptTags(conf) {
        return <Script key={JSON.stringify(conf)} {...conf} />;
      })}

      {noscript.map(function createNoScriptTags(conf) {
        return <Noscript key={JSON.stringify(conf)} {...conf} />;
      })}

      {style.map(function createStyleTags(conf) {
        return <Style key={JSON.stringify(conf)} {...conf} />;
      })}
    </Helmet>
  );
}

function mapStateToProps(state) {
  return {
    env: state.config.env,
    staticUrl: state.config.staticUrl,
    manifestJSON: state.config.manifestJSON,
    staticVendorUrl: state.config.staticVendorUrl,
    staticBundleUrl: state.config.staticBundleUrl,
    meta: state.meta
  };
}

Head.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  env: PropTypes.string.isRequired,
  staticUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  manifestJSON: PropTypes.shape({
    'vendor.js': PropTypes.string,
    'app.js': PropTypes.string
  }).isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  staticBundleUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  staticVendorUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  meta: PropTypes.shape({}).isRequired
};

export default connect(mapStateToProps)(Head);
