import * as promoterArticleTextActions from '../../action_creators/post/post_promoter_article_text_action_creators';
import promoterArticleTextModel from '../../../models/post/promoter_article_text';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function promoterArticleTextAction(params, store) {
  return function afterbodyRecircModuleTypeDispatch(dispatch) {
    dispatch(promoterArticleTextActions.articleTextLoading(store));

    const state = store.getState();
    const { abtest } = state.config;

    return promoterArticleTextModel
      .fetch(params, store)
      .then(function handleArticleTextData(resp) {
        const { data } = resp;
        if (data) {
          Object.keys(data).forEach(key => {
            if (Array.isArray(data[key])) {
              setAB(data[key], abtest);
            }
          });

          dispatch(promoterArticleTextActions.articleTextLoaded(data, store));
        }
      })
      .catch(function handleArticleTextError(err) {
        dispatch(promoterArticleTextActions.articleTextLoadError(err, store));
        log.error(err, `Error in all feed actions, message: ${err.message}`);
      });
  };
}
