import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import extend from 'lodash/extend';
import authUtil from '../utils/auth_util';
import cachingService from '../services/caching_service';
import request from '../utils/request_util';
import Base from './base_model';
import log from '../services/logger_service';

export default class AllFeed extends Base {
  static fetch(params, store) {
    const state = store.getState();
    const cacheEnabled = get(state, 'config.cacheEnabled');
    const cacheExpire = get(state, 'config.cacheExpire');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const host = get(state, 'config.apiEndpoint') || 'fc-api.fastcompany.com';
    const protocol = get(state, 'config.protocol') || 'https://';
    const options = {};
    let abtest = get(state, 'config.abtest');
    if (abtest) {
      abtest = `?abtest=${abtest}`;
    }
    // api has auth in beta
    if (get(state, 'config.authEnabled')) {
      extend(options, authUtil(state));
    }

    const cacheKeyUrl = `${protocol}${host}/v4/recirc/fastcompany`;

    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}${abtest}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      if (!canUseDOM) {
        log.info(`Requesting: ${cacheKeyUrl} from model.`);
      }
      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
