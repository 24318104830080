// import { canUseDOM } from 'exenv';

export function promoBannerLoading(resp = {}, store = {}) {
  return {
    type: 'PROMO_BANNER_LOADING',
    promoBanner: resp,
    store
  };
}

export function promoBannerLoaded(resp = {}, store = {}) {
  return {
    type: 'PROMO_BANNER_LOADED',
    promoBanner: resp.promoBanner,
    store
  };
}

export function promoBannerLoadError(error, store = {}) {
  return {
    type: 'PROMO_BANNER_ERROR',
    promoBanner: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
