import MindEdge from './mindedge';

export default function() {
  return {
    path: '/courses',
    component: MindEdge,
    exact: true,
    strict: true
  };
}
