/* eslint-disable no-underscore-dangle */
import { get, merge } from 'lodash';
import { getCMSHash } from '../../../../utils/ads/get_cms_hash';
import { getSlug } from '../../../../utils/ads/get_slug';
import { getPriorityTag } from '../../../../utils/ads/get_priority_tag';
import { formatPageContext } from '../../../../utils/ads/format_page_context';
import { canUseDOM } from 'exenv';
import { isPremium } from '../../../../views/components/advertorial/advertorial';

const adPathBase = '/4160/mv.fc';
// list of types to prevent displaying ads
const noAdTypes = [];
let hasInsertedPrimer = false;

function onNewAdModel(pageContext) {
  if (!hasInsertedPrimer) {
    hasInsertedPrimer = true;
    const div = document.createElement('div');
    div.innerHTML = `<div data-ad_slot_type="primer" style="position:absolute;background:transparent;width:1px;height:1px;top:0;left:0;"></div>`;
    document.body.appendChild(div);
  }

  if (window.digitalData) {
    window.digitalData.ads = pageContext;
  }

  window._ash = window._ash || [];
  window._ash.push({
    type: 'onNewPage',
    data: pageContext
  });
}

/**
 * script loader to request the ash.js ad library
 * @param {object} context - the big context
 * @returns {string} html markup <script> to insert the ash library
 */
export function initCAMP(context = {}) {
  // console.log('[ads] init camp');
  const { config, type = 'misc' } = context;
  const searchParams = config.initialQueryParams || {};
  if (Object.keys(searchParams).includes('no_ads')) {
    // prevent ads from loading
    return '';
  }
  if (noAdTypes.includes(type)) {
    return '';
  }
  const campSandboxParam = searchParams.camp_sandbox || '';
  const cachebuster = parseInt(Date.now() / 1000000, 10) || 0; // 16 minutes 40 seconds cache

  return `
    <!-- CAMP START -->
    <script key="camp" src="https://camp.fastcompany.com${
      campSandboxParam ? `/sandbox/${campSandboxParam}/` : '/'
    }ash.js?v=${cachebuster}" async ></script>
    <!-- CAMP END -->
  `;
}

/**
 * Builds a pageContext to generate ad data
 *
 * @param {object} context - the big context
 * @returns {object | undefined} the pageContext with ad data
 */
function buildPageContext(context = {}) {
  // console.log('[ads] build page context', context);

  const { config = {}, type = 'misc' } = context;
  const adPathFallback = `${adPathBase}/${type}/${type}`;

  const typeLookup = {
    author: () => {
      const { slug = '', id = '' } = context.authorPageData || {};
      const targeting = {
        aut: slug,
        articleid: id,
        tags: [slug]
      };

      return {
        targeting
      };
    },
    category: () => {
      const { slug = 'category', id = '' } = context.categoryPageData || {};
      const adPath = `${adPathBase}/${slug}/${slug}`;
      const targeting = {
        cat: slug,
        articleid: id,
        tags: [slug]
      };

      return {
        adPath,
        targeting
      };
    },
    homepage: () => ({}),
    hubPage: () => {
      const { slug = 'hubPage', id = '' } =
        get(context, 'hubPage.data.tag') || {};
      const targeting = {
        cat: slug,
        articleid: id,
        tags: [slug]
      };
      const adPath = `${adPathBase}/${slug}/${slug}`;

      return {
        adPath,
        targeting
      };
    },
    micYearPage: () => {
      const { micYearPageData } = context;
      const { data } = micYearPageData;
      const slug = get(data, 'slug', {});
      const adPath = `${adPathBase}/leadership/most-innovative-companies`;

      const targeting = {
        cat: 'most-innovative-companies',
        tags: ['most-innovative-companies', slug]
      };

      return {
        adPath,
        targeting
      };
    },
    mostCreativePeoplePage: () => {
      const { franchisePage } = context;
      const { franchiseCategory, slug, tags } = franchisePage.data || {};

      const targetingTags = tags.map(tag => tag.slug);
      const adPath = `${adPathBase}/leadership/most-creative-people`;

      const targeting = {
        cat: slug,
        tags: [
          ...targetingTags,
          slug,
          franchiseCategory,
          'most-creative-people'
        ]
      };

      return {
        adPath,
        targeting
      };
    },
    page: () => ({}),
    post: () => {
      const { categories, id = '', tags = [] } = context.postData || {};
      const aut = get(context, 'postData.author.slug') || '';
      const cat = categories.map(category => category.slug || '');
      const tagSlugs = tags.map(tag => tag.slug || '');
      const tagNames = tags.map(tag => tag.name || '');
      let priorityTag = getPriorityTag(tags) || 'misc';
      const isPremiumArticle = isPremium(tags);

      let adPath = `${adPathBase}/${cat[0] || priorityTag}/${priorityTag}`;

      tagNames.forEach(name => {
        if (name.indexOf('*') === 0) {
          priorityTag = 'advertorial';
          adPath = `${adPathBase}/advertorial/${name}`;
        }
      });

      const targeting = {
        articleid: id,
        aut,
        cat,
        tags: [...tagSlugs, ...cat]
      };

      if (isPremiumArticle) {
        targeting.t_type = 'premium';
      }

      return {
        adPath,
        targeting
      };
    },
    sitemapIndexPage: () => {
      const adPath = `${adPathBase}/sitemap/sitemap`;

      return {
        adPath
      };
    },
    tag: () => {
      const { id = '', slug = '', name = '' } = context.tagPageData || {};
      let adPath = `${adPathBase}/misc/${slug}`;
      let targeting = {
        articleid: id,
        tags: [slug]
      };

      if (name.indexOf('*') === 0 || name.indexOf('advertorial') > -1) {
        adPath = `${adPathBase}/advertorial/${name}`;
        targeting = {
          articleid: id,
          cat: 'advertorial',
          tags: ['advertorial', 'custom', slug]
        };
      }

      return {
        adPath,
        targeting
      };
    },
    videoIndexPage: () => {
      const adPath = `${adPathBase}/video/video`;
      const targeting = {
        cat: 'video',
        tags: ['video']
      };

      return {
        adPath,
        targeting
      };
    },
    videoPage: () => {
      const { playlist = [] } = get(context, 'videoPageData.data') || {};
      const { series, tags } = playlist[0] || {};
      const adPath = `${adPathBase}/video/${series || 'video'}`;
      const targetingTags = tags.map(tag => tag.slug || '');
      const targeting = {
        cat: 'video',
        tags: targetingTags
      };
      return {
        adPath,
        targeting
      };
    },
    videoPlaylistPage: () => {
      const { slug = 'video' } = get(context, 'playlistAllFeedData.data') || {};
      const adPath = `${adPathBase}/video/${slug}`;

      const targeting = {
        tags: ['video', type, slug]
      };

      return {
        adPath,
        targeting
      };
    },
    'wci-list': () => {
      const wci = 'world-changing-ideas';
      const { tags } = get(context, 'worldChangingIdeasPage.data') || {};
      const targetingTags = tags.map(tag => tag.slug);
      const adPath = `${adPathBase}/${wci}/${wci}`;

      const targeting = {
        cat: wci,
        tags: [wci, ...targetingTags]
      };

      return {
        adPath,
        targeting
      };
    }
  };

  const dynamicPageContext = typeLookup[type] ? typeLookup[type]() : {};

  const defaultPageContext = {
    adPath: adPathFallback,
    env: process.env.NODE_ENV, // 'development' or 'production'
    featureFlags: {},
    page: 1,
    targeting: {
      articleid: '',
      aut: '',
      cat: type,
      chn: '',
      cms: getCMSHash(config),
      flags: [],
      schn: '',
      slug: getSlug(config),
      t_type: type,
      tags: [type]
    },
    type
  };

  // console.log('[ads] === pageType', type);
  // console.log('[ads] === defaultConfig', defaultPageContext);
  // console.log('[ads] === dynamicConfig', dynamicPageContext);

  const rawContext = merge({}, defaultPageContext, dynamicPageContext);

  // console.log('[ads] === rawContext', rawContext);

  const pageContext = merge({}, rawContext, formatPageContext(rawContext));

  // console.log('[ads] === pageContext', pageContext);

  return pageContext;
}

/**
 * Creates and handles a new Ad Model
 *
 * @param {Object} context - the big context
 * @returns {Object} - the page context ad model
 */
export function createAdDataModel(context) {
  try {
    if (!canUseDOM) {
      return {};
    }
    const pageContext = buildPageContext(context);
    onNewAdModel(pageContext);
    return pageContext;
  } catch (error) {
    // eslint-disable-next-line
    console.error('[CAMP] buildPageContext', error);
    return {};
  }
}

export default initCAMP;
