// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    MAGAZINE_ARCHIVE_LOADING() {
      return {
        isLoading: true,
        magazineArchive: action.magazineArchive
      };
    },
    MAGAZINE_ARCHIVE_LOADED() {
      return {
        isLoading: false,
        data: action.magazineArchive
      };
    },
    MAGAZINE_ARCHIVE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.magazineArchive.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
