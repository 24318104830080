import * as supertagsActions from './../action_creators/supertags_action_creators';
import SupertagsModel from '../../models/supertags_model';
import log from '../../services/logger_service';

export default function fetchSupertags(params, store) {
  return function firstDispatchSupertags(dispatch) {
    dispatch(supertagsActions.supertagsLoading([], store));

    return SupertagsModel.fetch(params, store)
      .then(function handleSupertagsData(resp) {
        const { data } = resp;
        dispatch(supertagsActions.supertagsLoaded(data, store));
      })
      .catch(function handleSupertagsError(err) {
        dispatch(supertagsActions.supertagsLoadError(err, store));
        log.error(err, `Error in supertags actions, message: ${err.message}`);
      });
  };
}
