import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let PageComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  PageComponent = require('../queer50_page_handler').default;
}

/**
 * The route for Queer50 list page for CURRENT/LATEST year
 */
export function queer50List(store) {
  const routeConfig = {
    path: '/queer-50/:year(2021|2022|2023|list)/:sub(rank)?/:rank?',
    component() {
      if (PageComponent) {
        return <PageComponent />;
      }
      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: PageComponent ? PageComponent.loadData(store) : false,
    preloadChunk() {
      return import('../queer50_page_handler').then(resp => {
        PageComponent = resp.default;
        return PageComponent;
      });
    },
    chunk: 'queer50'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="queer50-list-page">
        <article className="queer50-list-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
