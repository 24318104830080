import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const tagName = get(state, 'categoryAllFeed.data.tag.name');
  const category = get(state, 'categoryFeaturedFeed.category', {
    description: `Breaking news and analysis on business, technology, and leadership from the Fast Company news team.`,
    slug: 'news'
  });
  const tagSlug = get(state, 'categoryAllFeed.data.tag.slug');
  const categoryName = get(category, 'name');
  const seoDescriptionOverride = `Find the latest ${tagName ||
    tagSlug ||
    categoryName} news from Fast company. See related business and technology articles, photos, slideshows and videos.`;
  const customSeoTitle = get(category, 'acf.seoCategoryTitle', null);
  const slug = get(category, 'slug');
  const description =
    // get(category, 'acf.seoCategoryDescription', '') ||
    seoDescriptionOverride ||
    get(category, 'description', '') ||
    get(state, 'categoryAllFeed.data.tag.description', '');
  let title = customSeoTitle || categoryName || '';
  let image = '';
  if (slug === 'magazine') {
    title = 'Fast Company Magazine';
  }

  if (tagName) {
    title = `${categoryName} : ${tagName}`;
  }

  if (slug === 'news') {
    image =
      'https://images.fastcompany.net/image/upload/v1501604705/fcweb/fastco-news-share-graphic_jot2bv.png';
  }

  const url = tagName
    ? `https://www.${site}.com/${slug}/${tagSlug}`
    : `https://www.${site}.com/${slug}`;

  const rss = `/${slug}/rss`;

  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    image,
    url,
    description,
    socialDescriptionOverride: description,
    config: state.config,
    articleSection: categoryName,
    rss
  });

  return data;
}
