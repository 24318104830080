export default function(state = {}, action) {
  const typeMap = {
    MCP_HUB_PAGE_LOADING() {
      return {
        isLoading: true,
        mcpHubPage: action.mcpHubPage
      };
    },
    MCP_HUB_PAGE_LOADED() {
      return {
        isLoading: false,
        data: action.mcpHubPage
      };
    },
    MCP_HUB_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.mcpHubPage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
