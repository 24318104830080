import { canUseDOM } from 'exenv';

let serverLog = false;
let clientLog = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  serverLog = require('../server/services/logger_service');
} else {
  // eslint-disable-next-line global-require
  clientLog = require('../client/services/logger_service');
}

const log = canUseDOM ? clientLog : serverLog;

export default log.default;
