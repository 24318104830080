// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    JW_PLAYLIST_INDEX_LOADING() {
      return {
        isLoading: true
      };
    },
    JW_PLAYLIST_INDEX_LOADED() {
      return {
        isLoading: false,
        data: action.jwPlaylistIndex
      };
    },
    JW_PLAYLIST_INDEX_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.jwPlaylistIndex.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
