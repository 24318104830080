// import { canUseDOM } from 'exenv';

export function featuredFeedLoading(resp = {}, store = {}) {
  return {
    type: 'FEATURED_FEED_LOADING',
    featuredFeed: resp,
    store
  };
}

export function featuredFeedLoaded(resp = {}, store) {
  return {
    type: 'FEATURED_FEED_LOADED',
    featuredFeed: Object.assign(resp, {
      combined: resp.featured.concat(resp.top)
    }),
    store
  };
}

export function featuredFeedLoadError(error, store) {
  return {
    type: 'FEATURED_FEED_ERROR',
    featuredFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
