import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('../logout_page').default;
}

export default function(store) {
  const routeConfig = {
    path: '/logout',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "logoutPage" */ '../logout_page').then(
        resp => {
          Component = resp.default;
          // routeConfig.loadData = () => {};
          return Component;
        }
      );
    },
    chunk: 'logoutPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="login-page">
        <article className="login-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
