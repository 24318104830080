// import { canUseDOM } from 'exenv';

export function articleTextLoading(resp = {}, store = {}) {
  return {
    type: 'POST_PROMOTER_ARTICLE_TEXT_LOADING',
    promoterArticleText: resp,
    store
  };
}

export function articleTextLoaded(resp = {}, store = {}) {
  return {
    type: 'POST_PROMOTER_ARTICLE_TEXT_LOADED',
    promoterArticleText: resp,
    store
  };
}

export function articleTextLoadError(error, store = {}) {
  return {
    type: 'POST_PROMOTER_ARTICLE_TEXT_ERROR',
    promoterArticleText: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
