import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('../verify_subscription_page').default;
}

export default function() {
  const routeConfig = {
    path: '/verify/:event?',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    preloadChunk() {
      return import('../verify_subscription_page').then(resp => {
        Component = resp.default;
        return Component;
      });
    },
    chunk: 'myAccountPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="verify-subscription-page">
        <article className="verify-subscription-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });
  return routeConfig;
}
