// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    TAG_FEATURED_FEED_LOADING() {
      return {
        isLoading: true,
        tagFeaturedFeed: action.tagFeaturedFeed
      };
    },
    TAG_FEATURED_FEED_LOADED() {
      return {
        isLoading: false,
        featured: get(action, 'tagFeaturedFeed.featured'),
        top: get(action, 'tagFeaturedFeed.top'),
        tag: get(action, 'tagFeaturedFeed.tag'),
        source: get(action, 'tagFeaturedFeed.source')
      };
    },
    TAG_FEATURED_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.tagFeaturedFeed.errorMessage,
        featured: [],
        tag: {},
        top: []
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
