// import { canUseDOM } from 'exenv';

export function categoryVideoLoading(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_VIDEO_LOADING',
    categoryVideo: resp,
    store
  };
}

export function categoryVideoLoaded(resp = {}, store = {}) {
  return {
    type: 'CATEGORY_VIDEO_LOADED',
    categoryVideo: resp,
    store
  };
}

export function categoryVideoLoadError(error, store = {}) {
  return {
    type: 'CATEGORY_VIDEO_ERROR',
    categoryVideo: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
