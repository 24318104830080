import forEach from 'lodash/forEach';

export default function(tags) {
  let bylineAttribution = '';

  forEach(tags, tag => {
    if (
      tag.name.indexOf('byline_') !== -1 ||
      tag.slug.indexOf('byline-') !== -1
    ) {
      bylineAttribution = tag.name.replace('byline_', '');
    }
  });

  return bylineAttribution;
}
