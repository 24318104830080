import get from 'lodash/get';
import siteData from './site_data_util';
import baseSeoUtil from './base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const siteHumanReadable = get(siteConf, '[og:site_name]');
  const description = `Site map for ${siteHumanReadable}`;

  const pageURL = get(state, 'config.navHistory[0]').split('/');
  let year = '';

  if (get(pageURL, 'length')) {
    const yearIdentifier = pageURL[get(pageURL, 'length') - 1];
    if (yearIdentifier !== 'sitemap') {
      year = ` > ${yearIdentifier}`;
    }
  }

  const title = `Site Map${year}`;
  const data = baseSeoUtil({
    site,
    siteConf,
    title,
    url: get(state, 'config.navHistory[0]'),
    description,
    articleSection: title,
    config: state.config
  });

  return data;
}
