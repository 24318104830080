export function franchisePageLoading(resp = {}, store = {}) {
  return {
    type: 'FRANCHISE_PAGE_LOADING',
    franchisePage: resp,
    store
  };
}

export function franchisePageLoaded(resp = {}, store = {}, pageType = '') {
  return {
    type: 'FRANCHISE_PAGE_LOADED',
    franchisePage: resp,
    store,
    pageType
  };
}

export function franchisePageLoadError(error, store = {}) {
  return {
    type: 'FRANCHISE_PAGE_ERROR',
    franchisePage: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}
