import * as recircFeedActions from './../action_creators/recirc_feed_action_creators';
import RecircFeedModel from '../../models/recirc_module';
import log from '../../services/logger_service';
import { setAB } from '../../utils/ab_test_util';

export default function fetchRecircModule(params, store) {
  return function firstDispatchRecirc(dispatch) {
    dispatch(recircFeedActions.recircFeedLoading({}, store));

    const state = store.getState();
    const { abtest } = state.config;

    return RecircFeedModel.fetch(params, store)
      .then(function handleRecircModuleData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(recircFeedActions.recircFeedLoaded(data, store));
      })
      .catch(function handleRecircError(err) {
        dispatch(recircFeedActions.recircFeedLoadError(err, store));
        log.error(err, `Error in recirc feed actions, message: ${err.message}`);
      });
  };
}

export function hideRecircAction(dispatch) {
  return function hide() {
    dispatch(recircFeedActions.hideRecircFeed());
  };
}
