import get from 'lodash/get';
import { canUseDOM } from 'exenv';
import trackThirdPartyJS from '../../client/utils/third_party_trackers/trackThirdPartyJS';

function trackAnalytics(currentState, type) {
  if (canUseDOM) {
    const currentStateCopy = currentState;
    const initialPageLoad = get(currentStateCopy, 'config.initialPageLoad');
    // build context... sux but due to synchronous nature of reducers
    // this will have to be built here

    currentStateCopy.context = {
      site: get(currentStateCopy, 'config.site'),
      type,
      postData: get(currentStateCopy, 'post.data.post'),
      tagPageData: get(currentState, 'tagFeaturedFeed.tag'),
      categoryPageData: get(currentState, 'categoryFeaturedFeed.category'),
      authorPageData: get(currentState, 'author.data'),
      staticPageData: get(currentState, 'customPage.data.page[0]'),
      bestWorkplacesPageData: get(currentState, 'bestWorkplacesPage'),
      mostCreativePeoplePageData: get(currentState, 'mostCreativePeople'),
      personPageData: get(currentState, 'person'),
      searchPageData: get(currentState, 'search.data'),
      ibdPageData: get(currentState, 'innovationByDesign'),
      companyPageData: get(currentState, 'companyData'),
      micPageData: get(currentState, 'micYear'),
      micSectorPageData: get(currentState, 'micSector'),
      videoPageData: get(currentState, 'jwVideo'),
      videoIndexPageData: get(currentState, 'videoIndexPage'),
      productPageData: get(currentState, 'product'),
      playlistAllFeedData: get(currentState, 'playlistAllFeed.data'),
      supertags: get(currentState, 'supertags')
    };

    if (!initialPageLoad) {
      trackThirdPartyJS(currentStateCopy);
    }
  }
}

export default function(state = {}, action) {
  const { store, type } = action;

  const typeMap = {
    POSTPAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'post');
      return state;
    },
    POSTPAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    HOMEPAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'homepage');
      return state;
    },
    HOMEPAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    CATEGORY_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'category');
      return state;
    },
    CATEGORY_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    TAG_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'tag');
      return state;
    },
    TAG_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    AUTHOR_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'author');
      return state;
    },
    AUTHOR_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    WP_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'page');
      return state;
    },
    WP_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'errorPage');
      return state;
    },
    HTML_SITEMAP_INDEX_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'htmlSitemapIndex');
      return state;
    },
    HTML_SITEMAP_INDEX_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'htmlSitemapIndex');
      return state;
    },
    HTML_SITEMAP_POST_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'htmlSitemapPostPage');
      return state;
    },
    HTML_SITEMAP_POST_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'htmlSitemapPostPage');
      return state;
    },
    MOST_CREATIVE_PEOPLE_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'mostCreativePeoplePage');
      return state;
    },
    MOST_CREATIVE_PEOPLE_COMMUNITY_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'mostCreativePeopleCommunityPage');
      return state;
    },
    PRODUCT_INDEX_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'productIndexPage');
      return state;
    },
    PRODUCT_INDEX_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'productIndexPage');

      return state;
    },
    PRODUCT_INDEX_SECTION_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'productIndexSectionPage');

      return state;
    },
    PRODUCT_INDEX_SECTION_PAGE_ERROR() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'productIndexSectionPage');
      return state;
    },
    PRODUCT_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'productPage');
      return state;
    },
    PERSON_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'personPage');
      return state;
    },
    STATIC_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'staticPageData');
      return state;
    },
    INNOVATION_BY_DESIGN_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'ibdPage');
      return state;
    },
    MIC_YEAR_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'micYearPage');
      return state;
    },
    MIC_SECTOR_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'micSectorPage');
      return state;
    },
    VIDEO_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'videoPage');
      return state;
    },
    JW_PLAYLIST_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'jwPlaylist');
      return state;
    },
    PLAYLIST_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'playlistAllFeed');
      return state;
    },
    VIDEO_INDEX_PAGE_LOADED() {
      const currentState = store.getState();

      trackAnalytics(currentState, 'videoIndexPage');
      return state;
    },
    MAGAZINE_ARCHIVE_PAGE_LOADED() {
      const currentState = store.getState();
      trackAnalytics(currentState, 'magazineArchivePage');
      return state;
    }
  };

  if (typeMap[type]) {
    return typeMap[type](action);
  }

  return state;
}
