// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    JW_RELATED_VIDEO_LOADING() {
      return {
        isLoading: true
      };
    },
    JW_RELATED_VIDEO_LOADED() {
      return {
        isLoading: false,
        data: action.jwRelatedVideo
      };
    },
    JW_RELATED_VIDEO_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.jwRelatedVideo.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
