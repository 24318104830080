// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    AUTHOR_LOADING() {
      return {
        isLoading: true,
        author: action.author
      };
    },
    AUTHOR_LOADED() {
      return {
        isLoading: false,
        data: get(action, 'author')
      };
    },
    AUTHOR_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: get(action, 'author.errorMessage')
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
