export default function(state = {}, action) {
  const typeMap = {
    FRANCHISE_PAGE_LOADING() {
      return {
        isLoading: true,
        franchisePage: action.franchisePage
      };
    },
    FRANCHISE_PAGE_LOADED() {
      return {
        isLoading: false,
        data: action.franchisePage
      };
    },
    FRANCHISE_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.franchisePage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
