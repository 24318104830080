import get from 'lodash/get';
import * as tagAllFeedActions from '../../action_creators/tag/tag_all_feed_action_creators';
import TagAllFeedModel from '../../../models/tag/tag_all_feed';
import notFoundActionCreator from '../../action_creators/not_found_status_action_creator';
import axios from 'axios';
import log from '../../../services/logger_service';
import { slugify } from '../../../utils/string_util';

const videoFetchLimit = 5; // Limit the number of videos fetched for feed
// Map of tag to JWPlayer playlist IDs
const tagJwIdMap = {
  'living-smarter': 'Tdu1z6L9',
  'davos-dialogues': 'YMljKrb3'
};

export default function fetchTag(params, store, shouldNot404) {
  return function firstDispatch(dispatch) {
    // Set loading state
    dispatch(tagAllFeedActions.tagAllFeedLoading({}, store));

    const promiseArray = [TagAllFeedModel.fetch(params, store)];

    // Check for tag needing video data
    const foundVideoTag = tagJwIdMap[params?.tag || ''];
    if (foundVideoTag) {
      promiseArray.push(
        axios.get(
          `https://cdn.jwplayer.com/v2/playlists/${foundVideoTag}?page_limit=${videoFetchLimit}`
        )
      );
    }

    return Promise.all(promiseArray)
      .then(function handleTagDetailData(TagData) {
        if (params.page && !get(TagData, 'data.posts.length')) {
          dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
        }

        const TagAllFeedModelResponse = get(TagData, '[0].data', []);
        const responsePosts = get(TagAllFeedModelResponse, 'posts', []);

        // Handle video response data
        const jwPostsForTagResponse = get(TagData, '[1].data');
        if (foundVideoTag && jwPostsForTagResponse) {
          const videoPlaylistData = get(jwPostsForTagResponse, 'playlist', []);

          const normalizedVideos = videoPlaylistData.map(video => ({
            title: `${get(video, 'title')}`,
            section: 'video',
            link: `https://www.fastcompany.com/video/${slugify(
              get(video, 'title', '')
            )}/${get(video, 'mediaid')}`,
            featured_image: {
              source: get(video, 'image'),
              alt: get(video, 'excerpt')
            },
            excerpt: get(video, 'description'),
            categories: [{ name: 'Video', slug: 'video' }]
          }));

          TagAllFeedModelResponse.posts = [
            ...responsePosts,
            ...normalizedVideos
          ];
        }

        dispatch(
          tagAllFeedActions.tagAllFeedLoaded(TagAllFeedModelResponse, store)
        );
      })
      .catch(function handleUserError(err) {
        const status = get(err, 'response.status') || 500;
        if (!shouldNot404) {
          if (status === 404) {
            dispatch(notFoundActionCreator(404, 'NOT_FOUND_STATUS', store));
          } else {
            dispatch(notFoundActionCreator(500, 'ERROR_STATUS', store));
            log.error(
              err,
              `Error in tag all feed actions, message: ${err.message}`
            );
          }
        }
        dispatch(tagAllFeedActions.tagAllFeedLoadError(err, store));
      });
  };
}
