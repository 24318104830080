import { canUseDOM } from 'exenv';
import { get, merge } from 'lodash';

// Triggers the tracking library event using the data from the EventModel
export const fireActionTriggerCustomEventDynamic = (
  eventName,
  data,
  target = canUseDOM ? document.querySelector('body') : undefined
) => {
  if (typeof target === 'undefined' || !canUseDOM) return;

  const event = new CustomEvent(eventName, data);

  // Update the data layer events array
  const dataLayerEvent = get(window.digitalData, 'events', []);
  dataLayerEvent.push({
    eventName: get(event, 'detail.name', 'unknown event'),
    eventType: get(event, 'type', 'unknown type'),
    eventInfo: get(event, 'detail', {})
  });
  window.digitalData.events = dataLayerEvent;

  // Fire the event
  target.dispatchEvent(event);
};

/**
 * The model to be imported & used for ALL tracking event data
 * It provides guaranteed data that is required by a tracking event
 * [Name, Type, Action, Effect, Position, Timestamp]
 */
class EventModel {
  constructor(
    eventName = '',
    eventType = '',
    eventAction = '',
    eventEffect = '',
    eventPosition = 1,
    eventTimestamp = new Date()
  ) {
    this.name = eventName;
    this.type = eventType;
    this.action = eventAction;
    this.effect = eventEffect;
    this.position = eventPosition;
    this.timestamp = eventTimestamp;
  }

  // Add any custom key/value pairs to the tracking event
  addTrackingAttributes(attributes = {}) {
    Object.entries(attributes).forEach(attribute => {
      const attributeName = attribute[0];
      const attributeValue = attribute[1];
      this[attributeName] = attributeValue;
    });
  }

  // Get the current set of data for the EventModel
  getTrackingObject() {
    const returnObject = {};

    Object.entries(this).forEach(attribute => {
      const attributeName = attribute[0];
      const attributeValue = attribute[1];

      returnObject[attributeName] = attributeValue;
    });

    return returnObject;
  }

  // Pass the data to the event tracking library for tracking
  triggerTrackingEvent(eventTrigger, eventDetails) {
    fireActionTriggerCustomEventDynamic(`${eventTrigger}`, {
      detail: eventDetails
    });
  }

  // Fire event in the dataLayer object for Google Tag Manager (GTM)
  addToDataLayer(eventName, additionalData = {}) {
    if (canUseDOM) {
      const pageType = get(window, 'digitalData.page.pageInfo.type', '');

      const eventData = {
        event: eventName,
        customer_id: get(
          window,
          'digitalData.user[0].profile[0].profileInfo.profileID',
          ''
        ),
        article: {
          author: get(window, 'digitalData.page.pageInfo.author', '')
        },
        page: {
          content_type: pageType,
          content_topic: get(
            window,
            'digitalData.page.category.primaryCategory',
            ''
          ),
          subCategory1: get(
            window,
            'digitalData.page.category.subCategory1',
            ''
          ),
          subCategory2: get(
            window,
            'digitalData.page.category.subCategory2',
            ''
          ),
          scroll_position: get(
            window,
            'digitalData.page.pageInfo.infiniteScrollIndex',
            '1'
          ),
          path: get(window, 'location.href', ''),
          premium: get(window, 'digitalData.page.pageInfo.premium', false)
        },
        ...this.getTrackingObject()
      };

      const finalData = merge({}, eventData, additionalData);
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(finalData);
    }
  }

  // Push key/value pair to SpeedCurve LUX object
  pushKeyValueToSpeedCurve(name, value) {
    if (!window.LUX) {
      // Bail if SC LUX object is not available
      // eslint-disable-next-line no-console
      console.warn('🔬 SC LUX object is not available');
      return;
    }
    window.LUX.addData(name, value);
  }
}

export default EventModel;
