import get from 'lodash/get';
import homepageMeta from '../../utils/seo_utils/homepage_seo_util';
import postMeta from '../../utils/seo_utils/post_seo_util';
import videoMeta from '../../utils/seo_utils/video/video_seo_util';
import videoIndexMeta from '../../utils/seo_utils/video/video_index_seo_util';
import videoPlaylistMeta from '../../utils/seo_utils/video/video_playlist_seo_util';
import categoryMeta from '../../utils/seo_utils/category_seo_util';
import tagMeta from '../../utils/seo_utils/tag_seo_util';
import personMeta from '../../utils/seo_utils/mcp/person_seo_util';
import personIndexMeta from '../../utils/seo_utils/mcp/person_index_seo_util';
import magazineArchivePageMeta from '../../utils/seo_utils/magazine_archive_seo_util';
import {
  bestWorkplacesForInnovatorsMeta,
  bestWorkplacesForInnovatorsItemMeta
} from '../../utils/seo_utils/bwi/best_workplaces_for_innovators_seo_util';
import mostCreativePeopleMeta from '../../utils/seo_utils/mcp/most_creative_people_seo_util';
import mostCreativePeopleCommunityMeta from '../../utils/seo_utils/mcp/most_creative_people_community_seo_util';
import micHubPageMeta from '../../utils/seo_utils/mic/mic_hub_page_seo_util';
import micIxPageMeta from '../../utils/seo_utils/mic/mic_ix_page_seo_util';
import mcpHubPageMeta from '../../utils/seo_utils/mcp/mcp_hub_page_seo_util';
import micYearPageMeta from '../../utils/seo_utils/mic/mic_year_page_seo_util';
import mic2020YearPageMeta from '../../utils/seo_utils/mic/mic2020_year_page_seo_util';
import micSectorPageMeta from '../../utils/seo_utils/mic/mic_sector_page_seo_util';
import ibdYearPageMeta from '../../utils/seo_utils/ibd/ibd_year_page_seo_util';
import giftGuidePageMeta from '../../utils/seo_utils/gift_guide/gift_guide_page_seo_util';
import giftPageMeta from '../../utils/seo_utils/gift_guide/gift_seo_util';
import authorMeta from '../../utils/seo_utils/author_seo_util';
import {
  queer50List,
  queer50Item,
  queer50Initial
} from '../../utils/seo_utils/queer50_seo_util';
import wpPageMeta from '../../utils/seo_utils/wp_page_seo_util';
import staticPageMeta from '../../utils/seo_utils/static_page_seo_util';
import sitemapIndexPageMeta from '../../utils/seo_utils/sitemap_index_page_seo_util';
import sitemapPostPageMeta from '../../utils/seo_utils/sitemap_post_page_seo_util';
import pianoPageMeta from '../../utils/seo_utils/piano_page_seo_util';
import { btmList } from '../../utils/seo_utils/brands_that_matter/brands_that_matter_page_seo_util';
import franchisePageMeta from '../../utils/seo_utils/franchise_page/franchise_page_seo_util';
import worldChangingIdeasList from '../../utils/seo_utils/wci/world_changing_ideas_seo_util';
import hubPageMeta from '../../utils/seo_utils/hub_seo_util';
import franchiseApplyPageMeta from '../../utils/seo_utils/applications/franchise_apply_page_seo_util';
import recognitionHubPageMeta from '../../utils/seo_utils/recognition_hub_seo_util';

export default function(state = {}, action) {
  const { store, type } = action;

  const typeMap = {
    POSTPAGE_LOADED() {
      const currentState = store.getState();
      return postMeta(currentState);
    },

    PIANO_PAGE_LOADED() {
      const currentState = store.getState();
      return pianoPageMeta(currentState);
    },

    PREVIEW_POSTPAGE_LOADED() {
      const currentState = store.getState();
      return postMeta(currentState);
    },

    HOMEPAGE_LOADED() {
      const currentState = store.getState();
      return homepageMeta(currentState);
    },

    CATEGORY_PAGE_LOADED() {
      const currentState = store.getState();
      return categoryMeta(currentState);
    },

    TAG_PAGE_LOADED() {
      const currentState = store.getState();
      return tagMeta(currentState);
    },

    PERSON_PAGE_LOADED() {
      const currentState = store.getState();
      return personMeta(currentState);
    },

    BEST_WORKPLACES_PAGE_LOADED() {
      const currentState = store.getState();
      const initialRank = get(action, 'bestWorkplacesPage.initialRank');

      if (initialRank) {
        return bestWorkplacesForInnovatorsItemMeta(currentState, action);
      }
      return bestWorkplacesForInnovatorsMeta(currentState, action);
    },

    MOST_CREATIVE_PEOPLE_PAGE_LOADED() {
      const currentState = store.getState();
      const currentYear = get(action, 'mostCreativePeoplePage.year', '2022'); // default to latest active year
      return mostCreativePeopleMeta(currentState, currentYear);
    },

    MOST_CREATIVE_PEOPLE_COMMUNITY_PAGE_LOADED() {
      const currentState = store.getState();
      return mostCreativePeopleCommunityMeta(currentState);
    },

    MOST_CREATIVE_PEOPLE_COMMUNITY_SECTION_PAGE_LOADED() {
      const currentState = store.getState();
      return mostCreativePeopleCommunityMeta(currentState);
    },

    PERSON_INDEX_PAGE_LOADED() {
      const currentState = store.getState();
      return personIndexMeta(currentState);
    },

    PERSON_INDEX_SECTION_PAGE_LOADED() {
      const currentState = store.getState();
      return personIndexMeta(currentState);
    },

    AUTHOR_PAGE_LOADED() {
      const currentState = store.getState();
      return authorMeta(currentState);
    },

    QUEER50_PAGE_LOADED() {
      const currentState = store.getState();
      const isRanked = get(action, 'queer50.isRankedItem', false);
      const franchiseYear =
        action?.queer50?.franchiseCategory?.replace('q50-', '') ||
        action?.queer50?.acf?.franchise_year ||
        false;

      switch (true) {
        case !franchiseYear:
        case franchiseYear === '2021':
          return queer50Initial(currentState, action);
        case isRanked:
          return queer50Item(currentState, action);
        default:
          return queer50List(currentState, action);
      }
    },

    WP_PAGE_LOADED() {
      const currentState = store.getState();
      return wpPageMeta(currentState);
    },

    STATIC_PAGE_LOADED() {
      const currentState = store.getState();
      return staticPageMeta(currentState);
    },

    HTML_SITEMAP_INDEX_PAGE_LOADED() {
      const currentState = store.getState();
      return sitemapIndexPageMeta(currentState);
    },

    HTML_SITEMAP_POST_PAGE_LOADED() {
      const currentState = store.getState();
      return sitemapPostPageMeta(currentState);
    },

    MAGAZINE_ARCHIVE_LOADED() {
      const currentState = store.getState();
      return magazineArchivePageMeta(currentState);
    },

    MIC_YEAR_PAGE_LOADED() {
      const currentState = store.getState();
      return micYearPageMeta(currentState, action);
    },

    MIC_2020_YEAR_PAGE_LOADED() {
      const currentState = store.getState();
      return mic2020YearPageMeta(currentState);
    },

    MIC_SECTOR_PAGE_LOADED() {
      const currentState = store.getState();
      return micSectorPageMeta(currentState);
    },

    INNOVATION_BY_DESIGN_PAGE_LOADED() {
      const currentState = store.getState();
      return ibdYearPageMeta(currentState);
    },

    GIFT_GUIDE_PAGE_LOADED() {
      const currentState = store.getState();
      return giftGuidePageMeta(currentState);
    },

    GIFT_PAGE_LOADED() {
      const currentState = store.getState();
      return giftPageMeta(currentState);
    },

    VIDEO_PAGE_LOADED() {
      const currentState = store.getState();
      return videoMeta(currentState);
    },

    PLAYLIST_PAGE_LOADED() {
      const currentState = store.getState();
      return videoPlaylistMeta(currentState);
    },

    VIDEO_INDEX_PAGE_LOADED() {
      const currentState = store.getState();
      return videoIndexMeta(currentState);
    },

    BRANDS_THAT_MATTER_PAGE_LOADED() {
      const currentState = store.getState();
      return btmList(currentState, action);
    },

    FRANCHISE_PAGE_LOADED() {
      const currentState = store.getState();
      return franchisePageMeta(currentState, action);
    },

    WORLD_CHANGING_IDEAS_PAGE_LOADED() {
      const currentState = store.getState();
      return worldChangingIdeasList(currentState, action);
    },

    HUB_PAGE_LOADED() {
      const currentState = store.getState();
      return hubPageMeta(currentState, action);
    },

    MCP_HUB_PAGE_LOADED() {
      const currentState = store.getState();
      return mcpHubPageMeta(currentState, action);
    },

    MIC_HUB_PAGE_LOADED() {
      const currentState = store.getState();
      return micHubPageMeta(currentState, action);
    },

    MIC_IX_PAGE_LOADED() {
      const currentState = store.getState();
      return micIxPageMeta(currentState, action);
    },

    FRANCHISE_APPLY_PAGE_LOADED() {
      const currentState = store.getState();
      return franchiseApplyPageMeta(currentState, action);
    },

    RECOGNITION_HUB_PAGE_LOADED() {
      const currentState = store.getState();
      return recognitionHubPageMeta(currentState, action);
    }
  };

  if (typeMap[type]) {
    return typeMap[type](action);
  }

  return state;
}
