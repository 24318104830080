export default {
  siteName: 'Fast Company',
  slug: 'fastcompany',
  url: 'https://www.fastcompany.com',
  slogan: 'Business News, Innovation, Technology, Work Life and Design',
  description:
    "Fast Company is the world's leading progressive business media brand, with a unique editorial focus on innovation in technology, leadership, and design.",
  favicon: '/favicon.ico',
  logo:
    'https://images.fastcompany.net/image/upload/v1541101333/fcweb/FC-Twitter-Card_new2_mqyz2q.jpg',
  keywords: [
    'business',
    'magazine',
    'news',
    'leadership',
    'most innovative companies',
    'most creative people',
    'videos',
    'innovation'
  ],
  facebook: {
    fanpageId: '5942134076',
    appId: '178479832213933',
    appAdminId: '100000062710915'
  },
  reCaptcha: {
    public: '6Lf59DoUAAAAAMImCMrNJRUIrtlnwN5sH_NOQ9D2'
  },
  parsely: {
    domain: 'fastcompany.com'
  },
  facebookPixel: {
    id: 1389601884702365
  },
  nielsen: {
    clientId: 'us-805310h'
  },
  googleAnalytics: {
    webPropertyId: 'UA-4300461-2',
    rollupPropertyId: 'UA-4300461-33'
  },
  googleAnalyticsIA: {
    webPropertyId: 'UA-4300461-40'
  },
  comscore: {
    c1: 2,
    c2: 6916907,
    publisherId: 6916907
  },
  chartbeat: {
    uid: 2768,
    domain: 'fastcompany.com'
  },
  pingdom: {
    id: '518c1737abe53dc44c000000'
  },
  dianomi: {
    id: '3780',
    ampID: '3925'
  },
  magazineSubUrls: [
    'https://store.fastcompany.com/subscription-order/singlesku/1999-us'
  ]
};
