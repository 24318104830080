// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    POST_LOADING() {
      return {
        isLoading: true,
        post: action.post
      };
    },
    POST_LOADED() {
      return {
        isLoading: false,
        data: action.post
      };
    },
    POST_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.post.errorMessage
      };
    },
    REFRESH_AD() {
      return {
        isLoading: false,
        data: state.data,
        slotsToRefresh: [action.slot]
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
