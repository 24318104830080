import forEach from 'lodash/forEach';
import get from 'lodash/get';
import toNumber from 'lodash/toNumber';

import { canUseDOM } from 'exenv';
import identityConfig from '../../../config/identity';
import { getPriorityTag } from '../../../utils/ads/get_priority_tag';

export default function loadGATracker(currentState, pageNumber = 0) {
  // https://developers.google.com/analytics/devguides/collection/analyticsjs/
  if (window.ga) {
    const { ga } = window;
    const siteConfig = identityConfig;
    const siteSlug = get(siteConfig, 'slug');
    const context = get(currentState, 'context');

    const {
      postData,
      tagPageData,
      categoryPageData,
      mostCreativePeoplePageData,
      micSectorPageData,
      ibdPageData,
      productPageData,
      micYearPageData,
      type,
      supertags
    } = context;

    let category = '';
    let tag = '';

    // config here: https://analytics.google.com/analytics/web/#management/Settings/a45063514w75598220p78098335/%3Fm.page%3DPropertySettings/
    const legacyDomain = siteSlug;
    let customDimensions = [
      { dimension1: legacyDomain },
      { dimension7: 'homepage' },
      { dimension3: 'Unassigned' },
      { dimension2: 'Unassigned' },
      { dimension4: 'Fast Company' },
      { dimension5: 'Fast Company' },
      { dimension6: '1/1/1990' },
      { dimension8: 0 },
      { dimension9: 0 }
    ];
    const typeLookup = {
      post() {
        category = get(postData, 'categories[0].slug', 'technology');
        const tags = get(postData, 'tags');
        const eyeBrowText = getPriorityTag(tags, supertags);

        let bylineAttribution = null;

        forEach(tags, tagObj => {
          if (
            tagObj.name.indexOf('byline_') !== -1 ||
            tagObj.slug.indexOf('byline-') !== -1
          ) {
            bylineAttribution = tagObj.name.replace('byline_', '');
          }
        });

        // For mapping of language, anaytics need to be consistent, language wise
        let pageType = 'unassigned';
        if (get(postData, 'posterSlideshow.length')) {
          pageType = 'slideshow';
        } else if (
          get(postData, 'hasVideoPoster') === true ||
          get(postData, 'posterVideo.feedId')
        ) {
          pageType = 'video';
        } else {
          pageType = 'article';
        }

        let minutesRead = get(postData, 'readTime');
        if (minutesRead !== 'long') {
          minutesRead = toNumber(minutesRead.replace(' minute', '')) || '0';
        }

        customDimensions = [
          { dimension1: legacyDomain },
          { dimension2: category },
          { dimension6: get(postData, 'formattedDate', '') },
          { dimension7: pageType },
          { dimension8: minutesRead },
          {
            dimension9: pageNumber !== 0 ? pageNumber - 1 : pageNumber
          }
        ];

        if (bylineAttribution) {
          customDimensions.push({ dimension4: bylineAttribution });
          customDimensions.push({
            dimension5: get(postData, 'author.name', 'Fast Company')
          });
        } else {
          customDimensions.push({
            dimension4: get(postData, 'author.name')
          });
          customDimensions.push({ dimension5: 'Fast Company' });
        }

        if (eyeBrowText) {
          customDimensions.push({ dimension3: eyeBrowText });
        } else {
          customDimensions.push({ dimension3: 'notag' });
        }
      },
      category() {
        category = get(categoryPageData, 'slug');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension2: category },
          { dimension7: 'category page' },
          { dimension3: 'notag' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      tag() {
        tag = get(tagPageData, 'name');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension3: tag },
          { dimension7: 'section page' },
          { dimension2: 'Unassigned' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      author() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: type },
          { dimension3: 'Unassigned' },
          { dimension2: 'Unassigned' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      search() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: type },
          { dimension3: 'Unassigned' },
          { dimension2: 'Unassigned' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      page() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: type },
          { dimension3: 'Unassigned' },
          { dimension2: 'Unassigned' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      personPage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'person page' },
          { dimension3: 'Unassigned' },
          { dimension2: 'person' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      personIndexSectionPage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'person index page' },
          { dimension3: 'Unassigned' },
          { dimension2: 'person' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      mostCreativePeoplePage() {
        const year = get(mostCreativePeoplePageData, 'year', '');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'mcp page' },
          { dimension3: `mcp${year}` },
          { dimension2: 'mcp' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      mostCreativePeopleCommunityPage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'mcp community page' },
          { dimension3: 'Unassigned' },
          { dimension2: 'mcp' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      micSectorPage() {
        const year = get(micSectorPageData, 'year', '');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'mic sector page' },
          { dimension3: `mic${year}` },
          { dimension2: 'mic' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      companyIndexSectionPage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'company sector index page' },
          { dimension3: 'Unassigned' },
          { dimension2: 'mic' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      ibdPage() {
        const year = get(ibdPageData, 'year', '');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'ibd page' },
          { dimension3: `ibd${year}` },
          { dimension2: 'ibd' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      companyPage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'company page' },
          { dimension3: 'company' },
          { dimension2: 'company' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      productPage() {
        const productName = get(productPageData, 'data.rows[0].name');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'product page' },
          { dimension3: `product:${productName}` },
          { dimension2: 'product' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      micYearPage() {
        const year = get(micYearPageData, 'year', '');
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'mic year page' },
          { dimension3: `mic${year}` },
          { dimension2: 'mic' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      },
      magazineArchivePage() {
        customDimensions = [
          { dimension1: legacyDomain },
          { dimension7: 'magazine archives page' },
          { dimension3: 'magazine' },
          { dimension2: 'magazine' },
          { dimension4: 'Fast Company' },
          { dimension5: 'Fast Company' },
          { dimension6: '1/1/1990' },
          { dimension8: 0 },
          { dimension9: 0 }
        ];
      }
    };

    if (typeLookup[type]) {
      typeLookup[type]();
    }

    forEach(customDimensions, dimension => {
      if (!dimension) {
        return;
      }
      const key = Object.keys(dimension)[0];
      const dimensionKey = dimension[key];
      ga('rollup.set', `${key}`, `${dimensionKey}`);
    }).join('');

    // Temporarly solution to figure out why ga.js is fired before head.js, we need head to set document.title first before ga fires in order for GA to collect the right title.
    setTimeout(() => {
      const { pathname } = window.location;
      ga('set', 'title', document.title);
      ga('set', 'page', pathname);
      ga('send', 'pageview');
      ga('rollup.set', 'page', pathname);
      ga('rollup.set', 'title', document.title);
      ga('rollup.send', 'pageview');
    }, 200);
  }
}

export function trackGAEvent(eventParams) {
  if (canUseDOM) {
    if (window && window.ga) {
      const { eventCategory, eventAction, eventLabel = null } = eventParams;
      if (eventCategory && eventAction) {
        window.ga('send', 'event', eventCategory, eventAction, eventLabel);
        window.ga(
          'rollup.send',
          'event',
          eventCategory,
          eventAction,
          eventLabel
        );
      }
    }
  }
}
