import { canUseDOM } from 'exenv';
import set from 'lodash/set';
import asyncInnovationByDesignPageAction from '../../../../redux/actions/innovation_by_design/async_page_actions';
import log from '../../../../services/logger_service';

export default function fetchDataWithStore(store, yearOverride) {
  return function fetchData(nextState, replace, callback) {
    // Handle setting current year at `/list` route
    if (yearOverride) {
      set(nextState, 'params.year', yearOverride);
    }

    const { dispatch } = store;
    // run async
    if (canUseDOM) {
      if (store.getState().config.initialPageLoad === true) {
        if (callback) {
          return callback();
        }
        return false;
      }
      asyncInnovationByDesignPageAction(nextState.params, store)(dispatch);
      if (callback) {
        return callback();
      }
      return false;
    }
    // run sync
    return asyncInnovationByDesignPageAction(nextState.params, store)(dispatch)
      .then(function cb() {
        if (callback) {
          return callback();
        }
        return false;
      })
      .catch(function handleActionError(error) {
        log.fatal(
          error,
          `Fatal error in onEnter state manager: ${error.message}`
        );
        if (callback) {
          return callback();
        }
        return false;
      });
  };
}
