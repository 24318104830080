export default {
  'fb:pages': '5942134076',
  'og:site_name': 'Fast Company',
  'fb:app_id': '178479832213933',
  'fb:admins': '100000062710915',
  'article:publisher': 'https://www.facebook.com/FastCompany',
  'twitter:site': '@FastCompany',
  'twitter:domain': 'fastcompany.com',
  imageUrl:
    'https://a.fastcompany.net/image/upload/v1485296620/fcweb/fastcompany-seo.png'
};
