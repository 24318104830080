import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../../components/loading_spinner/loading_spinner')
);

let Component = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  Component = require('./playlist_index_page').default;
}

export default function(store) {
  const routeConfig = {
    path: '/videos/playlists',
    component() {
      if (Component) {
        return <Component />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    loadData: Component ? Component.loadData(store) : false,
    preloadChunk() {
      return import(/* webpackChunkName: "playlistIndexPage" */ './playlist_index_page').then(
        resp => {
          Component = resp.default;
          routeConfig.loadData = Component.loadData(store);
          return Component;
        }
      );
    },
    chunk: 'playlistIndexPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="video-page">
        <article className="video-page__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
