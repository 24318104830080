import { canUseDOM } from 'exenv';

import loadGATracker from './ga';
import loadGTMTracker from './gtm';
import loadFacebookTracker from './facebook';
import loadComscoreTracker from './comscore';
import loadNielsenTracker from './nielsen';
import loadParselyTracker from './parsely';
import loadChartbeatTracker from './chartbeat';
import updateDatalayer from './datalayer';

// The following trackers are executed on CLIENT SIDE ONLY
// Usually during single page app's "pageview" action
export default function trackThirdPartyJS(currentState, pageNumber) {
  if (canUseDOM) {
    const trackers = [
      updateDatalayer(currentState, pageNumber),
      loadGATracker(currentState, pageNumber),
      loadFacebookTracker(currentState),
      loadComscoreTracker(currentState),
      loadNielsenTracker(),
      loadParselyTracker(currentState, pageNumber),
      loadChartbeatTracker(currentState),
      loadGTMTracker()
    ];
    return Promise.all(trackers);
  }
  return Promise.resolve([]);
}
