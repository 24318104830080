import tagMap from '../../config/ads/priority_tags';

/**
 * Gets a Priority Tag
 *
 * @param {Array} tags - some list of tags
 * @returns {string} - the priority tag or an empty string
 */
export function getPriorityTag(tags, wpTagMap = []) {
  let tagMatch = '';
  let priorityTagMap = tagMap;

  if (wpTagMap.length) priorityTagMap = wpTagMap;

  tags.forEach(tag => {
    const { slug = '', name = '' } = tag || {};

    if (!tagMatch && priorityTagMap.includes(slug)) {
      tagMatch = slug;
    }

    if (name && name.indexOf('*') > -1) {
      tagMatch = name;
    }
  });
  return tagMatch;
}
