import canUseDOM from 'exenv';

class CachingService {
  constructor() {
    this.cache = {};
  }

  initialize({ enabled = false, cacheClearInterval = 20000 }) {
    if (canUseDOM) {
      if (enabled) {
        this.cacheClearInterval = setInterval(
          function clearCache() {
            this.cache = {};
          }.bind(this),
          cacheClearInterval
        );
      }
    }
  }

  getAsync(key) {
    return Promise.resolve(this.cache[key]);
  }

  setex(key, expire, val) {
    if (!val || !key) {
      return;
    }

    this.cache[key] = val;
  }
}

export default new CachingService();
