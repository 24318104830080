import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';

import homepage from './homepage/homepage_reducer';
import scrollingModule from './homepage/scrolling_module_reducer';
import promoBanner from './promo_banner_reducer';
import allFeed from './homepage/all_feed_reducer';
import featuredFeed from './homepage/featured_feed_reducer';
import megatronNativeTile from './homepage/megatron_native_tile_reducer';

import homepageFeatureTerm from './homepage/homepage_feature_term_reducer';
import homepageTag from './homepage/homepage_tag_reducer';
import homepageVideo from './homepage/homepage_video_reducer';
import featuredSponsoredCard from './homepage/featured_sponsored_card_reducer';
import homepageTopPromo from './homepage/homepage_promoter_top_promo_bar_reducer';

import postpage from './post/postpage_reducer';
import post from './post/post_reducer';
import postVideo from './post/post_video_reducer';
import postFinite from './post/post_finite_reducer';
import postAfterbodyRecircModuleType from './post/post_afterbody_recirc_module_type_reducer';
import postPromoterArticleText from './post/post_promoter_article_type_reducer';
import postMoreStories from './post/post_more_stories_reducer';
import postNativeAd from './post/post_native_ad_reducer';

import videoPage from './video/video_page_reducer';
import videoIndexPage from './video/video_index_page_reducer';
import jwVideo from './video/jw_video_reducer';
import jwRelatedVideo from './video/jw_related_video_reducer';
import jwNewestVideo from './video/jw_newest_video_reducer';
import jwPlaylist from './video/jw_playlist_reducer';
import jwPlaylistIndex from './video/jw_playlist_index_reducer';
import playlistIndexPage from './video/playlist_index_page_reducer';
import playlistPage from './video/playlist_page_reducer';
import playlistAllFeed from './video/playlist_all_feed_reducer';

import categoryPage from './category/category_page_reducer';
import categoryFeaturedFeed from './category/category_featured_feed_reducer';
import categoryVideo from './category/category_video_reducer';
import categoryAllFeed from './category/category_all_feed_reducer';
import categoryScrollingModule from './category/category_scrolling_module_reducer';
import categoryPromoBanner from './category/category_promo_banner_reducer';

import tagPage from './tag/tag_page_reducer';
import tagFeaturedFeed from './tag/tag_featured_feed_reducer';
import tagVideo from './tag/tag_video_reducer';
import tagAllFeed from './tag/tag_all_feed_reducer';
import tagScrollingModule from './tag/tag_scrolling_module_reducer';
import tagPromoBanner from './tag/tag_promo_banner_reducer';
import tagPromoterChannelFcWorks from './tag/tag_promoter_channel_fc_works_reducer';

import magazineArchivePage from './magazine/magazine_archive_page_reducer';
import magazineArchive from './magazine/magazine_archive_reducer';
import magazineIssuePosts from './magazine/magazine_issue_posts_reducer';
import magazineLatestIssuePosts from './magazine/magazine_latest_issue_posts_reducer';

import personPage from './mcp/person_page_reducer';
import person from './mcp/person_reducer';

import personIndexPage from './mcp/person_index_page_reducer';

import innovationByDesignPage from './ibd/innovation_by_design_page_reducer';
import innovationByDesign from './ibd/innovation_by_design_reducer';
import innovationByDesignAllFeed from './ibd/innovation_by_design_all_feed_reducer';
import innovationByDesignDetail from './ibd/innovation_by_design_details_reducer';
import innovationByDesignFeaturedFeed from './ibd/innovation_by_design_featured_feed_reducer';
import innovationByDesignScrollingModule from './ibd/innovation_by_design_scrolling_module_reducer';

import giftGuidePage from './gift_guide/gift_guide_page_reducer';
import giftGuide from './gift_guide/gift_guide_reducer';
import giftGuideDetail from './gift_guide/gift_guide_details_reducer';
import giftPage from './gift_guide/gift_page_reducer';
import gift from './gift_guide/gift_reducer';

import personIndexSectionPage from './mcp/person_index_section_page_reducer';
import personIndexSection from './mcp/person_index_section_reducer';
import personIndexSectionNext from './mcp/person_index_section_next_reducer';

import mostCreativePeoplePage from './mcp/most_creative_people_page_reducer';
import bestWorkplacesPage from './bwi/best_workplaces_page_reducer';
import mostCreativePeople from './mcp/most_creative_people_reducer';
import mostCreativePeopleFeaturedFeed from './mcp/most_creative_people_featured_feed_reducer';
import mostCreativePeopleScrollingModule from './mcp/most_creative_people_scrolling_module_reducer';

import worldChangingIdeasPage from './wci/world_changing_ideas_page_reducer';

import brandsThatMatterPage from './brands_that_matter/brands_that_matter_page_reducer';

import franchisePage from './franchise/franchise_page_reducer';
import franchiseApplyPage from './applications/franchise_apply_page_reducers';

import mostCreativePeopleCommunityPage from './mcp/most_creative_people_community_page_reducer';
import mostCreativePeopleCommunitySectionPage from './mcp/most_creative_people_community_section_page_reducer';
import mostCreativePeopleCommunitySection from './mcp/most_creative_people_community_section_reducer';
import mostCreativePeopleCommunitySectionNext from './mcp/most_creative_people_community_section_next_reducer';

import mcpHubPage from './hub_pages/mcp_hub_page_reducer';
import micHubPage from './hub_pages/mic_hub_page_reducer';
import wciHubPage from './hub_pages/wci_hub_page_reducer';
import recognitionHubPage from './hub_pages/recognition_hub_page_reducer';
import hubPage from './hub_pages/hub_page_reducer';

import micIxPage from './one_offs/mic_ix_page_reducer';

import micYearPage from './mic/mic_year_page_reducer';
import micSectorPage from './mic/mic_sector_page_reducer';
import micYear from './mic/mic_year_reducer';
import micYearDescription from './mic/mic_year_description_reducer';
import micSector from './mic/mic_sector_reducer';

import mic2020YearPage from './mic/mic2020_year_page_reducer';
import mic2020Companies from './mic/mic2020_companies_reducer';
import mic2020Regions from './mic/mic2020_regions_reducer';
import mic2020Sectors from './mic/mic2020_sectors_reducer';

import authorPage from './author/author_page_reducer';
import author from './author/author_reducer';
import authorAllFeed from './author/author_all_feed_reducer';

import wpPage from './wp_page_reducer';
import customPage from './custom_page_reducer';

import htmlSitemapIndexPage from './html_sitemap/html_sitemap_index_page_reducer';
import htmlSitemapIndex from './html_sitemap/html_sitemap_index_reducer';

import htmlSitemapPostPage from './html_sitemap/html_sitemap_post_page_reducer';
import htmlSitemapPost from './html_sitemap/html_sitemap_post_reducer';

import recircFeed from './recirc_feed_reducer';

import contextReducer from './context_reducer';
import analyticsReducer from './analytics_reducer';
import configReducer from './config_reducer';
import metaReducer from './meta_reducer';
import statusReducer from './status_reducer';
import notFoundPage from './not_found_page_reducer';

import queer50 from './queer50/queer50_page_reducer';
import supertags from './supertags_reducer';

export default combineReducers({
  routing: routerReducer,
  config: configReducer,
  meta: metaReducer,
  context: contextReducer,
  analytics: analyticsReducer,
  status: statusReducer,
  hubPage,
  recognitionHubPage,
  wciHubPage,
  homepage,
  scrollingModule,
  promoBanner,
  allFeed,
  recircFeed,
  featuredFeed,
  megatronNativeTile,
  homepageFeatureTerm,
  homepageTag,
  homepageVideo,
  featuredSponsoredCard,
  homepageTopPromo,
  supertags,

  postpage,
  post,
  postVideo,
  postAfterbodyRecircModuleType,
  postPromoterArticleText,
  postMoreStories,
  postNativeAd,
  postFinite,

  categoryPage,
  categoryScrollingModule,
  categoryPromoBanner,
  categoryFeaturedFeed,
  categoryVideo,
  categoryAllFeed,

  tagPage,
  tagScrollingModule,
  tagPromoBanner,
  tagFeaturedFeed,
  tagVideo,
  tagAllFeed,
  tagPromoterChannelFcWorks,

  personPage,
  person,

  personIndexPage,

  personIndexSectionPage,
  personIndexSection,
  personIndexSectionNext,

  innovationByDesignPage,
  innovationByDesign,
  innovationByDesignAllFeed,
  innovationByDesignDetail,
  innovationByDesignFeaturedFeed,
  innovationByDesignScrollingModule,

  giftGuidePage,
  giftGuide,
  giftGuideDetail,
  giftPage,
  gift,

  mostCreativePeoplePage,
  mostCreativePeople,
  mostCreativePeopleFeaturedFeed,
  mostCreativePeopleScrollingModule,

  mostCreativePeopleCommunityPage,
  mostCreativePeopleCommunitySectionPage,
  mostCreativePeopleCommunitySection,
  mostCreativePeopleCommunitySectionNext,

  mcpHubPage,
  micHubPage,
  micYearPage,
  micSectorPage,
  micYear,
  micYearDescription,
  micSector,

  mic2020YearPage,
  mic2020Companies,
  mic2020Sectors,
  mic2020Regions,

  micIxPage,

  magazineArchivePage,
  magazineArchive,
  magazineIssuePosts,
  magazineLatestIssuePosts,

  videoIndexPage,
  videoPage,
  jwVideo,
  jwRelatedVideo,
  jwNewestVideo,

  jwPlaylist,
  jwPlaylistIndex,
  playlistPage,
  playlistAllFeed,
  playlistIndexPage,

  authorPage,
  authorAllFeed,
  author,

  queer50,

  wpPage,
  customPage,
  bestWorkplacesPage,

  worldChangingIdeasPage,
  franchisePage,
  franchiseApplyPage,

  brandsThatMatterPage,
  htmlSitemapIndexPage,
  htmlSitemapIndex,
  htmlSitemapPostPage,
  htmlSitemapPost,
  notFoundPage
});
