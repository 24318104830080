import get from 'lodash/get';
import siteData from './site_data_util';
import striptags from 'striptags';
import baseSeoUtil from './base_seo_util';

export default function(state = {}, action = {}) {
  const hubPageAcf = get(action, 'hubPage.tag.advancedFields.acf');
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const wpTitle = get(hubPageAcf, 'hubTitle');
  const img = get(hubPageAcf, 'hubFeaturedImage.url');
  const wpExcerpt = get(hubPageAcf, 'hubSubhed');

  const socialTitle = wpTitle ? striptags(wpTitle).trim() : ''; // displays for twitter and social platforms
  const seoTitle = wpTitle; // displays on google and browser tab
  const description = wpExcerpt ? striptags(wpExcerpt).trim() : '';
  const url = `https://www.fastcomany.com/${get(action, 'hubPage.tag.slug')}`;

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle,
    url,
    description,
    image: img,
    config: get(state, 'config'),
    facebookTitleOverride: `${socialTitle} | Fast Company`,
    twitterTitleOverride: `${socialTitle} | Fast Company`
  });
}
