import * as featuredSponsoredCardActions from '../../action_creators/homepage/featured_sponsored_card_action_creators';
import FeaturedSponsoredCardModel from '../../../models/homepage/featured_sponsored_card_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store, isTag) {
  return function firstDispatch(dispatch) {
    dispatch(
      featuredSponsoredCardActions.featuredSponsoredCardLoading({}, store)
    );

    const state = store.getState();
    const { abtest } = state.config;

    return FeaturedSponsoredCardModel.fetch(params, store, isTag)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(
          featuredSponsoredCardActions.featuredSponsoredCardLoaded(data, store)
        );
      })
      .catch(function handleUserError(err) {
        dispatch(
          featuredSponsoredCardActions.featuredSponsoredCardLoadError(
            err,
            store
          )
        );
        log.error(
          err,
          `Error in featured sponsored card actions, message: ${err.message}`
        );
      });
  };
}
