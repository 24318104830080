import categoryPageStateManager from '../category_page_state_manager';
import loadable from '@loadable/component';

const CategoryPage = loadable(() => import('../category_page'));

const IS_TAG = true;

export default function(store) {
  const state = store.getState();

  if (state.config.site === 'fastcompany') {
    return [
      {
        path: '/technology/:tag?/:page?',
        component: CategoryPage,
        exact: true,
        strict: true,
        loadData: categoryPageStateManager(store, 'technology', IS_TAG)
      },
      {
        path: '/work-life/:tag/:page?',
        component: CategoryPage,
        exact: true,
        strict: true,
        loadData: categoryPageStateManager(store, 'leadership', IS_TAG)
      },
      {
        path: '/creativity/:tag/:page?',
        component: CategoryPage,
        exact: true,
        strict: true,
        loadData: categoryPageStateManager(store, 'entertainment', IS_TAG)
      },
      {
        path: '/impact/:tag/:page?',
        component: CategoryPage,
        exact: true,
        strict: true,
        loadData: categoryPageStateManager(store, 'ideas', IS_TAG)
      }
    ];
  }
  return [
    {
      path: '/cities/:tag/:page?',
      component: CategoryPage,
      exact: true,
      strict: true,
      loadData: categoryPageStateManager(store, 'cities', IS_TAG)
    },
    {
      path: '/graphics/:tag/:page?',
      component: CategoryPage,
      exact: true,
      strict: true,
      loadData: categoryPageStateManager(store, 'graphics', IS_TAG)
    },
    {
      path: '/interactive/:tag/:page?',
      component: CategoryPage,
      exact: true,
      strict: true,
      loadData: categoryPageStateManager(store, 'interactive', IS_TAG)
    },
    {
      path: '/products/:tag/:page?',
      component: CategoryPage,
      exact: true,
      strict: true,
      loadData: categoryPageStateManager(store, 'products', IS_TAG)
    },
    {
      path: '/spaces/:tag/:page?',
      component: CategoryPage,
      exact: true,
      strict: true,
      loadData: categoryPageStateManager(store, 'spaces', IS_TAG)
    }
  ];
}
