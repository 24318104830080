import * as categoryVideoActions from '../../action_creators/category/category_video_action_creators';
import CategoryVideoModel from '../../../models/category/category_video_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchPostDetail(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(categoryVideoActions.categoryVideoLoading());

    const state = store.getState();
    const { abtest } = state.config;

    return CategoryVideoModel.fetch(params, store)
      .then(function handlePostDetailData(resp) {
        const { data } = resp;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(categoryVideoActions.categoryVideoLoaded(data, store));
      })
      .catch(function handleUserError(err) {
        dispatch(categoryVideoActions.categoryVideoLoadError(err, store));
        log.error(
          err,
          `Error in category video actions, message: ${err.message}`
        );
      });
  };
}
