import React from 'react';
import loadable from '@loadable/component';

const LoadingSpinner = loadable(() =>
  import('../../../components/loading_spinner/loading_spinner')
);

let MicIxPageComponent = false;

if (process.env.RUNTIME_ENV !== 'browser') {
  // eslint-disable-next-line global-require
  MicIxPageComponent = require('./micix').default;
}

export default function micIxPage(store) {
  const routeConfig = {
    path: '/:tag(fcmicx)',
    component() {
      if (MicIxPageComponent) {
        return <MicIxPageComponent />;
      }

      return <LazyComponent />;
    },
    exact: true,
    strict: true,
    autoScrollOff: true,
    loadData: MicIxPageComponent ? MicIxPageComponent.loadData(store) : false,
    preloadChunk() {
      return import('./micix').then(resp => {
        MicIxPageComponent = resp.default;
        routeConfig.loadData = MicIxPageComponent.loadData(store);

        return MicIxPageComponent;
      });
    },
    chunk: 'micIxPage'
  };

  const LazyComponent = loadable(() => routeConfig.preloadChunk(), {
    fallback: (
      <section className="post-page">
        <article className="post__main">
          <LoadingSpinner />
        </article>
      </section>
    )
  });

  return routeConfig;
}
