import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import cachingService from '../../services/caching_service';
import request from '../../utils/request_util';
import Base from '../base_model';
import log from '../../services/logger_service';

export default class CategoryScrollingModule extends Base {
  static fetch(store) {
    const state = store.getState();
    const cacheEnabled = get(state, 'config.cacheEnabled');
    const cacheExpire = get(state, 'config.cacheExpire');
    const cacheNamespace = get(state, 'config.cacheNamespace');
    const options = {};
    let abtest = get(state, 'config.abtest');
    if (abtest) {
      abtest = `?abtest=${abtest}`;
    }

    const cacheKeyUrl =
      'https://fc-api.fastcompany.com/v4/category/posts/fastcompany/news?page_size=10&page=1&old_fe=1&only_new=true';
    const cacheKey = `${cacheNamespace}:${cacheKeyUrl}${abtest}`;

    if (!cacheEnabled) {
      return request.getAsync(cacheKeyUrl, options);
    }

    if (!canUseDOM) {
      log.info(`Requesting: ${cacheKeyUrl} from model.`);
    }
    return cachingService.getAsync(cacheKey).then(function fromCache(cacheRes) {
      if (cacheRes) {
        return Promise.resolve(JSON.parse(cacheRes));
      }

      return request
        .getAsync(cacheKeyUrl, options)
        .then(function fetchSuccess(resp) {
          cachingService.setex(cacheKey, cacheExpire, JSON.stringify(resp));
          return resp;
        });
    });
  }
}
