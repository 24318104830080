// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    CATEGORY_ALL_FEED_LOADING() {
      return {
        isLoading: true,
        categoryAllFeed: action.categoryAllFeed,
        allRendered: false
      };
    },
    CATEGORY_ALL_FEED_LOADED() {
      return {
        isLoading: false,
        data: action.categoryAllFeed,
        allRendered: false
      };
    },
    CATEGORY_ALL_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.categoryAllFeed.errorMessage
      };
    },
    CATEGORY_ALL_FEED_ALL_STORIES_RENDERED() {
      return {
        isLoading: false,
        data: state.data,
        allRendered: true
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
