import * as categoryScrollingModuleActions from '../../action_creators/category/category_scrolling_module_action_creators';
import CategoryScrollingModuleModel from '../../../models/category/category_scrolling_module';
import log from '../../../services/logger_service';
import { setAB } from '../../../utils/ab_test_util';

export default function fetchCategory(params, store) {
  return function firstDispatch(dispatch) {
    dispatch(
      categoryScrollingModuleActions.categoryScrollingModuleLoading({}, store)
    );

    const state = store.getState();
    const { abtest } = state.config;

    return CategoryScrollingModuleModel.fetch(store)
      .then(function handleCategoryDetailData(CategoryData) {
        const { data } = CategoryData;
        Object.keys(data).forEach(key => {
          if (Array.isArray(data[key])) {
            setAB(data[key], abtest);
          }
        });

        dispatch(
          categoryScrollingModuleActions.categoryScrollingModuleLoaded(
            data,
            store
          )
        );
      })
      .catch(function handleUserError(err) {
        dispatch(
          categoryScrollingModuleActions.categoryScrollingModuleLoadError(
            err,
            store
          )
        );
        log.error(
          err,
          `Error in category scrollingModule actions, message: ${err.message}`
        );
      });
  };
}
