import React, { Component } from 'react';
import PropTypes from 'prop-types';
import generateTagData from '../../../utils/advertorial_utils';

export function getAdvertorialEyebrow(tags, tagData = false) {
  const data = tagData || generateTagData(tags);
  let { advertiser } = data;

  let copy = 'Created For and Commissioned By: ';

  if (!data.advertiser) {
    return 'Sponsored Content';
  }

  if (data.type === '_syndicated') {
    copy = 'Paid For And Posted By: ';
  }

  if (data.advertiser === 'LOREAL') {
    advertiser = "L'OREAL";
  }

  copy += advertiser;

  return copy;
}

export function isAdvertorial(tags = []) {
  return !!tags?.filter(tag => tag.slug === 'advertorial').length;
}

export function isWciAwards(tags = []) {
  return !!tags?.filter(tag => tag.slug === 'world-changing-ideas-awards')
    .length;
}

export function isCustom(tags = []) {
  return !!tags?.filter(tag => tag.slug === '_custom').length;
}

export function isFestival(tags = []) {
  return !!tags?.filter(tag => tag.slug === '_advertorial_festival').length;
}

export function isPremium(tags = []) {
  return !!tags?.filter(tag => tag.slug === 'premium').length;
}

export function isStartRunGrow(tags = []) {
  return !!tags?.filter(tag => tag.slug === 'start-run-grow').length;
}

export function getBrandworksName(tags = []) {
  let name;
  tags.forEach(tag => {
    if (tag.name.includes('_advertorial')) {
      name = tag.name
        .replace(/\*/g, '')
        .replace(/_/g, ' ')
        .replace(/-/g, ' ')
        .replace(/\s?advertorial/g, '')
        .trim();
    }
  });

  // [HOTFIX] replacement for titles with funky characters
  // since wordpress data does not include these characters
  if (name === 'ATT') {
    name = 'AT&T';
  }

  if (name === 'LOreal') {
    name = "L'Oreal";
  }

  return name;
}

export default class Advertorial extends Component {
  shouldComponentUpdate() {
    return false;
  }

  getBranding(slot) {
    let type;

    switch (slot) {
      case '_custom':
        type = (
          <img
            className="custom-brand-logo"
            alt="fastcoworks logo"
            src="https://images.fastcompany.net/image/upload/v1539886977/fcweb/FastCoWorks_siteslug_prwsv8.svg"
          />
        );
        break;
      case '_brandview':
        type = ['fast-co-works', 'inc-brand-view'];
        break;
      default:
        type = null;
        break;
    }

    if (Array.isArray(type)) {
      return (
        <ul className="advertorial__list">
          {type.map(item => (
            <li
              key={item}
              className={`advertorial__item advertorial__item--${item}`}
            />
          ))}
        </ul>
      );
    }

    return type;
  }

  render() {
    const { modifier, tags, section } = this.props;
    const data = generateTagData(tags);
    const eyebrow = getAdvertorialEyebrow(tags, data);

    if (section) {
      return <h3 className="advertorial__module-deck">{eyebrow}</h3>;
    }

    return (
      <div
        className={`advertorial ${modifier ? `advertorial--${modifier}` : ''}`}
      >
        {data.type || data.advertiser ? (
          <div
            className={`advertorial__container ${
              modifier ? `advertorial__container--${modifier}` : ''
            }`}
          >
            {data.type && this.getBranding(data.type)}
            {data.advertiser ? (
              <span className="advertorial__copy">{eyebrow}</span>
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

Advertorial.defaultProps = {
  modifier: '',
  section: ''
};

Advertorial.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  modifier: PropTypes.string,
  section: PropTypes.string
};
