import get from 'lodash/get';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';
import striptags from 'striptags';

// eslint-disable-next-line no-unused-vars
export default function(state = {}, activeYear = '2022') {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  let year = get(state, 'mostCreativePeople.year', '');
  const imgPresets =
    'https://images.fastcompany.net/image/upload/w_1200,q_auto:best,f_auto';
  const posterImgs = {
    2019: `${imgPresets}/v1558382688/fcweb/p-MCP_100-red-hero_aj7hbt.jpg`,
    2018: `${imgPresets}/v1527271605/fcweb/mcp-2018-share-1200x627_vzjjda.png`,
    2017: `${imgPresets}/v1494843167/MCPwith100and2017forSocial.jpg`,
    2016: `${imgPresets}/v1495030888/MCP100-2016Logo-SHARE.png`,
    2015: `${imgPresets}/v1495030888/MCP100-2015Logo-SHARE.png`,
    2014: `${imgPresets}/v1495030888/MCP100-2014Logo-SHARE.png`,
    fallback: `${imgPresets}/v1495030888/MCP-Logo-default-SHARE.png`
  };

  // 2021 Format
  const mcpData = get(state, 'mostCreativePeople.data');
  const franchiseData = get(state, 'franchisePage.data');

  const seoData = mcpData || franchiseData || {};

  year = get(seoData, 'franchise_year', year);
  const title = get(
    seoData,
    'title.rendered',
    `100 Most Creative People in Business ${year}`
  );
  const seoTitle = get(seoData, 'acf.title');
  const excerpt = get(seoData, 'excerpt.rendered', '');
  const desc = excerpt
    ? striptags(excerpt).trim()
    : `These are the 100 visionary leaders and thinkers you need to know this year.`;
  const featuredImage = get(
    seoData,
    '_embedded.wp:featuredmedia[0].source_url',
    ''
  );
  const megatronImage = get(seoData, 'acf.megatron_image', '');
  const image =
    featuredImage || megatronImage || posterImgs[year] || posterImgs.fallback;

  return baseSeoUtil({
    site,
    siteConf,
    title: seoTitle || title,
    url: `https://www.fastcompany.com/most-creative-people/${year ||
      activeYear}`,
    description: desc,
    image,
    articleSection: 'Most Creative People',
    config: state.config,
    socialDescriptionOverride: desc,
    facebookTitleOverride: seoTitle || title,
    twitterTitleOverride: seoTitle || title
  });
}
