// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';

export default function(state = {}, action) {
  const typeMap = {
    TAG_PAGE_LOADING() {
      return {
        isLoading: true,
        tagPage: action.tagPage
      };
    },
    TAG_PAGE_LOADED() {
      return {
        isLoading: false,
        data: {}
      };
    },
    TAG_PAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.tagPage.errorMessage
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
