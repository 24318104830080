import get from 'lodash/get';
import striptags from 'striptags';
import siteData from '../site_data_util';
import baseSeoUtil from '../base_seo_util';

export default function(state = {}) {
  const site = get(state, 'config.site');
  const siteConf = siteData || {};
  const person = get(state, 'person.data.rows[0]');
  const name = `${get(person, 'first_name')} ${get(person, 'last_name')}`;
  const url = `https://www.fastcompany.com/person/${get(person, 'url')}`;
  const description = striptags(get(person, 'bio'), '');
  const image = get(person, 'images[0].url') || '';
  const mcp = get(person, 'mcp');
  const mcpAwards = get(person, 'mcp_awards');
  const mcpYear = get(person, 'mcp_awards[0].year');

  // https://a.fastcompany.net/multisite_files/fastcompany/imagecache/w/300/h/300/crop/people/2017/05/03/ganesh-bell-fa0617head024a_sm-6orww8.jpg

  // https://a.fastcompany.net/multisite_files/fastcompany/people/2017/05/03/james-anderson-fa0617head029a_sm-i9y01m.jpg

  const imageParts = image.split('/fastcompany/');
  const twitterImage = `https://a.fastcompany.net/multisite_files/fastcompany/imagecache/w/300/h/300/crop/${get(
    imageParts,
    '[1]'
  )}`;

  const baseSEO = {
    site,
    siteConf,
    url,
    description,
    image,
    twitterCard: 'summary',
    twitterImage
  };

  if (mcp === 1 && get(mcpAwards, 'length')) {
    if (get(person, 'mcp_awards[0].why') !== '') {
      baseSEO.description = get(person, 'mcp_awards[0].why');
    }
    baseSEO.articleSection = 'Most Creative People';
    baseSEO.title = `Meet ${name}, one of Fast Company's 100 Most Creative People ${mcpYear}`;
    return baseSeoUtil(baseSEO);
  }

  if (mcp === 1) {
    baseSEO.articleSection = 'Most Creative People';
    baseSEO.title = `Meet ${name}, one of Fast Company's Most Creative People`;
    return baseSeoUtil(baseSEO);
  }

  return baseSeoUtil({
    site,
    siteConf,
    title: `${name}: Latest News, Work, Videos, Photos`,
    url,
    description,
    image,
    articleSection: 'People',
    twitterCard: 'summary',
    twitterImage,
    config: state.config
  });
}
