import React, { Component } from 'react';
import PropTypes from 'prop-types';

export default class LoadingSpinner extends Component {
  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { modifier } = this.props;

    return (
      <div className="loader-wrap">
        <div className={`loader ${modifier ? `loader--${modifier}` : ''}`} />
      </div>
    );
  }
}

LoadingSpinner.defaultProps = {
  modifier: ''
};

LoadingSpinner.propTypes = {
  modifier: PropTypes.string
};
