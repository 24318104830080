// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';

export default function(state = {}, action) {
  const typeMap = {
    POSTPAGE_LOADING() {
      return {
        isLoading: true,
        postpage: action.postpage
      };
    },
    POSTPAGE_LOADED() {
      return {
        isLoading: false,
        data: get(action, 'postpage'),
        postScrollDepth: 0
      };
    },
    POSTPAGE_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: action.postpage.errorMessage
      };
    },
    POSTPAGE_SCROLL_DEPTH() {
      return {
        isLoading: state.isLoading,
        data: state.postpage,
        postScrollDepth: action.postScrollDepth
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
