// https://github.com/reactjs/redux/issues/99
// import { canUseDOM } from 'exenv';
import get from 'lodash/get';
import extend from 'lodash/assign';

export default function(state = {}, action) {
  const typeMap = {
    PLAYLIST_ALL_FEED_LOADING() {
      return {
        isLoading: true,
        playlistAllFeed: action.playlistAllFeed,
        allRendered: false
      };
    },
    PLAYLIST_ALL_FEED_LOADED() {
      const playlist = get(action, 'playlistAllFeed', {});

      return {
        isLoading: false,
        data: playlist,
        allRendered: false
      };
    },
    PLAYLIST_ALL_FEED_ERROR() {
      return {
        isLoading: false,
        error: true,
        errorMessage: get(action, 'playlistAllFeed.errorMessage')
      };
    },
    PLAYLIST_ALL_FEED_IS_LOADING() {
      return extend({}, state, { isEnabled: true, isLoading: true });
    },
    PLAYLIST_ALL_FEED_IS_LOADED() {
      return {
        isEnabled: true,
        isLoading: false,
        data: extend({}, state.data, {
          page: get(action, 'playlistAllFeed.page'),
          playlist: [
            ...get(state, 'data.playlist', []),
            ...get(action, 'playlistAllFeed.playlist', [])
          ]
        }),
        allRendered: false
      };
    },
    PLAYLIST_ALL_FEED_IS_ERROR() {
      return {
        isEnabled: true,
        isLoading: true,
        data: extend({}, state, {
          page: get(action, 'playlistAllFeed.page'),
          playlist: [
            ...get(state, 'data.playlist', []),
            ...get(action, 'playlistAllFeed.playlist', [])
          ]
        }),
        allRendered: false
      };
    },
    PLAYLIST_ALL_FEED_ALL_STORIES_RENDERED() {
      return {
        isLoading: true,
        data: state.data,
        allRendered: true
      };
    }
  };

  if (typeMap[action.type]) {
    return typeMap[action.type]();
  }

  return state;
}
