import articleRedirects from './articleRedirect';

function generateParams() {
  const arr = [];
  articleRedirects.map(article => {
    arr.push({
      url: article.url,
      paramater: 'id',
      setTo: article.id
    });
    arr.push({
      url: article.url,
      paramater: 'slug',
      setTo: article.slug
    });
    arr.push({
      url: article.url,
      paramater: 'articleRedirect',
      setTo: false
    });
    arr.push({
      url: article.url,
      paramater: 'isNewUrl',
      setTo: true
    });
    return null;
  });
  return arr;
}
const data = {
  cases: [
    {
      url: '/innovation-by-design/2018',
      paramater: 'year',
      setTo: '2018'
    },
    {
      url: '/grant-thornton',
      paramater: 'tag',
      setTo: 'grant-thornton'
    },
    {
      url: '/citrix',
      paramater: 'tag',
      setTo: 'citrix_advertorial'
    },
    {
      url: '/innovation-by-design/2019',
      paramater: 'tag',
      setTo: 'innovation-by-design-2019'
    }
  ]
};

data.cases.push(...generateParams());

export default data;
