export default {
  'business-case-for-abortion-access': {
    tag: 'abortion-access',
    name: 'Abortion Access'
  },
  'ambition-diaries': {
    tag: 'ambition-diaries',
    name: 'Ambition Diaries'
  }
};

// need to include both tag and name. Name is used for sitemap/hub
