// import { canUseDOM } from 'exenv';

export function recircFeedLoading(resp = {}, store = {}) {
  return {
    type: 'RECIRC_FEED_LOADING',
    recircFeed: resp,
    store
  };
}

export function recircFeedLoaded(resp = {}, store = {}) {
  return {
    type: 'RECIRC_FEED_LOADED',
    recircFeed: resp,
    store
  };
}

export function recircFeedLoadError(error, store = {}) {
  return {
    type: 'RECIRC_FEED_ERROR',
    recircFeed: {
      isLoading: false,
      error: true,
      errorMessage: error ? error.message : 'no message'
    },
    store
  };
}

export function hideRecircFeed(resp = {}, store = {}) {
  return {
    type: 'HIDE_RECIRC_FEED',
    recircFeed: resp,
    store
  };
}
